import '../../assets/styles/dashboardStyles.css';
import { useEffect, useState } from 'react';
import { getUserSafetyForms } from '../../network/safety_form';
import { toast } from 'react-toastify';
import SafetyformTable from '../../components/operatorLogbook/SafetyformTable';
import moment from 'moment';

function OperatorLogBook() {
  const [forms, setForms] = useState([]);
  const [loader, setLoader] = useState(false);
  const [entriesMeta, setEntriesMeta] = useState();
  const [filters, setFilter] = useState({
    start_date: '',
    end_date: '',
    search: '',
  });

  useEffect(() => {
    getSafetyForms();
  }, [filters]);

  const getSafetyForms = async (pageNo = 1, pageSize = 10) => {
    setLoader(true);
    let res = await getUserSafetyForms({
      pageSize,
      pageNo,
      search: filters.search,
      startDate: filters.start_date
        ? moment(filters.start_date).format('YYYY-MM-DD')
        : '',
      endDate: filters.end_date
        ? moment(filters.end_date).format('YYYY-MM-DD')
        : '',
    });
    if (res.data?.data) {
      setForms(res.data?.data);
      setEntriesMeta(res.data?.meta);
      setLoader(false);
    } else {
      toast.error('Error occured while fetching data.');
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <div className='loader'></div>}
      <div className={`${loader ? 'opacity-50' : ''} app-container}`}>
        {entriesMeta && (
          <SafetyformTable
            entriesMeta={entriesMeta}
            getForms={getSafetyForms}
            data={forms}
            filters={filters}
            setFilter={setFilter}
          />
        )}
      </div>
    </>
  );
}

export default OperatorLogBook;
