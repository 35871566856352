import { useState } from 'react';
import { isNumber } from '../../services/utils';

export default function TwoDigitTimeField({ value, min, max, handleChange }) {
  const [time, setTime] = useState(
    value === '0' || value === '00' ? '' : value
  );
  const handleOnChange = (event) => {
    let newValue = event.target.value;
    if (isNumber(newValue)) {
      if (time?.length === 2 && event.target.value?.length === 1) {
        setTime('');
        handleChange('00');
      } else {
        if (Number(newValue) >= min && Number(newValue) <= max) {
          setTime(newValue);
          handleChange(newValue);
        } else if (!newValue) {
          setTime('');
          handleChange('00');
        }
      }
    } else if (!newValue) {
      setTime('');
      handleChange('00');
    }
  };

  return (
    <input
      className="tt-desc-input time-entry-field"
      style={{ caretColor: 'transparent' }}
      value={time}
      type="text"
      maxLength={2}
      onChange={handleOnChange}
    />
  );
}
