import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Header from '../components/header';

import { ToastContainer } from 'react-toastify';

export const DefaultLayout = () => {
  const auth = useAuth();

  if (!auth?.user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Outlet />
    </div>
  );
};
