import './Form.css';
import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import VehicleInfo from '../vehicleInfo/VehicleInfo';
import SignatureCanvas from 'react-signature-canvas';

import penIcon from '../../assets/icons/pen.png';
import frameIcon from '../../assets/icons/Frame.png';
// import ArgonBox from 'components/ArgonBox';
import { Controller, useForm } from 'react-hook-form';
import Dropdown from 'react-bootstrap/Dropdown';
import { getBase64 } from '../../services/utils';
import { createSafetyForm } from '../../network/safety_form';
import { toast } from 'react-toastify';
import { getUserJobs } from '../../network/jobs';
import Checkbox from './component/Checkbox.jsx';
import QrScanner from '../../pages/QrCodePage/QrScanner';
import pdfPreview from '../../assets/icons/pngegg.png';
import { getOneVehicle } from '../../network/vehicle';
import { useNavigate } from 'react-router-dom';

export default function Form({
  updateLoader,
  userData,
  weather,
  updateOpenQr,
  openQr,
  vehicleId,
  isSubmitted,
  submittedForm,
  oldReadingId,
}) {
  const navigate = useNavigate();
  const [attachment, setAttachment] = useState();
  const [trailerAttachment, setTrailerAttachment] = useState();
  const [signature, setSignature] = useState();
  const [previewImage, setPreviewImage] = useState(
    submittedForm?.safetyForm?.attachment?.mimeType == 'application/pdf'
      ? pdfPreview
      : submittedForm?.safetyForm?.attachment?.imageUrl || null
  );
  const [trailerPreviewImage, setTrailerPreviewImage] = useState(
    submittedForm?.trailer?.attachment?.mimeType == 'application/pdf'
      ? pdfPreview
      : submittedForm?.trailer?.attachment?.imageUrl || null
  );
  const [previewSignature, setPreviewSignature] = useState(
    submittedForm?.safetyForm?.signature?.mimeType == 'application/pdf'
      ? pdfPreview
      : submittedForm?.safetyForm?.signature?.imageUrl || null
  );
  const [trailerVehicle, setTrailerVehicle] = useState(
    submittedForm?.trailerBasicInfo || null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  let signaturePad = useRef(null);
  const [signatureEntered, setSignatureEntered] = useState(false);
  const [lastReading, setlastReading] = useState();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const filesChange = (file) => {
    setAttachment(file);
  };

  const getJobs = async () => {
    updateLoader(true);
    let res = await getUserJobs();
    if (res?.status === 200) {
      setOptions(res?.data?.data?.jobs);
    } else {
      toast.error(res?.response?.data?.error);
    }
    updateLoader(false);
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selected = options.find((option) => option._id === selectedId);
    setSelectedOption(selected);
  };
  const handleTrailerAttachment = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const { base64, mimeType } = await getBase64(file);
      setTrailerAttachment({ imageUrl: base64, mimeType });
      if (file.type.startsWith('image/')) {
        const imageDataUrl = URL.createObjectURL(file);
        setTrailerPreviewImage(imageDataUrl);
      } else {
        setTrailerPreviewImage(pdfPreview);
      }
    }
  };

  const handleAttachment = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const { base64, mimeType } = await getBase64(file);
      setAttachment({ imageUrl: base64, mimeType });
      if (file.type.startsWith('image/')) {
        const imageDataUrl = URL.createObjectURL(file);
        setPreviewImage(imageDataUrl);
      } else {
        setPreviewImage(pdfPreview);
      }
    }
  };

  const clearSignature = () => {
    signaturePad.current.clear();
    setSignatureEntered(false);
    setSignature();
  };

  const handleRemoveAttachment = () => {
    setPreviewImage(null);
    setAttachment(null);
  };

  const updateTrailerVehicle = (data) => {
    setTrailerVehicle(data);
  };

  const handleRemoveTrailerAttachment = () => {
    setTrailerPreviewImage(null);
    setTrailerAttachment(null);
  };

  const handleQrScan = () => {
    signaturePad.current.clear();
    setSignatureEntered(false);
    updateOpenQr(true);
  };

  // window.onresize = function () {
  //   setSignatureEntered(false);
  // };

  const requiredFieldCheck = (data) => {
    if (
      signaturePad.current.isEmpty() ||
      !data.is_trailer_attached ||
      !data.any_visual_defects ||
      !data.is_engine_oil_been_checked ||
      !data.is_lights_been_checked ||
      !data.is_engine_oil_been_checked ||
      !data.is_brakes_been_checked
    ) {
      return false;
    } else {
      if (data.is_trailer_attached == 'true') {
        if (
          !data.trailer_any_visual_defects ||
          !data.trailer_is_engine_oil_been_checked ||
          !data.trailer_is_lights_been_checked ||
          !data.trailer_is_engine_oil_been_checked ||
          !data.trailer_is_brakes_been_checked
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const onSubmit = async (data) => {
    updateLoader(true);
    let imageUrl;
    if (requiredFieldCheck(data)) {
      if (data?.odometer_reading < lastReading) {
        toast.error(
          'Odometer Reading should be equal or greater than last reading'
        );
        updateLoader(false);
      } else {
        imageUrl = await signaturePad?.current?.getCanvas().toDataURL();
        let trailerSafetyForm;
        if (data?.is_trailer_attached === 'true') {
          trailerSafetyForm = {
            is_trailer: true,
            any_visual_defects: data?.trailer_any_visual_defects,
            is_brakes_been_checked: data?.trailer_is_brakes_been_checked,
            is_engine_oil_been_checked:
              data?.trailer_is_engine_oil_been_checked,
            is_lights_been_checked: data?.trailer_is_lights_been_checked,
            is_tires_been_checked: data?.trailer_is_tires_been_checked,
            comment: data?.trailer_comments,
            attachment: trailerAttachment,
            vehicleId: trailerVehicle?.vehicle_id,
          };
        }

        const vehicleSafetyForm = {
          any_visual_defects: data?.any_visual_defects,
          is_brakes_been_checked: data?.is_brakes_been_checked,
          is_engine_oil_been_checked: data?.is_engine_oil_been_checked,
          is_lights_been_checked: data?.is_lights_been_checked,
          is_tires_been_checked: data?.is_tires_been_checked,
          is_trailer_attached: data?.is_trailer_attached,
          odometer_reading: data?.odometer_reading,
          comment: data?.comments,
          is_trailer_attached: data?.is_trailer_attached,
          signature: { imageUrl: imageUrl, mimeType: 'image/png' },
          attachment: attachment,
          vehicleId: vehicleId,
        };
        const weatherData = {
          current_temperature: weather?.main?.temp,
          min_temperature: weather?.main?.temp_min,
          max_temperature: weather?.main?.temp_max,
          status: weather?.weather[0]?.main,
          city: weather?.name,
        };

        const output = {
          trailerSafetyForm: data?.is_trailer_attached
            ? trailerSafetyForm
            : null,
          vehicleSafetyForm: vehicleSafetyForm,
          jobId: selectedOption?._id,
          userId: userData?._id,
          weather: weatherData,
        };
        createSafetyForm(output)
          .then((data) => {
            toast.success('Safety form added successfully');
            updateLoader(false);
            navigate('/safety_forms/operator_logbook');
          })
          .catch((error) => {
            toast.error('Error Occured');
            updateLoader(false);
          });
      }
    } else {
      toast.error('Required Fields are missing');
      updateLoader(false);
    }
  };

  const getOdometerReading = () => {
    getOneVehicle(oldReadingId)
      .then((res) => {
        setlastReading(
          res?.data?.data?.last_odometer_reading
            ? res?.data?.data?.last_odometer_reading
            : res?.data?.data?.current_odometer_reading
        );
      })
      .catch((error) => {
        toast.error('Error in fetching details.');
        console.log(error);
      });
  };

  useEffect(() => {}, [signatureEntered]);

  useEffect(() => {
    getJobs();
    getOdometerReading();
  }, []);

  return (
    <>
      {openQr ? (
        <QrScanner
          isTrailer={true}
          updateTrailerVehicle={updateTrailerVehicle}
          updateOpenQr={updateOpenQr}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='card-container px-4 text-color'>
            <div className='heading-font'>Operator's Log Book</div>
            <hr />
            <Row>
              <Col
                sm='12'
                md='6'
                className={`${
                  isSubmitted
                    ? 'd-flex align-items-center justify-content-between pe-3'
                    : ''
                }`}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <label className='mb-2 d-block'>
                    Job <span className='required-color'>*</span>
                  </label>
                </div>
                {!isSubmitted ? (
                  <select
                    className='form-input w-100 select-options'
                    value={selectedOption?._id || ''}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value=''>Select Job</option>
                    {options.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className='text-black'>{submittedForm?.job}</div>
                )}
              </Col>
              <Col
                sm='12'
                md='6'
                className={`ps-md-2 mt-3 mt-md-0 ${
                  isSubmitted
                    ? 'd-flex align-items-center justify-content-between  pe-3'
                    : ''
                }`}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <label className='mb-2 d-block'>
                    Current Odometer reading(KM){' '}
                    <span className='required-color'>*</span>
                  </label>
                </div>
                {!isSubmitted ? (
                  <input
                    placeholder='Enter reading'
                    className={`form-input w-100 ${
                      errors['odometer_reading'] && 'invalid-input'
                    }`}
                    type='Number'
                    pattern='[0-9]*'
                    {...register('odometer_reading', { required: true })}
                  />
                ) : (
                  <div className='text-black'>
                    {submittedForm?.safetyForm?.odometer_reading}
                  </div>
                )}
              </Col>
            </Row>
            <Checkbox
              register={register}
              errors={errors}
              watch={watch}
              preview={previewImage}
              add={handleAttachment}
              remove={handleRemoveAttachment}
              isTrailer={false}
              isSubmitted={isSubmitted}
              submittedForm={submittedForm}
            />
          </div>
          {/* Trailer info */}
          <div className='card-container px-4 text-color mt-3'>
            <div className='heading-font'>Trailer Info</div>
            <hr />

            <Row>
              <Col sm='12' md='6'>
                <div
                  className={`d-flex ${
                    isSubmitted ? 'flex-row' : 'flex-column'
                  } mt-3 ${isSubmitted ? ' justify-content-between me-3' : ''}`}
                >
                  <label>
                    Is Trailer attached?
                    <span className='required-color'> *</span>
                  </label>
                  {!isSubmitted ? (
                    <div className='mt-3'>
                      <label className='me-3'>
                        <input
                          className='mx-2 primary-checkbox'
                          type='radio'
                          value={true}
                          {...register('is_trailer_attached')}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          className='mx-2 primary-checkbox'
                          type='radio'
                          value={false}
                          {...register('is_trailer_attached')}
                        />
                        No
                      </label>
                    </div>
                  ) : (
                    <div className='text-black'>
                      {submittedForm?.safetyForm?.is_trailer_attached
                        ? 'Yes'
                        : 'No'}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {!isSubmitted &&
            watch('is_trailer_attached') === 'true' &&
            !trailerVehicle ? (
              <Row className='mt-3 d-flex justify-content-center align-items-center'>
                <Col>Which Trailer</Col>
                <Col>
                  <label
                    className='camera-button  gap-2'
                    onClick={handleQrScan}
                  >
                    <img src={frameIcon} alt='' />
                    Scan QR
                  </label>
                </Col>
              </Row>
            ) : (
              ''
            )}

            {(!isSubmitted
              ? watch('is_trailer_attached') === 'true'
              : submittedForm?.safetyForm?.is_trailer_attached) &&
            trailerVehicle ? (
              <>
                <VehicleInfo
                  data={trailerVehicle}
                  updateLoader={updateLoader}
                  isTrailer={true}
                  isSubmitted={isSubmitted}
                  type={submittedForm?.trailer_category}
                />
                <hr />
                <Checkbox
                  register={register}
                  errors={errors}
                  watch={watch}
                  preview={trailerPreviewImage}
                  add={handleTrailerAttachment}
                  remove={handleRemoveTrailerAttachment}
                  isTrailer={true}
                  isSubmitted={isSubmitted}
                  submittedForm={submittedForm}
                />
              </>
            ) : (
              ''
            )}
          </div>

          <div className='card-container px-4 text-color mt-3'>
            <div className='sig-text'>
              Signature<span className='required-color'> *</span>
            </div>
            <div className='sig-container gap-2 mt-3'>
              {previewSignature ? (
                <img
                  src={previewSignature}
                  alt='Preview'
                  style={{ maxWidth: '100%', maxHeight: '170px' }}
                />
              ) : (
                !isSubmitted && (
                  <>
                    <div className='signature-canvas-container'>
                      <SignatureCanvas
                        ref={signaturePad}
                        penColor='black'
                        canvasProps={{
                          // width: 320,
                          // height: 175,
                          style: {
                            width: '100%',
                            height: '175px',
                          },
                          className: 'signature-canvas',
                        }}
                        clearOnResize={false}
                        onBegin={() => setSignatureEntered(true)}
                      />
                      {!signatureEntered && (
                        <div className='centered-text d-flex justify-content-center align-items-center flex-column'>
                          <img src={penIcon} alt='pen symbol' />
                          <label htmlFor='signature-input'>
                            <div className='add-sig-text'>Please sign here</div>
                          </label>
                        </div>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
            {signatureEntered && !isSubmitted && (
              <div className='d-flex justify-content-end mt-2'>
                <label onClick={clearSignature} className='camera-button'>
                  Retake
                </label>
              </div>
            )}
          </div>
          {!isSubmitted ? (
            <div className='btn-container d-flex justify-content-around mt-3'>
              <button
                className='primary-button-outline px-5'
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              <button
                className='primary-button primary-button-text px-5'
                type='submit'
              >
                Submit
              </button>
            </div>
          ) : (
            ''
          )}
        </form>
      )}
    </>
  );
}
