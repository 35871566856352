import '../../assets/styles/dashboardStyles.css';
import CustomDropDown from '../../components/dropdown';
import DOWN_ARROW from '../../assets/icons/select-down-arrow.svg';
import SelectJob from '../../components/modals/SelectJob';
import { useEffect, useState } from 'react';
import TimeTrack from '../../components/TimeTrack';
import Disabled from '../../components/modals/Disabled';
import TimeTrackMobile from '../../components/TimeTrackMobile';
import useWindowSize from '../../hooks/useWindowSize';
import { MEDIUM_SCREEN_WIDTH, UPDATE_ENTRY } from '../../constants';
import NOT_ALLOWED from '../../assets/icons/not-allowed.svg';
import DashboardComp from '../../components/DashboardComp';

import { getUserJobs } from '../../network/jobs';

import moment from 'moment';
import {
  addTimeEntry,
  pauseTimeEntry,
  getUnpausedEntry,
  getTimeEntriesByWeek,
  updateTimeEntry,
  addSubEntry,
  clockOut,
} from '../../network/timeTrack';

import { toast } from 'react-toastify';
import Confirmation from '../../components/modals/Confirmation';
import { deleteTimeEntry, deleteSubTimeEntry } from '../../network/timeTrack';
import { useAuth } from '../../hooks/useAuth';

function TimeTrackPage() {
  const size = useWindowSize();
  const auth = useAuth();
  const [jobModal, toggleJobModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [isTimeTrack, setTrackFlag] = useState(false);
  const [disableModal, toggleDisableModal] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [currentTracking, setCurrentTracking] = useState({});
  const [timeEntries, setTimeEntries] = useState([]);
  const [description, setDescription] = useState('');
  const [loader, setLoader] = useState(false);
  const [weeklyHours, setWeeklyHours] = useState('');
  const [durationMap, setDurationMap] = useState('');
  const [entriesMeta, setEntriesMeta] = useState({});
  const [timeAlert, toggleTimeAlert] = useState(false);
  const [alertEntry, setAlertEntry] = useState({});
  const [teamLeaders, setTeamLeaders] = useState([]);

  const isPermitted = auth?.user?.team?.is_track_permitted_by_lead;

  useEffect(() => {
    handleUseEffectCalls();
  }, []);

  const handleUseEffectCalls = async () => {
    setLoader(true);
    await Promise.all[
      (getJobs(), getUserUnPausedEntry(), getWeekTimeEntries())
    ];

    setLoader(false);
  };

  const getUserUnPausedEntry = async () => {
    let unpausedEntry = await getUnpausedEntry();

    if (unpausedEntry?.data?.timeTrack) {
      setSelectedJob(unpausedEntry?.data?.timeTrack?.job);
      setDescription(unpausedEntry?.data?.timeTrack?.description);
      setCurrentTracking({
        job: unpausedEntry?.data?.timeTrack?.job,
        entries: unpausedEntry?.data?.timeTrack,
      });
      setTrackFlag(true);
    }
  };

  const getWeekTimeEntries = async (page = 1, limit = 10) => {
    setLoader(true);
    setTimeEntries([]);
    let entriesResponse = await getTimeEntriesByWeek(page, limit);

    if (entriesResponse?.data?.timeEntries) {
      setTimeEntries(
        (prevEntries) => entriesResponse?.data?.timeEntries?.entries
      );
      setWeeklyHours(entriesResponse?.data?.timeEntries?.hoursSecsWeek);
      setDurationMap(entriesResponse?.data?.timeEntries?.durationMap);
      setEntriesMeta(entriesResponse?.data?.timeEntries?.meta);
      setTeamLeaders(entriesResponse?.data?.teamLeaders);
    }
    setLoader(false);
  };

  const getJobs = async () => {
    let res = await getUserJobs();
    if (res?.data?.data?.jobs) {
      setJobs(res?.data?.data?.jobs);
    } else {
      toast.error(
        res?.response?.data?.error || 'You have not been assigned any job yet.'
      );
    }
  };

  const handleClockIn = async () => {
    if (!isTimeTrack) {
      let trackedTime = await addTimeEntry({
        description,
        job_id: selectedJob?._id,
        start_time: moment().set({ second: 0 }).format(),
      });

      if (trackedTime?.data?.time_entry) {
        setCurrentTracking({
          job: selectedJob,
          entries: trackedTime?.data?.time_entry,
        });
        setTrackFlag(true);
      } else {
        toast.error('Something went wrong.');
      }
    }
  };

  const handlePause = async () => {
    let trackingClone = { ...currentTracking };
    let allEntriesStop = trackingClone.entries?.time_intervals?.filter(
      (entry) => !entry?.end_time
    );
    if (allEntriesStop?.length) {
      if (
        moment().diff(moment(allEntriesStop?.[0]?.start_time), 'seconds') < 60
      ) {
        setAlertEntry({ ...currentTracking, isPaused: true });
        toggleTimeAlert(true);
      } else {
        await pauseTimeService(trackingClone, false);
      }
    } else {
      let entry = {
        start_time: moment().set({ second: 0 }).format(),
      };
      let trackedTime = await addSubEntry(trackingClone?.entries?._id, entry);
      if (trackedTime?.data?.time_entry) {
        setCurrentTracking((prevTracking) => {
          return { ...prevTracking, entries: trackedTime?.data?.time_entry };
        });
      } else {
        toast.error('Something went wrong.');
      }
    }
  };

  const pauseTimeService = async (trackingClone) => {
    let entryPaused = await pauseTimeEntry({
      entry_id: trackingClone.entries?._id,
      subentry_id:
        trackingClone.entries?.time_intervals[
          trackingClone.entries?.time_intervals?.length - 1
        ]?._id,
      end_time: moment().set({ second: 0 }).format(),
    });
    if (entryPaused?.data?.time_entry) {
      setCurrentTracking((prevTracking) => {
        return { ...prevTracking, entries: entryPaused?.data?.time_entry };
      });
      toast.success('Entry updated.');
      return true;
    } else {
      toast.error('Something went wrong.');
      return false;
    }
  };

  const handleClockOut = async () => {
    let trackingClone = { ...currentTracking };
    let allEntriesStop = trackingClone?.entries?.time_intervals?.filter(
      (entry) => !entry?.end_time
    );

    let updateEntry = {};
    if (allEntriesStop?.length) {
      if (
        moment().diff(moment(allEntriesStop?.[0]?.start_time), 'seconds') < 60
      ) {
        setAlertEntry({
          ...currentTracking,
          isPaused: false,
          clockout_time: moment().set({ second: 0 }).format(),
        });
        toggleTimeAlert(true);
        return;
      }
      updateEntry = {
        entry_id: trackingClone.entries?._id,
        subentry_id:
          trackingClone.entries?.time_intervals[
            trackingClone.entries?.time_intervals?.length - 1
          ]?._id,
        end_time: moment().set({ second: 0 }).format(),
      };
    } else {
      updateEntry = {
        entry_id: trackingClone.entries?._id,
      };
    }

    let clockedOutRes = await clockOut(updateEntry);

    if (clockedOutRes?.data?.time_entry) {
      toast.success('Clocked out successfully.');
      setTrackFlag(false);
      setDescription('');
      setSelectedJob({});
      setCurrentTracking([]);
      getWeekTimeEntries();
    } else {
      toast.error('Something went wrong.');
    }
  };

  const handleShortEntry = async () => {
    let trackingClone = { ...alertEntry };
    let allEntriesStop = trackingClone?.entries?.time_intervals?.filter(
      (entry) => !entry?.end_time
    );
    if (trackingClone?.entries?.time_intervals?.length > 1) {
      let res = await deleteSubTimeEntry(
        trackingClone?.entries?._id,
        allEntriesStop?.[0]?._id
      );

      if (res?.data?.time_entry) {
        if (alertEntry?.isPaused) {
          setCurrentTracking((prevTracking) => {
            return { ...prevTracking, entries: res?.data?.time_entry };
          });
          toggleTimeAlert(false);
          setAlertEntry({});
        } else {
          await clockOut({ entry_id: trackingClone?.entries?._id });
          setTrackFlag(false);
          setDescription('');
          setSelectedJob({});
          setCurrentTracking([]);
          toggleTimeAlert(false);
          setAlertEntry({});
          getWeekTimeEntries();
        }
      } else {
        toast.error('Something went wrong.');
      }
    } else {
      let res = await deleteTimeEntry(trackingClone?.entries?._id);
      if (!res?.data) {
        toast.error('Something went wrong.');
      } else {
        setTrackFlag(false);
        setDescription('');
        setSelectedJob({});
        setCurrentTracking([]);
        toggleTimeAlert(false);
        setAlertEntry({});
      }
    }
  };

  const handleTimeBarClick = () => {
    if (isTimeTrack) {
      toggleDisableModal(true);
    }
  };

  const handleEntryUpdate = async (
    prevValue,
    newValue,
    row,
    action,
    subentry_id,
    subentry = false
  ) => {
    if (newValue !== prevValue) {
      let apiData = {};
      let apiFlag = true;
      switch (action) {
        case UPDATE_ENTRY.description: {
          apiData = { description: newValue };
          break;
        }
        case UPDATE_ENTRY.colckIn: {
          let newTime;
          let diff;

          if (!subentry) {
            newTime = moment(row?.time_intervals?.[0]?.start_time)
              .set('hour', newValue?.split(':')[0])
              .set('minute', newValue?.split(':')[1])
              .toISOString();

            diff = moment(
              row?.time_intervals?.[row?.time_intervals?.length - 1]?.end_time
            ).isBefore(moment(newTime), 'seconds');
          } else {
            newTime = moment(row?.start_time)
              .set('hour', newValue?.split(':')[0])
              .set('minute', newValue?.split(':')[1])
              .toISOString();

            diff = moment(row?.end_time).isBefore(moment(newTime), 'seconds');
          }

          if (!diff) {
            apiData = { start_time: newTime, subentry_id };
          } else {
            apiFlag = false;
            toast.error(`Clock In time can't be after clock out time`);
          }
          break;
        }
        case UPDATE_ENTRY.clockout: {
          let newTime;
          let diff;
          if (!subentry) {
            newTime = moment(
              row?.time_intervals?.[row?.time_intervals?.length - 1]?.end_time
            )
              .set('hour', newValue?.split(':')[0])
              .set('minute', newValue?.split(':')[1])
              .toISOString();
            diff = moment(row?.time_intervals?.[0]?.start_time).isAfter(
              moment(newTime),
              'seconds'
            );
          } else {
            newTime = moment(row?.end_time)
              .set('hour', newValue?.split(':')[0])
              .set('minute', newValue?.split(':')[1])
              .toISOString();
            diff = moment(row?.start_time).isAfter(moment(newTime), 'seconds');
          }
          if (!diff) {
            apiData = { end_time: newTime, subentry_id };
          } else {
            apiFlag = false;
            setTimeEntries((prevEntries) =>
              prevEntries.map((entry) => {
                if (entry?._id === row?._id) {
                  return {
                    ...entry,
                    time_intervals: row?.time_intervals,
                    description: row?.description,
                  };
                }
                return entry;
              })
            );
            toast.error(`Clock out time can't be before clock in time`);
          }
          break;
        }
        case UPDATE_ENTRY.job: {
          apiData = { job_id: newValue?._id };
          break;
        }
        default: {
          console.log('invalid');
        }
      }
      if (apiFlag) {
        let res = await updateTimeEntry(row?._id, apiData);
        if (res.data?.timeEntry) {
          toast.success('Entry updated successfully.');
          getWeekTimeEntries(entriesMeta?.page, entriesMeta?.limit);
        } else {
          toast.error('Something went wrong.');
        }
      }
    }
  };

  return (
    <>
      {loader && <div className="loader"></div>}
      {!(isTimeTrack && size?.width <= MEDIUM_SCREEN_WIDTH) ? (
        <div className="secondary-container">
          <div
            className={`clocker-wrapper ${
              isTimeTrack ? 'opacity-75 cursor-pointer' : ''
            }`}
            onClick={handleTimeBarClick}
          >
            <div className="d-md-flex gap-3">
              <div className="d-flex gap-3">
                <span className="p-2 disabled-input-wrapper">
                  {selectedJob?.po_number
                    ? `${
                        selectedJob?.po_number?.length > 6
                          ? `${selectedJob?.po_number?.slice(0, 6)}...`
                          : selectedJob?.po_number
                      }`
                    : 'Job ID'}
                </span>
                <input
                  className="p-2 desc-input-field"
                  placeholder="Description"
                  disabled={isTimeTrack}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="d-md-block d-none">
                <CustomDropDown
                  jobs={jobs}
                  handleSelectedJob={(val) => setSelectedJob(val)}
                  isTimeTrack={isTimeTrack}
                >
                  <div
                    className={`custom-select cursor-pointer mt-3 mt-md-0 ${
                      isTimeTrack ? 'opacity-75 cursor-pointer pe-none' : ''
                    }`}
                  >
                    {selectedJob?.name ? (
                      <span>
                        {selectedJob?.name?.length > 10
                          ? `${selectedJob?.name?.slice(0, 8)}...`
                          : selectedJob?.name}
                      </span>
                    ) : (
                      <span>Select Job</span>
                    )}
                    <img src={DOWN_ARROW} alt="select-icon" />
                  </div>
                </CustomDropDown>
              </div>
              <div className="d-flex d-md-none justify-content-end">
                <div
                  className="custom-select cursor-pointer mt-3 mt-md-0"
                  onClick={() => toggleJobModal(true)}
                >
                  {selectedJob?.name ? (
                    <span>
                      {selectedJob?.name?.length > 10
                        ? `${selectedJob?.name?.slice(0, 7)}...`
                        : selectedJob?.name}
                    </span>
                  ) : (
                    <span>Select Job</span>
                  )}
                  <img src={DOWN_ARROW} alt="select-icon" />
                </div>
              </div>
            </div>
            <div className="mt-3 mt-md-0 d-md-block d-flex justify-content-end">
              <button
                className="primary-button primary-button-text"
                onClick={handleClockIn}
                disabled={!selectedJob?.po_number}
              >
                Clock In
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={`${loader ? 'opacity-50' : ''}${
          !(isTimeTrack && size?.width <= MEDIUM_SCREEN_WIDTH)
            ? 'dashboard-container'
            : 'app-container'
        }`}
      >
        {isTimeTrack && size?.width <= MEDIUM_SCREEN_WIDTH ? (
          <TimeTrackMobile currentTracking={currentTracking} />
        ) : (
          <DashboardComp
            isTimeTrack={isTimeTrack}
            timeEntries={timeEntries}
            handleEntryUpdate={handleEntryUpdate}
            setTimeEntries={setTimeEntries}
            weeklyHours={weeklyHours}
            durationMap={durationMap}
            entriesMeta={entriesMeta}
            getWeekTimeEntries={getWeekTimeEntries}
            jobs={jobs}
            teamLeaders={teamLeaders}
          />
        )}
        {isTimeTrack && (
          <TimeTrack
            currentTracking={currentTracking}
            handlePause={handlePause}
            handleClockOut={handleClockOut}
          />
        )}
      </div>

      <div>
        <SelectJob
          isOpen={jobModal}
          handleClose={toggleJobModal}
          jobs={jobs}
          handleSelectedJob={(val) => setSelectedJob(val)}
        />
      </div>
      <Disabled show={disableModal} toggle={toggleDisableModal} />
      <Confirmation
        show={timeAlert}
        toggle={toggleTimeAlert}
        onOkay={handleShortEntry}
        icon={NOT_ALLOWED}
        title={'Alert!'}
        description={"Entries under 1 minute won't be saved. Proceed?"}
      />
    </>
  );
}

export default TimeTrackPage;
