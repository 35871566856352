import DataTable from 'react-data-table-component';
import EXPAND from '../../assets/icons/expand.svg';
import EXPANDED from '../../assets/icons/expanded.svg';
import DELETE from '../../assets/icons/delete.svg';

import moment from 'moment';
import { toHoursAndMinutes } from '../../services/utils';
import { SCREEN } from '../../constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TimeField from '../cutomTimeField/TimeInput';

export default function DashboardLarge({
  entries,
  toggleRowExpansion,
  expandedRows,
  date,
  handleDeleteIconClick,
  updateEntry,
  handleAddUserClick,
}) {
  const tableHeader = [
    {
      name: 'Job ID',
      selector: (row) => (
        <span className="normal-text-primary">{row?._id?.jobId}</span>
      ),
    },
    {
      name: 'Job Name',
      selector: (row) => (
        <span className="normal-text-primary">{row?._id?.name}</span>
      ),
    },
    {
      name: 'Total Time',
      selector: (row) => {
        const totalTimeInSeconds = row?.data?.reduce((total, user) => {
          const userTotal = user.entry.reduce((userTime, entry) => {
            const durationInSeconds = moment(entry.end_time).diff(
              moment(entry.start_time),
              'seconds'
            );
            return userTime + durationInSeconds;
          }, 0);
          return total + userTotal;
        }, 0);
        return (
          <span className="normal-text-primary">
            {toHoursAndMinutes(totalTimeInSeconds, SCREEN.desktop)}
          </span>
        );
      },
    },
    {
      name: 'Action',
      selector: (row) => (
        <div className="d-flex gap-3">
          {moment().format('YYYY-MM-DD') === date ? (
            <button
              className="primary-button-outline"
              onClick={() => handleAddUserClick(row?._id?.id)}
            >
              Add User
            </button>
          ) : (
            ''
          )}
          <img
            className="cursor-pointer"
            alt="expand-icon"
            src={
              expandedRows?.includes(`${row?._id?.date}-${row?._id?.id}`)
                ? EXPANDED
                : EXPAND
            }
            onClick={() => toggleRowExpansion(row)}
          />
        </div>
      ),
    },
  ];

  const expandedTableHeader = [
    {
      name: 'Union Id',
      selector: (row) => row?.user?.union_id,
    },
    {
      name: 'Worker',
      selector: (row) => row?.user?.name,
    },
    {
      name: 'Time',
      selector: (row) => {
        const userTotal = row.entry.reduce((userTime, entry) => {
          const durationInSeconds = moment(entry.end_time).diff(
            moment(entry.start_time),
            'seconds'
          );
          return userTime + durationInSeconds;
        }, 0);
        return (
          <div>
            {moment().format('YYYY-MM-DD') !== date ? (
              <span>{toHoursAndMinutes(userTotal, SCREEN.desktop)}</span>
            ) : (
              <>
                {row?.is_track_by_lead ? (
                  <TimeField
                    className="tt-desc-input time-input normal-text-primary"
                    value={toHoursAndMinutes(userTotal, SCREEN.mobile)}
                    onBlur={(e) =>
                      updateEntry({
                        users: [
                          {
                            _id: row?.user?._id,
                            time: e.target.value,
                            tt_id: row?.timeEntryId,
                          },
                        ],
                        jobId: row?.job?.id,
                      })
                    }
                  />
                ) : (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
                  >
                    <TimeField
                      className="tt-desc-input time-input normal-text-primary"
                      value={toHoursAndMinutes(userTotal, SCREEN.mobile)}
                      disabled
                      onBlur={(e) =>
                        updateEntry({
                          users: [
                            {
                              _id: row?.user?._id,
                              time: e.target.value,
                              tt_id: row?.timeEntryId,
                            },
                          ],
                          jobId: row?.job?.id,
                        })
                      }
                    />
                  </OverlayTrigger>
                )}
              </>
            )}
          </div>
        );
      },
      width: '200px',
    },
    {
      name: 'Action',
      selector: (row) => (
        <div>
          {moment().format('YYYY-MM-DD') !== date || !row?.is_track_by_lead ? (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
            >
              <img className="opacity-50" alt="delete-icon" src={DELETE} />
            </OverlayTrigger>
          ) : (
            <img
              className="cursor-pointer"
              alt="delete-icon"
              src={DELETE}
              onClick={() => handleDeleteIconClick(row?.timeEntryId)}
            />
          )}
        </div>
      ),
    },
  ];

  const ExpandedComponent = ({ data }) => {
    let filteredData = data?.data?.map((dt) => {
      return { ...dt, job: { ...data?._id } };
    });
    return (
      <div className="expanded-wrapper">
        <div className="expanded-inner-wrapper">
          <DataTable columns={expandedTableHeader} data={filteredData} />
        </div>
      </div>
    );
  };
  return (
    <DataTable
      columns={tableHeader}
      data={entries}
      expandableRows
      expandableRowsComponent={ExpandedComponent}
      expandableRowsHideExpander
      expandableRowExpanded={(row) =>
        expandedRows.includes(`${row?._id?.date}-${row?._id?.id}`)
      }
      onRowExpandToggled={(bool, row) => toggleRowExpansion(row)}
    />
  );
}
