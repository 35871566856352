import API from './axiosClient';

const TIME_TRACK_ENPOINT = '/time_track';

export const addTimeEntry = async (data) => {
  return API.post(`${TIME_TRACK_ENPOINT}`, data);
};

export const addSubEntry = async (id, data) => {
  return API.post(`${TIME_TRACK_ENPOINT}/sub_entry/${id}`, data);
};

export const addEntryByLead = async (data) => {
  return API.post(`${TIME_TRACK_ENPOINT}/by_lead`, data);
};

export const pauseTimeEntry = async (data) => {
  return API.put(`${TIME_TRACK_ENPOINT}/pause`, data);
};

export const clockOut = async (data) => {
  return API.put(`${TIME_TRACK_ENPOINT}/clock_out`, data);
};

export const getUnpausedEntry = async () => {
  return API.get(`${TIME_TRACK_ENPOINT}/unpaused`);
};

export const getTimeEntriesByWeek = async (page, limit) => {
  return API.get(
    `${TIME_TRACK_ENPOINT}/current_week/?page=${page}&limit=${limit}`
  );
};

export const getTeamLogs = async (page, limit, start_date, end_date, query) => {
  return API.get(
    `${TIME_TRACK_ENPOINT}/team_logs/?page=${page}&limit=${limit}${
      start_date ? `&start_date=${start_date}` : ''
    }${end_date ? `&end_date=${end_date}` : ''}${
      query ? `&query=${query}` : ''
    }`
  );
};

export const deleteTimeEntry = async (id) => {
  return API.delete(`${TIME_TRACK_ENPOINT}/${id}`);
};

export const deleteSubTimeEntry = async (id, sub_id) => {
  return API.delete(`${TIME_TRACK_ENPOINT}/${id}/sub_entry/${sub_id}`);
};

export const updateTimeEntry = async (id, data) => {
  return API.put(`${TIME_TRACK_ENPOINT}/${id}`, data);
};

export const getTimeEntriesByWeekDays = async (page, limit) => {
  return API.get(
    `${TIME_TRACK_ENPOINT}/current_week_days/?page=${page}&limit=${limit}`
  );
};

export const getMonthHistory = async (page, limit, data) => {
  return API.post(
    `${TIME_TRACK_ENPOINT}/monthly_history/?page=${page}&limit=${limit}`,
    data
  );
};
