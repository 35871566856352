import React from "react";
import DataTable from "react-data-table-component";
import EXPAND from "../../assets/icons/expand.svg";
import EXPANDED from "../../assets/icons/expanded.svg";

import moment from "moment";
import { SCREEN } from "../../constants";

import { toHoursAndMinutes } from "../../services/utils";

import ProfilesCell from '../common/ProfilesCell';

export default function HistoryLarge({
    timeEntries,
    expandedRows,
    toggleRowExpansion,
    durationMap
}) {
    const tableHeader = [
        {
            name: "Date",
            selector: (row) => (
                <span className="normal-text-primary">{moment(row?._id).format("ddd, MMM D")}</span>
            ),
            width: "250px",
        },
        {
            name: "",
            selector: (row) => "",
        },
        {
            name: "Jobs",
            selector: (row) => {
                let jobs = row?.entries?.map((entry) => entry?.job?.name);
                const uniqueJobs = new Set(jobs);
                const uniqueJobsArray = [...uniqueJobs];
                return (
                    <div className="normal-text-primary">
                        {uniqueJobsArray[0]}
                        {uniqueJobsArray.length > 1 ?
                            <span className="more-span px-2 ms-1">
                                {`${uniqueJobsArray.length - 1} more...`}

                            </span>
                            :
                            null
                        }
                        {
                            uniqueJobsArray.length > 1 ?
                                <div className="additional-jobs">
                                    Jobs
                                    <div className="additional-jobs-sub">
                                        {uniqueJobsArray.slice(1).map((job, index) => (
                                            <div key={index}>{job}</div>
                                        ))}
                                    </div>
                                </div> :
                                null
                        }
                    </div>
                );
            },
            allowRowEvents: false,
            width: "250px",
            style: "",
        },
        {
            name: "",
            selector: (row) => "",
        },
        // {
        //     name: "Total Payout Amount",
        //     selector: (row) => {
        //         let one_day_payout_amount = 0;
        //         const entries = row?.entries;
        //         for (const entry of entries) {
        //             one_day_payout_amount += entry.payout_amount;
        //         }
        //         return <div className="normal-text-primary">${one_day_payout_amount.toFixed(2)}</div>;
        //     },
        // },
        {
            name: "Total Time",
            selector: (row) => {
                let durationMapForDay = durationMap.find((entry) => entry.date === row._id);
                return <span className="normal-text-primary">{toHoursAndMinutes(durationMapForDay?.totalSecondsPerDay, SCREEN.desktop)}</span>;
            },
        },
        {
            name: "",
            selector: (row) => "",
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    {row?.entries?.length > 1 && (
                        <img
                            className="cursor-pointer"
                            alt="expand-icon"
                            src={expandedRows?.includes(row?._id) ? EXPANDED : EXPAND}
                            onClick={() => toggleRowExpansion(row)}
                        />
                    )}
                </div>
            )
        }
    ];

    const expandedTableHeader = [
        {
            name: "PO Number",
            selector: (row) => (
                <span className="normal-text-primary">{row?.po_number}</span>
            ),
            width: "160px",
        },
        {
            name: "",
            selector: (row) => "",
        },
        {
            name: "Job Name",
            selector: (row) => (
                <div className="text-label-heading">{row?.job_name}</div>
            ),
            width: "160px",
        },
        {
            name: "",
            selector: (row) => "",
        },
        {
            name: "Team Leaders",
            selector: (row) => <ProfilesCell teamLeaders={row.team_leader} />,
        },
        {
            name: "",
            selector: (row) => "",
        },
        // {
        //     name: "Payout Amount",
        //     selector: (row) => <span className="normal-text">${row?.payout_amount?.toFixed(2)}</span>,
        // },
        {
            name: "Time",
            selector: (row) => <span className="normal-text">{row?.time}</span>
        }
    ];

    const ExpandedComponent = ({ data }) => {
        let filteredData = data?.entries?.map((entry) => {
            let totalTimeSecs = entry?.time_intervals
                ?.map((entry) =>
                    moment(entry?.end_time).diff(moment(entry?.start_time), "seconds")
                )
                .reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                }, 0);

            return {
                po_number: entry?.job?.po_number,
                job_name: entry?.job?.name,
                team_leader: entry?.job?.team_leads,
                payout_amount: entry?.payout_amount,
                time: toHoursAndMinutes(totalTimeSecs, SCREEN.desktop),
                _id: data?._id,
            };
        });
        return (
            <div className="expanded-wrapper">
                <div className="expanded-inner-wrapper">
                    <DataTable
                        columns={expandedTableHeader}
                        data={filteredData}
                    />
                </div>
            </div>
        );
    };

    return (
        <DataTable
            columns={tableHeader}
            data={timeEntries}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            expandableRowsHideExpander
            expandableRowExpanded={(row) => expandedRows.includes(row._id)}
            onRowExpandToggled={(bool, row) => toggleRowExpansion(row)}
        />
    );
}
