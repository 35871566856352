import React, { useEffect, useRef, useState } from 'react';
import './Profile.css';
import userImage from '../../assets/images/user-image.png';
import editIcon from '../../assets/icons/edit-icon.svg';
import downloadIcon from '../../assets/icons/downloadIcon.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getUserDetail, removeProfile, updateUser } from '../../network/users';
import { getBase64 } from '../../network/util';
import { toast } from 'react-toastify';
import moment from 'moment';
import pdfImage from '../../assets/images/pdf-image.png';
import { downloadFile, isPdf } from '../../services/utils';
import { useAuth } from '../../hooks/useAuth';
import { allowedFileTypes } from '../../constants';

const Profile = () => {
  const auth = useAuth();
  const fileInputRef = useRef();
  const [btnId, setBtnId] = useState(1);
  const [profileImage, setProfileImage] = useState();
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState({
    profile_key: '',
    phone: '',
  });

  const [user, setUser] = useState({
    name: '',
    email: '',
    union_id: '',
    profile_key: '',
    title: '',
    phone: '',
  });

  const [certificates, setCertificates] = useState();

  const handleOpenFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (e) => {
    const selectedFile = e?.target?.files && e?.target?.files?.[0];

    // Check if a file is selected
    if (selectedFile) {
      // Check if the selected file type is allowed
      if (allowedFileTypes.includes(selectedFile.type)) {
        const file = await getBase64(selectedFile);
        setProfileImage(file);
        setProfile((prevProfile) => ({
          ...prevProfile,
          profile_key: selectedFile,
        }));
      } else {
        toast.error(
          'Unsupported file type. Please choose a JPG, PNG, or GIF file.'
        );
      }
    }
  };

  const handleUpdate = async () => {
    setLoader(true);

    const formData = new FormData();
    formData.append('image', profile.profile_key);
    formData.append('phone', profile.phone);

    updateUser(formData)
      .then((data) => {
        auth?.updateUser(data?.data?.user);
        toast.success(data?.data?.message);
        setBtnId(1);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error occured while updating user.');
      });
  };

  const handleRemove = async () => {
    setLoader(true);
    removeProfile()
      .then((data) => {
        auth?.updateUser(data?.data?.user);
        toast.success(data?.data?.message);
        setBtnId(1);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error occured while removing profile photo.');
      });
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    getUserDetail()
      .then((data) => {
        auth?.updateUser(data?.data?.user);
        setUser(data?.data?.user);
        setCertificates(data?.data?.certificates);
        setProfile((prevProfile) => ({
          ...prevProfile,
          phone: data?.data?.user?.phone,
        }));

        setProfileImage(data?.data?.user?.image);
      })
      .catch((error) => {
        toast.error('Error in fetching details.');
        console.log(error);
      });
    setLoader(false);
  }, [btnId]);

  const handleDownload = async (url) => {
    await downloadFile(url);
  };

  return (
    <>
      <div className='custom-container'>
        {loader && <div className='loader'></div>}
        <div className='container-fluid'>
          <div className='profile-container'>
            {btnId === 1 && (
              <>
                <h1 className='profile-heading'>Profile</h1>
                <div className='detail-container'>
                  <div>
                    <img
                      src={user?.image || userImage}
                      alt=''
                      className='user-image'
                    />
                  </div>
                  <div className='user-detail'>
                    <div className='user-left'>
                      <div className='profile-heading'>{user?.name}</div>
                      <div className='role'>{user?.title}</div>
                      <div className='user-data'>
                        <div className='text-title '>Union ID:</div>
                        <div className='text-value '>
                          &nbsp;{user?.union_id}
                        </div>
                      </div>
                      <div className='user-data'>
                        <div className='text-title'>Email:</div>
                        <div className='text-value'>{user?.email}</div>
                      </div>
                      <div className='user-data'>
                        <div className='text-title'>Phone:</div>
                        <div className='text-value'>
                          &nbsp; {user?.phone ? '+' + user?.phone : 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className='user-right'>
                      <button className='edit-btn' onClick={() => setBtnId(2)}>
                        <img src={editIcon} alt='' className='mx-1' />
                        Edit Profile
                      </button>
                    </div>
                  </div>
                </div>

                <div className='certificate-container'>
                  <h1 className='certificate-heading'>Certifications</h1>
                  <div className='certificates'>
                    {certificates?.map((certificate) => (
                      <div className='certificate'>
                        <div className='certificate-image'>
                          <img
                            src={
                              certificate?.attachement &&
                              isPdf(certificate?.attachement)
                                ? pdfImage
                                : certificate?.attachement
                            }
                            alt=''
                            className='certificate-iamge'
                          />
                        </div>
                        <div className='certificate-detail'>
                          <div className='profile-heading'>
                            {certificate?.name || 'N/A'}
                          </div>
                          <div className='user-data'>
                            <div className='text-title'>Issued Date:</div>
                            <div className='role'>
                              &nbsp;
                              {moment(certificate?.issue_date).format(
                                'DD-MM-YYYY'
                              ) || 'N/A'}
                            </div>
                          </div>
                          <div className='user-data'>
                            <div className='text-title'>Valid till:</div>
                            <div className='role'>
                              &nbsp;{' '}
                              {moment(certificate?.valid_till).format(
                                'DD-MM-YYYY'
                              ) || 'N/A'}
                            </div>
                          </div>
                          <button
                            className='download-btn'
                            onClick={() =>
                              handleDownload(certificate?.attachement)
                            }
                          >
                            <img src={downloadIcon} alt='' className='mx-1' />
                            Download
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {btnId === 2 && (
              <>
                <h1 className='profile-heading'>Edit Profile</h1>
                <div className='edit-detail'>
                  <div className='top-section-c'>
                    <div>
                      <img
                        src={profileImage || userImage}
                        alt=''
                        className='user-image-2'
                      />
                    </div>
                    <div>
                      <div className='actions'>
                        <button
                          className='btn btn-primary btn-change'
                          onClick={handleOpenFileInput}
                        >
                          Change Photo
                        </button>
                        <button
                          className='btn btn-danger btn-remove'
                          onClick={handleRemove}
                        >
                          Remove Photo
                        </button>
                      </div>
                      <div className='png-size'>
                        Maximum size of 1MB. JPG, GIF, or PNG.
                      </div>
                    </div>
                  </div>
                  <input
                    type='file'
                    className='d-none'
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                  />
                  <div className='bottom-section'>
                    <PhoneInput
                      containerClass='phoneContainer'
                      inputStyle={{ width: '100%' }}
                      placeholder='123-456-789'
                      country={'us'}
                      prefix='+'
                      value={profile.phone}
                      onChange={(value) => {
                        setProfile((prev) => {
                          return { ...prev, phone: value };
                        });
                      }}
                    />
                    <div className='actions-bottom'>
                      <button
                        className='btn  btn-cancel'
                        onClick={() => setBtnId(1)}
                      >
                        Cancel
                      </button>
                      <button
                        className='btn btn-primary btn-change'
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>{' '}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
