import { Modal } from "react-bootstrap";
import CORSS from "../../assets/icons/cross.svg";

export default function Confirmation({
  show,
  toggle,
  title,
  description,
  icon,
  onOkay,
}) {
  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="d-flex justify-content-end">
          <img
            src={CORSS}
            alt="cross-icon"
            className="cursor-pointer"
            onClick={() => toggle(false)}
          />
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-center">
            <img src={icon} alt="not-allowed" />
          </div>
          <div className="modal-inner-wrapper text-center my-4">
            <p className="modal-heading heading-color">{title}</p>
            <span className="text-label">{description}</span>
            <div className="d-flex justify-content-center mt-4 gap-4">
              <button
                className="model-button core-outline-button text-label-heading heading-color"
                onClick={() => toggle(false)}
              >
                No
              </button>
              <button
                className="model-button core-danger-button text-label-heading color-white"
                onClick={onOkay}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
