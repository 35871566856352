import DataTable from 'react-data-table-component';
import { toHoursAndMinutes } from '../../services/utils';
import { SCREEN, TIME_VAR } from '../../constants';
import moment from 'moment';
import TwoDigitTimeField from '../cutomTimeField/TwoDigitTimeField';
import COLON from '../../assets/icons/colon.svg';

export default function DesktopTable({
  selectedJob,
  users,
  handleAddUserClick,
  onTimeEntryUpdate,
}) {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span>{selectedJob?.name ? selectedJob?.name : 'N/A'}</span>
        <button className="primary-button-outline" onClick={handleAddUserClick}>
          Add User
        </button>
      </div>
      <div className="custom-table-log">
        <DataTable
          className="mt-3"
          columns={[
            {
              name: 'Union ID',
              selector: (row) => (
                <span className="normal-text-primary">{row?.union_id}</span>
              ),
            },
            {
              name: 'Worker Name',
              selector: (row) => (
                <span className="normal-text-primary">{row?.name}</span>
              ),
            },
            {
              name: 'Time',
              selector: (row) => (
                <div className="d-flex gap-1 align-items-center">
                  <TwoDigitTimeField
                    value={
                      row?.time_entry
                        ? toHoursAndMinutes(
                            moment(
                              row?.time_entry?.time_intervals[0]?.end_time
                            ).diff(
                              row?.time_entry?.time_intervals[0]?.start_time,
                              'seconds'
                            ),
                            SCREEN.mobile
                          )?.split(':')[0]
                        : ''
                    }
                    min={1}
                    max={23}
                    handleChange={(val) =>
                      onTimeEntryUpdate(val, row?._id, TIME_VAR.hour)
                    }
                  />
                  :
                  <TwoDigitTimeField
                    value={
                      row?.time_entry
                        ? toHoursAndMinutes(
                            moment(
                              row?.time_entry?.time_intervals[0]?.end_time
                            ).diff(
                              row?.time_entry?.time_intervals[0]?.start_time,
                              'seconds'
                            ),
                            SCREEN.mobile
                          )?.split(':')[1]
                        : ''
                    }
                    min={1}
                    max={59}
                    handleChange={(val) =>
                      onTimeEntryUpdate(val, row?._id, TIME_VAR.minute)
                    }
                  />
                </div>

                // <TimeField
                //   className="tt-desc-input time-input normal-text-primary"
                //   value={
                //     row?.time_entry
                //       ? toHoursAndMinutes(
                //           moment(
                //             row?.time_entry?.time_intervals[0]?.end_time
                //           ).diff(
                //             row?.time_entry?.time_intervals[0]?.start_time,
                //             'seconds'
                //           ),
                //           SCREEN.mobile
                //         )
                //       : '00:00'
                //   }
                //   onBlur={(e) => onTimeEntryUpdate(e.target.value, row?._id)}
                // />
              ),
            },
          ]}
          data={users}
        />
      </div>
    </div>
  );
}
