import moment from 'moment';
import DELETE from '../../assets/icons/delete.svg';
import EXPAND from '../../assets/icons/expand-white-bg.svg';
import EXPANDED from '../../assets/icons/expanded-white-bg.svg';
import { SCREEN, UPDATE_ENTRY } from '../../constants';
import { toHoursAndMinutes } from '../../services/utils';
import TimeFieldWrapper from '../cutomTimeField';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TimeField from '../cutomTimeField/TimeInput';
import ProfilesCell from '../common/ProfilesCell';
export default function DashboardSmall({
  timeEntries,
  handleDeleteEntryToggle,
  expandedRows,
  toggleRowExpansion,
  handleEntryUpdate,
  isEditAllowed,
  teamLeaders
}) {
  return (
    <div>
      <div className=" d-flex flex-column gap-3">
        {timeEntries?.map((entry) => (
          <div className="time-entry-wrapper">
            <div className="time-entry-header-wrapper align-items-center d-flex justify-content-between">
              <span className="text-label-primary color-standard">{`${entry?.job?.po_number} (${entry?.job?.name})`}</span>
              <span>
                {expandedRows?.includes(entry?._id) ? (
                  <img
                    src={EXPANDED}
                    alt="expand"
                    onClick={() => toggleRowExpansion(entry)}
                  />
                ) : (
                  <img
                    src={EXPAND}
                    alt="expand"
                    onClick={() => toggleRowExpansion(entry)}
                  />
                )}
              </span>
            </div>
            {expandedRows?.includes(entry?._id) && (
              <div className="time-entry-div">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Job Name</span>
                  <span className="primary-text-color text-label-heading text-decoration-none">
                    {'• '} <u>{` ${entry?.job?.name}`}</u>
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Team Leader</span>
                  <ProfilesCell teamLeaders={teamLeaders} />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Clocked In/Out</span>
                  <div className="d-flex gap-1">
                    {!entry?.is_track_by_lead ? (
                      <>
                        <TimeFieldWrapper
                          timeEntry={
                            entry?.time_intervals?.length > 1
                              ? moment
                                .min(
                                  entry.time_intervals?.map((entry) =>
                                    moment(entry.start_time)
                                  )
                                )
                                .format('HH:mm')
                              : moment(
                                entry?.time_intervals?.[0]?.start_time
                              ).format('HH:mm')
                          }
                          handleBlur={(e) => {
                            handleEntryUpdate(
                              moment(
                                entry?.time_intervals?.[0]?.start_time
                              ).format('HH:mm'),
                              e.target.value,
                              entry,
                              UPDATE_ENTRY?.colckIn,
                              entry?.time_intervals[0]?._id
                            );
                          }}
                          entryType={UPDATE_ENTRY.colckIn}
                          startTime={entry?.time_intervals?.[0]?.start_time}
                          endTime={
                            entry?.time_intervals[
                              entry?.time_intervals?.length - 1
                            ]?.end_time
                          }
                          disabled={
                            !isEditAllowed || entry?.time_intervals?.length > 1
                          }
                        />
                        <TimeFieldWrapper
                          timeEntry={
                            entry?.time_intervals?.length > 1
                              ? moment
                                .max(
                                  entry.time_intervals?.map((entry) =>
                                    moment(entry.end_time)
                                  )
                                )
                                .format('HH:mm')
                              : moment(
                                entry?.time_intervals?.[
                                  entry?.time_intervals?.length - 1
                                ]?.end_time
                              ).format('HH:mm')
                          }
                          handleBlur={(e) => {
                            handleEntryUpdate(
                              moment(
                                entry?.time_intervals?.[
                                  entry?.time_intervals?.length - 1
                                ]?.end_time
                              ).format('HH:mm'),
                              e.target.value,
                              entry,
                              UPDATE_ENTRY?.clockout,
                              entry?.time_intervals?.[
                                entry?.time_intervals?.length - 1
                              ]?._id
                            );
                          }}
                          entryType={UPDATE_ENTRY.clockout}
                          startTime={entry?.time_intervals[0]?.start_time}
                          endTime={
                            entry?.time_intervals[
                              entry?.time_intervals?.length - 1
                            ]?.end_time
                          }
                          disabled={
                            !isEditAllowed ||
                            entry?.time_intervals?.length !== 1
                          }
                        />
                      </>
                    ) : (
                      <>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
                        >
                          <TimeField
                            value={
                              entry?.time_intervals?.length > 1
                                ? moment
                                  .min(
                                    entry.time_intervals?.map((entry) =>
                                      moment(entry.start_time)
                                    )
                                  )
                                  .format('HH:mm')
                                : moment(
                                  entry?.time_intervals?.[0]?.start_time
                                ).format('HH:mm')
                            }
                            className="tt-desc-input time-input normal-text-primary"
                            disabled
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
                        >
                          <TimeField
                            value={
                              entry?.time_intervals?.length > 1
                                ? moment
                                  .max(
                                    entry.time_intervals?.map((entry) =>
                                      moment(entry.end_time)
                                    )
                                  )
                                  .format('HH:mm')
                                : moment(
                                  entry?.time_intervals?.[
                                    entry?.time_intervals?.length - 1
                                  ]?.end_time
                                ).format('HH:mm')
                            }
                            className="tt-desc-input time-input normal-text-primary"
                            disabled
                          />
                        </OverlayTrigger>
                      </>
                    )}
                  </div>
                </div>
                {entry?.time_intervals?.length > 1 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="text-label-primary">Entries</span>
                    <div className="d-flex flex-column gap-1">
                      {entry?.time_intervals?.map((entity) => {
                        return (
                          <div
                            className="d-flex gap-1"
                            key={`entity_${entity?._id}`}
                          >
                            <TimeFieldWrapper
                              timeEntry={moment(entity?.start_time).format(
                                'HH:mm'
                              )}
                              handleBlur={(e) => {
                                handleEntryUpdate(
                                  moment(entity?.start_time).format('HH:mm'),
                                  e.target.value,
                                  {
                                    sub_id: entity?._id,
                                    start_time: entity?.start_time,
                                    end_time: entity?.end_time,
                                    job: entry?.job,
                                    description: entry?.description,
                                    _id: entry?._id,
                                  },
                                  UPDATE_ENTRY?.colckIn,
                                  entity?._id,
                                  true
                                );
                              }}
                              entryType={UPDATE_ENTRY.colckIn}
                              startTime={entity?.start_time}
                              endTime={entity?.end_time}
                              disabled={!isEditAllowed}
                            />
                            <TimeFieldWrapper
                              timeEntry={
                                entity?.end_time
                                  ? moment(entity?.end_time).format('HH:mm')
                                  : '__:__'
                              }
                              handleBlur={(e) => {
                                handleEntryUpdate(
                                  moment(entity?.end_time).format('HH:mm'),
                                  e.target.value,
                                  {
                                    sub_id: entity?._id,
                                    start_time: entity?.start_time,
                                    end_time: entity?.end_time,
                                    job: entry?.job,
                                    description: entry?.description,
                                    _id: entry?._id,
                                  },
                                  UPDATE_ENTRY?.clockout,
                                  entity?._id,
                                  true
                                );
                              }}
                              entryType={UPDATE_ENTRY.clockout}
                              startTime={entity?.start_time}
                              endTime={entity?.end_time}
                              disabled={!isEditAllowed}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Total Time</span>
                  <span className="text-label-heading">
                    {toHoursAndMinutes(
                      entry?.time_intervals
                        ?.map((entry) =>
                          moment(entry?.end_time).diff(
                            moment(entry?.start_time),
                            'seconds'
                          )
                        )
                        .reduce((accumulator, currentValue) => {
                          return accumulator + currentValue;
                        }, 0),
                      SCREEN.mobile
                    )}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Action</span>
                  <span>
                    {!entry?.is_track_by_lead ? (
                      <img
                        src={DELETE}
                        alt="delte-icon"
                        onClick={() =>
                          handleDeleteEntryToggle({
                            entry_id: entry?._id,
                            is_subentry: false,
                          })
                        }
                      />
                    ) : (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
                      >
                        <img
                          className="opacity-50"
                          alt="delete-icon"
                          src={DELETE}
                        />
                      </OverlayTrigger>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
