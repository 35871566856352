import moment from 'moment';
import { SCREEN } from '../constants';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

export function toHoursAndMinutes(totalSeconds, screenType) {
  if (totalSeconds === 0) {
    return '00h 00m';
  }

  const totalMinutes = Math.floor(totalSeconds / 60);

  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;

  let formattedTime =
    screenType === SCREEN.desktop
      ? `${hours ? `${hours < 10 ? `0${hours}` : hours}h` : ''} ${
          minutes ? `${minutes < 10 ? `0${minutes}` : minutes}m` : ''
        }`
      : `${hours ? `${hours < 10 ? `0${hours}` : hours}:` : '00:'}${
          minutes ? `${minutes < 10 ? `0${minutes}` : minutes}` : '00'
        }`;

  return formattedTime.trim();
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // reader.onload = () => {
    //   resolve(reader.result);
    // };

    reader.onload = (event) => {
      const base64 = event.target.result; // Base64 representation of the file
      const mimeType = base64?.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type from the base64 string
      resolve({ base64, mimeType });
    };

    reader.onerror = () => {
      reject(new Error('Error reading file.'));
    };

    reader.readAsDataURL(file);
  });
}

export const getDaysDiff = (start_date, end_date) => {
  return moment(end_date).diff(start_date, 'days') + 1;
};

export const lastDateOfWeek = () => {
  const today = new Date();
  const lastDayOfPreviousWeek = new Date(today);
  lastDayOfPreviousWeek.setDate(today.getDate() - ((today.getDay() + 7) % 7));
  return lastDayOfPreviousWeek;
};

export const minutesToHours = (minutes) => {
  const minute = Math.floor(parseInt(minutes) % 60);
  let hours = Math.floor(parseInt(minutes) / 60);
  return `${hours}h ${minute}m`;
};

export const millisecondsToHoursAndMinutes = (milliseconds) => {
  if (milliseconds < 0) {
    return 'Invalid input';
  }

  const totalMinutes = Math.floor(milliseconds / (1000 * 60));
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${hours}h ${minutes}m`;
};

export const isPdf = (url) => {
  const fileExtension = url.split('.').pop();
  const isPDF = fileExtension.toLowerCase() === 'pdf';
  return isPDF ? true : false;
};

export const downloadFile = async (fileUrl) => {
  const paths = fileUrl.split('/');
  const fileName = paths[paths.length - 1];
  const response = await fetch(fileUrl);
  const fileData = await response.blob();
  saveAs(fileData, fileName);
};

export const isNumber = (value) => {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
};

export const getFilePreview = async (file, local = true) => {
  const url = file?.url || file?.name;
  if (!url) {
    return '';
  }
  const parts = url.split('.');
  const ext = parts[parts.length - 1].toLowerCase();
  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return local
        ? {
            preview: await getBase64(file),
            size: file.size,
            type: file.type,
            name: file.name,
            ext,
            previewed: true,
          }
        : file.url;

    default:
      return local
        ? {
            preview: await getBase64(file),
            size: file.size,
            type: file.type,
            name: file.name,
            ext,
            previewed: true,
          }
        : file.url;
  }
};

export const getSubString = (str) => {
  if (!str) {
    return '';
  }
  if (str.length > 12) {
    return str.substr(0, 12) + '...';
  }
  return str;
};

export const generateUniqueId = () => uuidv4();
