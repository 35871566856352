import React from 'react';
import DataTable from 'react-data-table-component';
import DOWN_ARROW from '../../assets/icons/down-arrow.svg';
import EXPAND from '../../assets/icons/expand.svg';
import EXPANDED from '../../assets/icons/expanded.svg';
import DELETE from '../../assets/icons/delete.svg';
import CustomDropDown from '../dropdown';

import moment from 'moment';
import { SCREEN, UPDATE_ENTRY } from '../../constants';

import { toHoursAndMinutes } from '../../services/utils';
import TimeFieldWrapper from '../cutomTimeField';
import TimeField from '../cutomTimeField/TimeInput';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProfilesCell from '../common/ProfilesCell';

export default function DashboardLarge({
  timeEntries,
  handleDeleteEntryToggle,
  expandedRows,
  toggleRowExpansion,
  handleEntryUpdate,
  jobs,
  isEditAllowed,
  teamLeaders
}) {
  const tableHeader = [
    {
      name: 'Job ID',
      selector: (row) => (
        <span className="normal-text-primary">{row?.job?.po_number}</span>
      ),
      width: '120px',
    },
    {
      name: 'Job Name',
      selector: (row) => (
        <>
          {isEditAllowed ? (
            <CustomDropDown
              jobs={jobs}
              handleSelectedJob={(newJob) =>
                handleEntryUpdate(row?.job?._id, newJob, row, UPDATE_ENTRY?.job)
              }
            >
              <div className="core-tt-select-job cursor-pointer normal-text color-white w-100 d-flex justify-content-between">
                <span>{row?.job?.name}</span>
                <img src={DOWN_ARROW} alt="down-arrow" />
              </div>
            </CustomDropDown>
          ) : (
            <div className="core-tt-select-job cursor-pointer normal-text color-white w-100 d-flex justify-content-between">
              <span>{row?.job?.name}</span>
              <img src={DOWN_ARROW} alt="down-arrow" />
            </div>
          )}
        </>
      ),
      allowRowEvents: false,
      width: '200px',
      style: '',
    },
    {
      name: 'Description',
      selector: (row) => (
        <input
          className="tt-desc-input normal-text-primary"
          defaultValue={row?.description ? row?.description : ''}
          onBlur={(e) =>
            handleEntryUpdate(
              e.target.defaultValue,
              e.target.value,
              row,
              UPDATE_ENTRY?.description
            )
          }
          disabled={!isEditAllowed}
        />
      ),
      width: '200px',
    },
    {
      name: 'Team Leaders',
      selector: () => <ProfilesCell teamLeaders={teamLeaders} />
    },
    {
      name: 'Clocked In',
      selector: (row) => {
        return (
          <>
            {!row?.is_track_by_lead ? (
              <TimeFieldWrapper
                timeEntry={
                  row?.time_intervals?.length > 1
                    ? moment
                      .min(
                        row.time_intervals?.map((entry) =>
                          moment(entry.start_time)
                        )
                      )
                      .format('HH:mm')
                    : moment(row?.time_intervals?.[0]?.start_time).format(
                      'HH:mm'
                    )
                }
                handleBlur={(e) => {
                  handleEntryUpdate(
                    moment(row?.time_intervals?.[0]?.start_time).format(
                      'HH:mm'
                    ),
                    e.target.value,
                    row,
                    UPDATE_ENTRY?.colckIn,
                    row?.time_intervals[0]?._id
                  );
                }}
                entryType={UPDATE_ENTRY.colckIn}
                startTime={row?.time_intervals?.[0]?.start_time}
                endTime={
                  row?.time_intervals[row?.time_intervals?.length - 1]?.end_time
                }
                disabled={!isEditAllowed || row?.time_intervals?.length > 1}
              />
            ) : (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
              >
                <TimeField
                  value={
                    row?.time_intervals?.length > 1
                      ? moment
                        .min(
                          row.time_intervals?.map((entry) =>
                            moment(entry.start_time)
                          )
                        )
                        .format('HH:mm')
                      : moment(row?.time_intervals?.[0]?.start_time).format(
                        'HH:mm'
                      )
                  }
                  className="tt-desc-input time-input normal-text-primary"
                  disabled
                />
              </OverlayTrigger>
            )}
          </>
        );
      },
    },
    {
      name: 'Clocked Out',
      selector: (row) => (
        <>
          {!row?.is_track_by_lead ? (
            <TimeFieldWrapper
              timeEntry={
                row?.time_intervals?.length > 1
                  ? moment
                    .max(
                      row.time_intervals?.map((entry) =>
                        moment(entry.end_time)
                      )
                    )
                    .format('HH:mm')
                  : moment(
                    row?.time_intervals?.[row?.time_intervals?.length - 1]
                      ?.end_time
                  ).format('HH:mm')
              }
              handleBlur={(e) => {
                handleEntryUpdate(
                  moment(
                    row?.time_intervals?.[row?.time_intervals?.length - 1]
                      ?.end_time
                  ).format('HH:mm'),
                  e.target.value,
                  row,
                  UPDATE_ENTRY?.clockout,
                  row?.time_intervals?.[row?.time_intervals?.length - 1]?._id
                );
              }}
              entryType={UPDATE_ENTRY.clockout}
              startTime={row?.time_intervals[0]?.start_time}
              endTime={
                row?.time_intervals[row?.time_intervals?.length - 1]?.end_time
              }
              disabled={!isEditAllowed || row?.time_intervals?.length !== 1}
            />
          ) : (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
            >
              <TimeField
                value={
                  row?.time_intervals?.length > 1
                    ? moment
                      .max(
                        row.time_intervals?.map((entry) =>
                          moment(entry.end_time)
                        )
                      )
                      .format('HH:mm')
                    : moment(
                      row?.time_intervals?.[row?.time_intervals?.length - 1]
                        ?.end_time
                    ).format('HH:mm')
                }
                className="tt-desc-input time-input normal-text-primary"
                disabled
              />
            </OverlayTrigger>
          )}
        </>
      ),
    },
    {
      name: 'Total Time',
      selector: (row) => {
        let totalTimeSecs = row?.time_intervals
          ?.map((entry) =>
            moment(entry?.end_time).diff(moment(entry?.start_time), 'seconds')
          )
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
        let hoursAndMin = toHoursAndMinutes(totalTimeSecs, SCREEN.desktop);
        return <span className="text-label-heading">{hoursAndMin}</span>;
      },
    },
    {
      name: 'Action',
      selector: (row) => (
        <div className="d-flex gap-4">
          {!row?.is_track_by_lead ? (
            <img
              className="cursor-pointer"
              alt="delete-icon"
              src={DELETE}
              onClick={() =>
                handleDeleteEntryToggle({
                  entry_id: row?._id,
                  is_subentry: false,
                })
              }
            />
          ) : (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip">Not allowed</Tooltip>}
            >
              <img className="opacity-50" alt="delete-icon" src={DELETE} />
            </OverlayTrigger>
          )}
          {row?.time_intervals?.length > 1 && (
            <img
              className="cursor-pointer"
              alt="expand-icon"
              src={expandedRows?.includes(row?._id) ? EXPANDED : EXPAND}
              onClick={() => toggleRowExpansion(row)}
            />
          )}
        </div>
      ),
    },
  ];

  const expandedTableHeader = [
    {
      name: 'Job ID',
      width: '75px',
    },
    {
      name: 'Job Name',
      selector: (row) => (
        <div className="core-tt-select-job cursor-pointer pe-none d-flex justify-content-between">
          <span>{row?.job?.name} </span>
          <img src={DOWN_ARROW} alt="down-arrow" />
        </div>
      ),
      width: '200px',
    },
    {
      name: 'Description',
      selector: (row) => (
        <input
          className="tt-desc-input normal-text-primary"
          value={row.description}
          disabled
        />
      ),
      width: '200px',
    },
    {
      name: 'Team Leader',
      selector: () => { " " }
    },
    {
      name: 'Clocked In',
      selector: (row) => {
        return (
          <TimeFieldWrapper
            timeEntry={moment(row?.start_time).format('HH:mm')}
            handleBlur={(e) => {
              handleEntryUpdate(
                moment(row?.start_time).format('HH:mm'),
                e.target.value,
                row,
                UPDATE_ENTRY?.colckIn,
                row?.sub_id,
                true
              );
            }}
            entryType={UPDATE_ENTRY.colckIn}
            startTime={row?.start_time}
            endTime={row?.end_time}
            disabled={!isEditAllowed}
          />
        );
      },
    },
    {
      name: 'Clocked Out',
      selector: (row) => {
        return (
          <TimeFieldWrapper
            timeEntry={
              row?.end_time ? moment(row?.end_time).format('HH:mm') : '__:__'
            }
            handleBlur={(e) => {
              handleEntryUpdate(
                moment(row?.end_time).format('HH:mm'),
                e.target.value,
                row,
                UPDATE_ENTRY?.clockout,
                row?.sub_id,
                true
              );
            }}
            entryType={UPDATE_ENTRY.clockout}
            startTime={row?.start_time}
            endTime={row?.end_time}
            disabled={!isEditAllowed}
          />
        );
      },
    },
    {
      name: 'Total Time',
      selector: (row) => {
        let hoursAndMin = toHoursAndMinutes(
          moment(row?.end_time).diff(moment(row?.start_time), 'seconds'),
          SCREEN.desktop
        );
        return row?.end_time ? (
          <span className="text-label-heading">{hoursAndMin}</span>
        ) : (
          'N/A'
        );
      },
    },
    {
      name: 'Action',
      selector: (row) => (
        <div>
          <img
            className="cursor-pointer"
            alt="delete-icon"
            src={DELETE}
            onClick={() =>
              handleDeleteEntryToggle({
                entry_id: row?._id,
                is_subentry: true,
                subentry_id: row?.sub_id,
              })
            }
          />
        </div>
      ),
    },
  ];

  const ExpandedComponent = ({ data }) => {
    let filteredData = data?.time_intervals?.map((entry) => {
      return {
        sub_id: entry?._id,
        start_time: entry?.start_time,
        end_time: entry?.end_time,
        job: data?.job,
        description: data?.description,
        _id: data?._id,
      };
    });
    return (
      <div className="expanded-wrapper">
        <div className="expanded-inner-wrapper">
          <DataTable
            noTableHead
            columns={expandedTableHeader}
            data={filteredData}
          />
        </div>
      </div>
    );
  };

  return (
    <DataTable
      columns={tableHeader}
      data={timeEntries}
      expandableRows
      expandableRowsComponent={ExpandedComponent}
      expandableRowsHideExpander
      expandableRowExpanded={(row) => expandedRows.includes(row._id)}
      onRowExpandToggled={(bool, row) => toggleRowExpansion(row)}
    />
  );
}
