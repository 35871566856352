import React, { useEffect, useLayoutEffect, useState } from 'react';
import './Notifications.css';
import { Container } from 'react-bootstrap';
import moment from 'moment';
import {
  getNotifications,
  getNotificationsCount,
  markAllRead,
} from '../../network/notification';
import { generateUniqueId } from '../../services/utils';
import readNotif from '../../assets/images/read-notif.svg';
import Pagination from '../pagination';
import { connect } from 'socket.io-client';

function Notifications() {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [entriesMeta, setEnriesMeta] = useState({});
  const [socket, setSocket] = useState();

  useEffect(() => {}, [entriesMeta]);

  const fetchNotification = async (currentPage = 1, limit = 10) => {
    try {
      const [data, count] = await Promise.all([
        getNotifications({ size: limit, pageNo: currentPage }),
        getNotificationsCount(),
      ]);
      setNotifications(data?.data?.data);
      setEnriesMeta(data?.data?.meta);
      setNotificationCount(count?.data?.unseenCount);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  useLayoutEffect(() => {
    const newSocket = connect(
      process.env.REACT_APP_API_BASE_URL.replace('/api/v1', '')
    );
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleClearAll = async () => {
    try {
      await markAllRead();
      fetchNotification();
      socket.emit(`update`);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (val) => {
    setCurrentPage(Number(val));
    fetchNotification(val, limit);
  };

  const handleNextPageClick = () => {
    if (Math.ceil(entriesMeta?.total_count / limit) < currentPage + 1) {
      setCurrentPage((prev) => prev + 1);
      fetchNotification(currentPage + 1, limit);
    }
  };

  const handlePreviousPageClick = () => {
    if (currentPage - 1 > 0) {
      setCurrentPage((prev) => prev - 1);
      fetchNotification(currentPage - 1, limit);
    }
  };

  const handleLimitChange = (val) => {
    setLimit(val);
    fetchNotification(currentPage, val);
  };

  return (
    <div className="mt-5">
      <Container fluid>
        <div className="auth-box tender-detail-box p-0">
          <div className="separate-small notif-header border-bottom d-flex align-items-center justify-content-between">
            <h5 className="mb-0 bold-text">
              Notifications {notificationCount ? `(${notificationCount})` : ''}
            </h5>
            <div className="d-flex justify-content-end">
              <button className="btn-mark" onClick={handleClearAll}>
                Mark All Read
              </button>
            </div>
          </div>
          <div>
            {notifications.length ? (
              notifications.map((notif) => (
                <div
                  role="button"
                  key={generateUniqueId()}
                  className={`py-3 px-5 border-bottom ${
                    !notif?.is_seen_by_user && 'unread-notif'
                  }`}
                >
                  <div className="d-flex align-items-center">
                    <div>
                      Your certificate "{notif?.certificateName}" has been
                      expired. Please contact admin!
                    </div>
                  </div>
                  <div className="mt-2 d-flex align-items-center justify-content-between">
                    <small className="secondary-text-color">
                      {moment(notif?.notify_date).format('DD-MM-YYYY')}
                    </small>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-5 text-center">No Notifications</div>
            )}
          </div>
        </div>
        <div className="mt-3">
          <Pagination
            currentPage={currentPage}
            limit={limit}
            totalPage={Math.ceil(entriesMeta?.total_count / limit)}
            handlePageChange={handlePageChange}
            handleLimitChange={(val) => handleLimitChange(Number(val))}
            handleNextPageClick={handleNextPageClick}
            handlePreviousPageClick={handlePreviousPageClick}
          />
        </div>
      </Container>
    </div>
  );
}

export default Notifications;
