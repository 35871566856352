import React from 'react';
import DataTable from 'react-data-table-component';
import DOWN_ARROW from '../../assets/icons/down-arrow.svg';
import EXPAND from '../../assets/icons/expand.svg';
import EXPANDED from '../../assets/icons/expanded.svg';
import DELETE from '../../assets/icons/delete.svg';
import CustomDropDown from '../dropdown';

import moment from 'moment';
import { SCREEN, UPDATE_ENTRY } from '../../constants';

import {
  millisecondsToHoursAndMinutes,
  toHoursAndMinutes,
} from '../../services/utils';
import TimeFieldWrapper from '../cutomTimeField';
import ProfilesCell from '../common/ProfilesCell';

export default function DashboardLarge({
  timeEntries,
  expandedRows,
  toggleRowExpansion,
  jobs
}) {
  const tableHeader = [
    {
      name: 'PO Number',
      selector: (job) => (
        <span className="normal-text-primary">{job?.po_number}</span>
      ),
      width: '20%',
    },
    {
      name: 'Name',
      selector: (job) => (
        <span className="normal-text-primary">{job?.name}</span>
      ),
      width: '25%',
    },
    {
      name: 'Team Leader',
      selector: (job) => <ProfilesCell teamLeaders={job.team_leaders} />,
      width: '25%',
    },
    {
      name: 'Total Time',
      selector: (job) => (
        <span className="normal-text-primary">
          {millisecondsToHoursAndMinutes(job?.totalTime)}
        </span>
      ),
      width: '30%',
    },
  ];

  const expandedTableHeader = [
    {
      name: 'PO Number',
      width: '75px',
    },
    {
      name: 'Name',
      selector: (row) => (
        <div className="core-tt-select-job cursor-pointer pe-none d-flex justify-content-between">
          <span>{row?.job?.name} </span>
          <img src={DOWN_ARROW} alt="down-arrow" />
        </div>
      ),
      width: '200px',
    },
    {
      name: 'Team Leader',
      selector: (row) => 'TL',
    },
    {
      name: 'Total Time',
      selector: (job) => (
        <span className="normal-text-primary">{job?.totalTime}</span>
      ),
    },
  ];

  const ExpandedComponent = ({ data }) => {
    let filteredData = jobs?.map((entry) => {
      return {
        po_number: entry?.po_number,
        name: entry?.name,
        team_leaders: entry?.team_leaders,
        totalTime: entry?.totalTime,
      };
    });

    return (
      <div className="expanded-wrapper">
        <div className="expanded-inner-wrapper">
          <DataTable
            noTableHead
            columns={expandedTableHeader}
            data={filteredData}
          />
        </div>
      </div>
    );
  };

  return (
    <DataTable
      columns={tableHeader}
      data={timeEntries}
      expandableRows
      expandableRowsComponent={ExpandedComponent}
      expandableRowsHideExpander
      expandableRowExpanded={(row) => expandedRows.includes(row.po_number)}
      onRowExpandToggled={(bool, row) => toggleRowExpansion(row)}
    />
  );
}
