import moment from 'moment';
import DELETE from '../../assets/icons/delete.svg';
import EXPAND from '../../assets/icons/expand-white-bg.svg';
import EXPANDED from '../../assets/icons/expanded-white-bg.svg';
import { SCREEN, UPDATE_ENTRY } from '../../constants';
import {
  millisecondsToHoursAndMinutes,
  minutesToHours,
  toHoursAndMinutes,
} from '../../services/utils';
import TimeFieldWrapper from '../cutomTimeField';
import ProfilesCell from '../common/ProfilesCell';
export default function DashboardSmall({
  timeEntries,
  handleDeleteEntryToggle,
  expandedRows,
  toggleRowExpansion,
  handleEntryUpdate,
  isEditAllowed
}) {
  return (
    <div>
      <div className=" d-flex flex-column gap-3">
        {timeEntries?.map((entry) => (
          <div className="time-entry-wrapper">
            <div className="time-entry-header-wrapper align-items-center d-flex justify-content-between">
              <span className="text-label-primary color-standard">{`${entry?.po_number} (${entry?.name})`}</span>
              <span>
                {expandedRows?.includes(entry?.po_number) ? (
                  <img
                    src={EXPANDED}
                    alt="expand"
                    onClick={() => toggleRowExpansion(entry)}
                  />
                ) : (
                  <img
                    src={EXPAND}
                    alt="expand"
                    onClick={() => toggleRowExpansion(entry)}
                  />
                )}
              </span>
            </div>
            {expandedRows?.includes(entry?.po_number) && (
              <div className="time-entry-div">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Job Name</span>
                  <span className="primary-text-color text-label-heading text-decoration-none">
                    {'• '} <u>{` ${entry?.name}`}</u>
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Team Leader</span>
                  <span className="text-label-heading">
                    <ProfilesCell teamLeaders={entry.team_leaders} />
                  </span>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Total Time</span>
                  <span className="text-label-heading">
                    {millisecondsToHoursAndMinutes(entry?.totalTime)}
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
