import { useCallback, useEffect, useState } from 'react';
import { MEDIUM_SCREEN_WIDTH, SCREEN } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { getDaysDiff, toHoursAndMinutes } from '../../services/utils';
import ProfilesCell from '../common/ProfilesCell';
import SafetyformTableMobile from './SafetyformTableMobile';
import DateRangeFilter from '../modals/DateRangeFilter';
import dateImg from '../../assets/icons/lets-icons_date-fill.png';
import truck from '../../assets/icons/truck.svg';
import _debounce from 'lodash/debounce';
import Pagination from '../pagination';
import expand from '../../assets/icons/expand.svg';
import expanded from '../../assets/icons/expanded.svg';
import './SafetyFormTable.css';
import pdfIcon from '../../assets/icons/pngegg.png';

export default function SafetyformTable({
  entriesMeta,
  getForms,
  data,
  filters,
  setFilter,
}) {
  const size = useWindowSize();
  const [expandedRows, setExpandedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState(filters?.search || '');
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(entriesMeta.current_page);
  const [expandedRowId, setExpandedRowId] = useState(null);

  const date = new Date();
  useEffect(() => {
    getForms(parseInt(currentPage), parseInt(limit));
  }, [currentPage, limit]);

  const toggleRowExpansion = (row) => {
    const newRowId = expandedRowId === row._id ? null : row._id;
    setExpandedRowId(newRowId);
  };

  // To expand multiple rows

  // const toggleRowExpansion = (row) => {
  //   if (expandedRows.includes(row._id)) {
  //     setExpandedRows(expandedRows.filter((id) => id !== row?._id));
  //   } else {
  //     setExpandedRows([...expandedRows, row?._id]);
  //   }
  // };

  const tableHeader = [
    {
      name: 'Job Name',
      selector: (data) => (
        <span className='normal-text-primary'>{data?.job}</span>
      ),
      width: '200px',
      style: '',
    },
    {
      name: 'VIN',
      selector: (data) => (
        <span className='normal-text-primary'>
          {data?.basicInfo?.vin_number}
        </span>
      ),
    },
    {
      name: 'Date',
      selector: (data) => (
        <span className='normal-text-primary'>
          {moment(data?.safetyForm?.createdAt).format('DD MMM, YYYY')}
        </span>
      ),
    },
    {
      name: 'Make',
      selector: (data) => (
        <span className='normal-text-primary'>
          {data?.basicInfo?.make || 'N/A'}
        </span>
      ),
    },
    {
      name: 'Model',
      selector: (data) => (
        <span className='normal-text-primary'>
          {data?.basicInfo?.model || 'N/A'}
        </span>
      ),
    },
    {
      name: 'License Number',
      selector: (data) => (
        <span className='normal-text-primary'>
          {data?.basicInfo?.license_plate_number || 'N/A'}
        </span>
      ),
    },
    {
      name: 'Year',
      selector: (data) => (
        <span className='normal-text-primary'>
          {data?.basicInfo?.year || 'N/A'}
        </span>
      ),
    },
    {
      name: 'Last Reading',
      selector: (data) => (
        <span className='normal-text-primary'>
          {data?.safetyForm?.odometer_reading
            ? data?.safetyForm?.odometer_reading + ' KM'
            : 'N/A'}
        </span>
      ),
    },
    {
      name: 'Action',
      selector: (data) => (
        <span>
          {expandedRowId === data?._id ? (
            <img
              src={expanded}
              alt='expanded'
              onClick={() => toggleRowExpansion(data)}
            />
          ) : (
            <img
              src={expand}
              alt='expand'
              onClick={() => toggleRowExpansion(data)}
            />
          )}
        </span>
      ),
    },
  ];

  const handleOkay = (filters) => {
    setFilter(filters);
    setShowModal(false);
  };

  const clearFilters = () => {
    setFilter(() => ({ start_date: '', end_date: '' }));
    setShowModal(false);
  };

  const filterButton = () => (
    <button
      className='primary-button-outline'
      onClick={() => setShowModal(true)}
    >
      <div className='d-flex align-items-center justify-content-center'>
        <img className='me-1' src={dateImg} alt='filter' />
        <span>Filter</span>
      </div>
    </button>
  );

  const handleDebounce = (searchVal) => {
    setFilter((prev) => ({ ...prev, search: searchVal }));
  };

  const debounceFn = useCallback(_debounce(handleDebounce, 500), []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  const getWeekDates = () => {
    return (
      moment(date).startOf('week').format('MMM DD, YYYY') +
      ' to ' +
      moment(date).endOf('week').format('MMM DD, YYYY')
    );
  };

  const getRangeDates = () => {
    return (
      moment(filters?.start_date).format('MMM DD, YYYY') +
      ' to ' +
      moment(filters?.end_date).format('MMM DD, YYYY')
    );
  };

  const handleLimitChange = (val) => {
    setCurrentPage(1);
    setLimit(val);
  };

  const expandableRowComponent = ({ data }) => (
    <div className='expanded-row'>
      <div className='d-flex flex-row justify-content-between sub-heading-text'>
        <div className='d-flex flex-row gap-5'>
          <div className='d-flex flex-column gap-2 '>
            <div>Vehicle Image</div>
            <div>
              <img
                src={data?.basicInfo?.vehicle_photos[0]?.imageUrl || truck}
                alt='vehicle img'
                width={'80px'}
                height={'80px'}
              />
            </div>
          </div>
          <div className='d-flex flex-column gap-2 sub-heading-text ms-3'>
            <div>Odometer Reading</div>
            <div className='data-font'>
              {data?.safetyForm?.odometer_reading
                ? data?.safetyForm?.odometer_reading + ' KM'
                : 'N/A'}
            </div>
          </div>
        </div>
        <div className='d-flex flex-column gap-2 '>
          <div>Weather</div>
          {data?.safetyForm?.weather ? (
            <div className='data-font'>
              {data?.safetyForm?.weather?.city}{' '}
              <span>
                ({Math.round(data?.safetyForm?.weather?.current_temperature)}
                &deg;)
              </span>
            </div>
          ) : (
            'N/A'
          )}
        </div>
      </div>
      <div className='d-flex flex-row mt-4 gap-4 sub-heading-text'>
        <div className='d-flex flex-column gap-2 '>
          <div>Engine Oil Checked?</div>
          <div className='data-font'>
            {data?.safetyForm?.is_engine_oil_been_checked ? 'Yes' : 'No'}
          </div>
        </div>
        <div className='d-flex flex-column gap-2 '>
          <div>Brakes Checked?</div>
          <div className='data-font'>
            {data?.safetyForm?.is_brakes_been_checked ? 'Yes' : 'No'}
          </div>
        </div>
        <div className='d-flex flex-column gap-2 '>
          <div>Lights Checked?</div>
          <div className='data-font'>
            {data?.safetyForm?.is_lights_been_checked ? 'Yes' : 'No'}
          </div>
        </div>
        <div className='d-flex flex-column gap-2 '>
          <div>Tires Checked?</div>
          <div className='data-font'>
            {data?.safetyForm?.is_tires_been_checked ? 'Yes' : 'No'}
          </div>
        </div>
        <div className='d-flex flex-column gap-2 '>
          <div>Any Visual Defects?</div>
          <div className='data-font'>
            {data?.safetyForm?.any_visual_defects ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column gap-2 mt-4 sub-heading-text'>
        <div>Comments</div>
        <div className='data-font'>{data?.safetyForm?.comment || 'N/A'}</div>
      </div>
      <div className='d-flex flex-column gap-2 mt-4 sub-heading-text'>
        <div>Attachment</div>
        <div className='data-font'>
          {data?.safetyForm?.attachment?.imageUrl ? (
            <img
              src={
                data?.safetyForm?.attachment.mimeType == 'application/pdf'
                  ? pdfIcon
                  : data?.safetyForm?.attachment?.imageUrl
              }
              alt='vehicle attachment'
              width={'80px'}
              height={'80px'}
            />
          ) : (
            'N/A'
          )}
        </div>
      </div>
      <div className='d-flex flex-column gap-2 mt-4 sub-heading-text'>
        <div>Trailer Attached?</div>
        <div className='data-font'>
          {data?.safetyForm?.is_trailer_attached ? 'Yes' : 'No'}
        </div>
      </div>
      {data?.safetyForm?.is_trailer_attached && (
        <>
          <div className='d-flex flex-row mt-4 gap-4 sub-heading-text'>
            <div className='d-flex flex-row gap-5'>
              <div className='d-flex flex-column gap-2 '>
                <div>Vehicle Image</div>
                <div>
                  <img
                    src={
                      data?.trailerBasicInfo?.vehicle_photos[0]?.imageUrl ||
                      truck
                    }
                    alt='vehicle img'
                    width={'80px'}
                    height={'80px'}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex flex-column gap-2 ps-5'>
              <div>Engine Oil Checked?</div>
              <div className='data-font'>
                {data?.trailer?.is_engine_oil_been_checked ? 'Yes' : 'No'}
              </div>
            </div>
            <div className='d-flex flex-column gap-2 '>
              <div>Brakes Checked?</div>
              <div className='data-font'>
                {data?.trailer?.is_brakes_been_checked ? 'Yes' : 'No'}
              </div>
            </div>
            <div className='d-flex flex-column gap-2 '>
              <div>Lights Checked?</div>
              <div className='data-font'>
                {data?.trailer?.is_lights_been_checked ? 'Yes' : 'No'}
              </div>
            </div>
            <div className='d-flex flex-column gap-2 '>
              <div>Tires Checked?</div>
              <div className='data-font'>
                {data?.trailer?.is_tires_been_checked ? 'Yes' : 'No'}
              </div>
            </div>
            <div className='d-flex flex-column gap-2 '>
              <div>Any Visual Defects?</div>
              <div className='data-font'>
                {data?.trailer?.any_visual_defects ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
          <div className='d-flex flex-column gap-2 mt-4 sub-heading-text'>
            <div>Comments</div>
            <div className='data-font'>{data?.trailer?.comment || 'N/A'}</div>
          </div>
          <div className='d-flex flex-column gap-2 mt-4 sub-heading-text'>
            <div>Attachment</div>
            <div className='data-font'>
              {data?.trailer?.attachment?.imageUrl ? (
                <img
                  src={
                    data?.trailer?.attachment.mimeType == 'application/pdf'
                      ? pdfIcon
                      : data?.trailer?.attachment?.imageUrl
                  }
                  alt='vehicle attachment'
                  width={'80px'}
                  height={'80px'}
                />
              ) : (
                'N/A'
              )}
            </div>
          </div>
        </>
      )}
      <div className='d-flex flex-column gap-2 mt-4 sub-heading-text'>
        <div>Signature</div>
        <div className='data-font'>
          {data?.safetyForm?.signature?.imageUrl ? (
            <img
              src={
                data?.safetyForm?.signature.mimeType == 'application/pdf'
                  ? pdfIcon
                  : data?.safetyForm?.signature?.imageUrl
              }
              alt='signature'
              width={'110px'}
              height={'90px'}
            />
          ) : (
            'N/A'
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='h-100 d-flex flex-column justify-content-between p-4 '>
        <div className='table-wrapper'>
          <div className='d-flex flex-column gap-3'>
            <div className='table-wrapper'>
              <div className='px-4 pt-3'>
                <div className='mb-2 d-flex align-items-center justify-content-between'>
                  <div>
                    <h3>Operator's Daily Logbook</h3>
                    <h6 className='mt-1' style={{ color: '#3b98d1' }}>
                      Date Range (
                      {filters?.start_date ? getRangeDates() : getWeekDates()})
                    </h6>
                  </div>
                  {size?.width < MEDIUM_SCREEN_WIDTH && filterButton()}
                </div>
                <div className='search-bg d-flex align-items-center justify-content-between'>
                  <div className='w-100'>
                    <input
                      type='text'
                      className='search-input'
                      placeholder='Search by Job name'
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  {size?.width >= MEDIUM_SCREEN_WIDTH && filterButton()}
                </div>
              </div>
              <div className='p-3 custom-table'>
                {data.length ? (
                  <>
                    {size?.width >= MEDIUM_SCREEN_WIDTH ? (
                      <DataTable
                        columns={tableHeader}
                        data={data}
                        expandableRows
                        expandableRowsComponent={expandableRowComponent}
                        expandableRowsHideExpander
                        // expandableRowExpanded={(row) =>
                        //   expandedRows.includes(row._id)     //for multiple rows expansion
                        // }
                        // onRowExpandToggled={(bool, row) =>
                        //   toggleRowExpansion(row)
                        // }
                        expandableRowExpanded={(row) =>
                          row._id === expandedRowId
                        }
                        onRowExpandToggled={(bool, row) =>
                          toggleRowExpansion(row)
                        }
                      />
                    ) : (
                      <SafetyformTableMobile
                        toggleRowExpansion={toggleRowExpansion}
                        // expandedRows={expandedRows}
                        expandedRows={expandedRowId}
                        data={data}
                      />
                    )}
                  </>
                ) : (
                  <p className='text-center text-heading-primary'>
                    No Record Exists
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='mt-2'>
          <Pagination
            limit={limit}
            handleLimitChange={(val) => handleLimitChange(val)}
            totalPage={entriesMeta.total_pages}
            currentPage={currentPage}
            totalCount={entriesMeta.total_count}
            handlePageChange={(val) => setCurrentPage(Number(val))}
            handleNextPageClick={() => setCurrentPage(currentPage + 1)}
            handlePreviousPageClick={() => setCurrentPage(currentPage - 1)}
          />
        </div>
      </div>
      <DateRangeFilter
        show={showModal}
        toggle={setShowModal}
        onOkay={handleOkay}
        onClear={clearFilters}
        value={filters}
      />
    </>
  );
}
