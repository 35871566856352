import { Modal } from 'react-bootstrap';
import CORSS from '../../assets/icons/cross.svg';

export default function SuccessModal({
  show,
  toggle,
  title,
  description,
  icon,
  onConfirm,
  onCancel,
}) {
  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="d-flex justify-content-end">
          <img
            src={CORSS}
            alt="cross-icon"
            className="cursor-pointer"
            onClick={toggle}
          />
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-center">
            <img src={icon} alt="not-allowed" />
          </div>
          <div className="modal-inner-wrapper text-center my-4">
            <p className="modal-heading heading-color">{title}</p>
            <span className="text-label">{description}</span>
            <div className="d-flex justify-content-center mt-4 gap-4">
              <button
                className="model-button primary-button-outline text-label-heading primary-text-color"
                onClick={onCancel}
              >
                Go to Dashboard
              </button>
              <button
                className="model-button primary-button text-label-heading color-white"
                onClick={onConfirm}
              >
                Go to Dailylog
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
