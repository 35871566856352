import HistoryDashboard from '../../components/history';

function History({ isTimeTrack }) {
  return (
    <>
      <HistoryDashboard isTimeTrack={isTimeTrack} />
    </>
  );
}

export default History;