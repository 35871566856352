import DASHBOARD from '../assets/icons/dashboard.svg';
import DASHBOARD_DARK from '../assets/icons/dashboard-dark.svg';
import HISTORY from '../assets/icons/history.svg';
import HISTORY_DARK from '../assets/icons/history-dark.svg';
import JOBS_DARK from '../assets/icons/jobs-dark.svg';
import JOBS from '../assets/icons/jobs.svg';
import TIME from '../assets/icons/time.svg';
import TIME_DARK from '../assets/icons/time-dark.svg';
import TEAM from '../assets/icons/teams.svg';
import TEAM_DARK from '../assets/icons/teams-dark.svg';
import FRAME from '../assets/icons/FrameNav.png';
import SAFETYFORM from '../assets/icons/safety-forms.png';
import SAFETYFORM_DARK from '../assets/icons/safety-forms-dark.png';

export const GOOGLE_CLIENT_ID =
  '510534267946-l475ldedp48s8pubnklm674h43a87ltg.apps.googleusercontent.com';
export const FACEBOOK_CLIENT_ID = '1778606105912413';

export const MEDIUM_SCREEN_WIDTH = 768;

export const NAVS = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    icon: DASHBOARD,
    icon_dark: DASHBOARD_DARK,
    icon_alt: '',
    to: '/dashboard',
  },
  {
    title: 'Jobs',
    key: 'job',
    icon_alt: '',
    icon: JOBS,
    icon_dark: JOBS_DARK,
    to: '/jobs',
  },
  {
    title: 'History',
    key: 'history',
    icon_alt: '',
    icon: HISTORY,
    icon_dark: HISTORY_DARK,
    to: '/history',
  },
  {
    title: 'QR Code',
    key: 'QrScan',
    icon_alt: '',
    icon: FRAME,
    icon_dark: FRAME,
    to: '/qrscan',
  },
  {
    title: 'Forms',
    key: 'safetyforms',
    icon_alt: '',
    icon: SAFETYFORM,
    icon_dark: SAFETYFORM_DARK,
    to: '/safety_forms',
  },
];

export const NAV_LEAD_ALLOWED_TRACK = [
  {
    title: 'Dashboard',
    key: 'dashboard_tl',
    icon: DASHBOARD,
    icon_dark: DASHBOARD_DARK,
    icon_alt: '',
    to: '/dashboard',
  },
  {
    title: 'Daily Log',
    key: 'daily_log_tl',
    icon: HISTORY,
    icon_dark: HISTORY_DARK,
    icon_alt: '',
    to: '/daily_logs',
  },
  {
    title: 'Teams',
    key: 'teams',
    icon_alt: '',
    icon: TEAM,
    icon_dark: TEAM_DARK,
    to: '/teams',
  },
  {
    title: 'QR Code',
    key: 'QrScan',
    icon_alt: '',
    icon: FRAME,
    icon_dark: FRAME,
    to: '/qrscan',
  },
  {
    title: 'Forms',
    key: 'safetyforms',
    icon_alt: '',
    icon: SAFETYFORM,
    icon_dark: SAFETYFORM_DARK,
    to: '/safety_forms',
  },
];

export const LEAD_NAVS = [
  {
    title: 'Daily Log',
    key: 'daily_log_tl',
    icon: HISTORY,
    icon_dark: HISTORY_DARK,
    icon_alt: '',
    to: '/daily_logs',
  },
  {
    title: 'Time Track',
    key: 'time_track',
    icon_alt: '',
    icon: TIME,
    icon_dark: TIME_DARK,
    to: '/time_track',
  },
  {
    title: 'Teams',
    key: 'teams',
    icon_alt: '',
    icon: TEAM,
    icon_dark: TEAM_DARK,
    to: '/teams',
  },
  {
    title: 'QR Code',
    key: 'QrScan',
    icon_alt: '',
    icon: FRAME,
    icon_dark: FRAME,
    to: '/qrscan',
  },
  {
    title: 'Forms',
    key: 'safetyforms',
    icon_alt: '',
    icon: SAFETYFORM,
    icon_dark: SAFETYFORM_DARK,
    to: '/safety_forms',
  },
];

export const ROLES = {
  team_lead: 'TEAM_LEAD',
  worker: 'WORKER',
};

export const TIME_VAR = {
  hour: 'HOUR',
  minute: 'MINUTE',
};

export const CALENDER_FORMAT = {
  sameDay: '[Today] MMM, YYYY',
  nextDay: 'DD/MM/YYYY',
  nextWeek: 'DD/MM/YYYY',
  lastDay: '[Yesterday] MMM, YYYY',
  lastWeek: 'DD/MM/YYYY',
  sameElse: 'DD/MM/YYYY',
};

export const UPDATE_ENTRY = {
  description: 'DESCRIPTION',
  colckIn: 'CLOCK_IN',
  clockout: 'CLOCK_OUT',
  job: 'JOB',
};

export const SCREEN = {
  mobile: 'MOBILE',
  desktop: 'DESKTOP',
};

export const IMAGES_ASSESTS_PATH = '/assets/images';

export const defaultPageMeta = {
  current_page: 1,
  total_pages: 1,
  total_count: 0,
  per_page: 10,
};

export const allowedFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
];
