import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { getTimeAndAttendance } from '../../network/team';
import './Teams.css';
import DataTable from 'react-data-table-component';
import DatePickerValue from '../../components/common/DatePicker';
import dayjs from 'dayjs';
import profileImg from '../../assets/images/user-profile.png';
import useWindowSize from '../../hooks/useWindowSize';
import { MEDIUM_SCREEN_WIDTH } from '../../constants';
import Pagination from '../../components/pagination';
import _debounce from 'lodash/debounce';
import TeamMobile from '../../components/teams/TeamSmall';
import { toast } from 'react-toastify';

function Teams({ isTimeTrack }) {
  const size = useWindowSize();

  const [jobs, setJobs] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [startDate, setStartDate] = useState(dayjs());
  const [stat, setStat] = useState({});
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const overtimeMinutesArray = [];
  const [entriesMeta, setEntriesMeta] = useState();
  const [search, setSearch] = useState('');
  const [filters, setFilter] = useState({
    search: '',
  });
  const [expandedRows, setExpandedRows] = useState([]);

  const getWeekDates = (startDate) => {
    const weekDates = {};
    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    });
    let currentDate = moment(startDate).startOf('week');

    for (let i = 0; i < 7; i++) {
      weekDates[currentDate.format('dddd')] = currentDate.format('YYYY-MM-DD');
      currentDate.add(1, 'days');
    }

    return weekDates;
  };

  const [weekDates, setWeekDates] = useState(getWeekDates(startDate));

  const isNoTimeAgainstUser = (jobs, weeklyHourLimit) => {
    const weeklyTotalMinutes = jobs.reduce(
      (total, job) => total + job.weekTotalHours,
      0
    );
    let hours = parseInt(weeklyTotalMinutes) / 60;
    let weeklyMinutes = 0;
    if (hours <= weeklyHourLimit) {
      weeklyMinutes = hours * 60;
    } else {
      weeklyMinutes = weeklyHourLimit * 60;
    }
    if (!weeklyMinutes) {
      return true;
    }
    return false;
  };

  const fetchJobs = useCallback(async () => {
    try {
      setSpinner(true);
      moment.updateLocale('en', {
        week: {
          dow: 1,
        },
      });
      const currentWeekStart = moment(startDate).startOf('week').toDate();
      const currentWeekEnd = moment(startDate)
        .endOf('week')
        .endOf('day')
        .toDate();
      let data = await getTimeAndAttendance({
        pageSize: limit,
        pageNo: currentPage,
        currentWeekStart,
        currentWeekEnd,
        ...filters,
      });
      if (!data?.data) {
        toast.error(data?.response?.data?.error || 'Something went wrong!');
      }
      data = data?.data;
      setJobs(
        data?.result?.filter(
          (job) =>
            !isNoTimeAgainstUser(job?.jobs, job?.paymentRule?.weekly_hour_limit)
        ) || []
      );
      setStat(data?.statistics);
      setEntriesMeta(data.meta);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
    }
  }, [limit, currentPage, startDate, filters]);

  useEffect(() => {
    setWeekDates(getWeekDates(startDate));
    fetchJobs();
  }, [fetchJobs, startDate]);

  const nameCell = (img, name, role) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={img || profileImg}
          alt="profile"
          className="user-profile-image-teams"
        />
        <div className="name-wrapper">
          {name}/{role}
        </div>
      </div>
    );
  };

  const jobsCell = (jobs) => {
    return jobs
      .filter((job) => job?.weekTotalHours !== 0)
      .map((job) => (
        <div key={job?._id} className="job-wrapper">
          {job?.name}
        </div>
      ));
  };

  const minutesToHours = (minutes) => {
    if (!minutes) {
      return '0h 0m';
    }
    const minute = Math.floor(minutes % 60);
    let hours = Math.floor(minutes / 60);
    hours = Math.floor(hours);
    return `${hours}h ${minute}m`;
  };

  const dayCell = (jobs, day) => {
    return jobs
      .filter((job) => job?.weekTotalHours !== 0)
      .map((job) => {
        return (
          <div key={job?._id} className="day-hours">
            {minutesToHours(job?.totalWorkedHours[`${day}`]?.totalMinutes)}
          </div>
        );
      });
  };

  const weekTotalCell = (jobs, weeklyHourLimit) => {
    const weeklyTotalMinutes = jobs.reduce(
      (total, job) => total + job.weekTotalHours,
      0
    );
    if (!weeklyTotalMinutes) {
      return '0h 0m';
    }

    return (
      <div className="weekly-hours">{minutesToHours(weeklyTotalMinutes)}</div>
    );
  };

  const overtimeHoursCell = (jobs, weeklyHourLimit) => {
    const weeklyTotalMinutes = jobs.reduce(
      (total, job) => total + job.weekTotalHours,
      0
    );
    const assignedMinutes = weeklyHourLimit * 60;
    const overtimeMinutes =
      assignedMinutes < weeklyTotalMinutes
        ? Math.abs(assignedMinutes - weeklyTotalMinutes)
        : 0;

    overtimeMinutesArray.push(overtimeMinutes);
    return (
      <div className="weekly-hours">{minutesToHours(overtimeMinutes)}</div>
    );
  };

  const dayTotal = (day) => {
    return (
      <div className="weekly-hours specific-table">
        {minutesToHours(stat[`${day}`])}
      </div>
    );
  };

  const totalWeekly = () => {
    return (
      <div className="weekly-hours">
        {/* <div className="break-word">TOTAL WEEKLY HOURS</div> */}
        <div className="weekly-hours specific-table">
          {minutesToHours(stat?.weekTotal?.weekTotal)}
        </div>
      </div>
    );
  };

  const totalOvertime = () => {
    return (
      <div>
        {/* <div className="break-word fw-bold">TOTAL OVERTIME HOURS</div> */}
        <div className="weekly-hours specific-table">
          {minutesToHours(stat?.weekTotal?.overtime)}
        </div>
      </div>
    );
  };

  const dayHeader = (day) => {
    return (
      <div className="day-header">
        <div>{day}</div>
        <div>
          {moment(
            jobs?.[0]?.jobs?.[0]?.totalWorkedHours[`${day}`].date ||
            weekDates[`${day}`]
          ).format('MMMM D, YYYY')}
        </div>
      </div>
    );
  };

  const customHeader = (text) => {
    return <div className="custom-header">{text}</div>;
  };

  const customeRow = (text) => {
    return <div className="custom-row">{text}</div>;
  };

  const tableHeader = [
    {
      name: 'Name',
      selector: (job) =>
        job.finalRow
          ? job.name
          : nameCell(job?.user?.image, job?.user?.name, job?.user?.role),
    },
    {
      name: 'Job',
      selector: (job) => (job.finalRow ? '' : jobsCell(job?.jobs)),
    },
    {
      name: dayHeader('Monday'),
      selector: (job) =>
        job.finalRow ? job.monday : dayCell(job?.jobs, 'Monday'),
    },
    {
      name: dayHeader('Tuesday'),
      selector: (job) =>
        job.finalRow ? job.tuesday : dayCell(job?.jobs, 'Tuesday'),
    },
    {
      name: dayHeader('Wednesday'),
      selector: (job) =>
        job.finalRow ? job.wednesday : dayCell(job?.jobs, 'Wednesday'),
    },
    {
      name: dayHeader('Thursday'),
      selector: (job) =>
        job.finalRow ? job.thursday : dayCell(job?.jobs, 'Thursday'),
    },
    {
      name: dayHeader('Friday'),
      selector: (job) =>
        job.finalRow ? job.friday : dayCell(job?.jobs, 'Friday'),
    },
    {
      name: dayHeader('Saturday'),
      selector: (job) =>
        job.finalRow ? job.saturday : dayCell(job?.jobs, 'Saturday'),
    },
    {
      name: dayHeader('Sunday'),
      selector: (job) =>
        job.finalRow ? job.sunday : dayCell(job?.jobs, 'Sunday'),
    },
    {
      name: customHeader('WORKER TOTAL HOURS'),
      selector: (job) =>
        job.finalRow
          ? job.weekTotal
          : weekTotalCell(job?.jobs, job?.paymentRule?.weekly_hour_limit),
    },
    {
      name: 'OVERTIME HOURS',
      selector: (job) =>
        job.finalRow
          ? job.overtime
          : overtimeHoursCell(job?.jobs, job?.paymentRule?.weekly_hour_limit),
    },
  ];

  const filterButton = () => (
    <DatePickerValue
      initialValue={dayjs(startDate)}
      handleChange={(value) => setStartDate(value)}
      customize
    />
  );

  const handleLimitChange = (val) => {
    setCurrentPage(1);
    setLimit(val);
  };

  const handleDebounce = (searchVal) => {
    setFilter((prev) => ({ ...prev, search: searchVal }));
  };

  const debounceFn = useCallback(_debounce(handleDebounce, 500), []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  const toggleRowExpansion = (row) => {
    if (expandedRows.includes(row._id)) {
      setExpandedRows(expandedRows.filter((id) => id !== row?._id));
    } else {
      setExpandedRows([...expandedRows, row?._id]);
    }
  };

  const getRow = (title, data) => (
    <div className="d-flex justify-content-between align-items-center">
      <span className="text-label-primary">{title}</span>
      <span className="text-label-heading">{data}</span>
    </div>
  );

  return (
    <>
      {spinner && <div className="loader"></div>}
      <div
        className={`${isTimeTrack
          ? 'teams-main-container'
          : 'teams-main-container-without-bottom-time-track'
          } d-flex flex-column justify-content-between ${size?.width < MEDIUM_SCREEN_WIDTH && 'mobile-teams'
          }`}
      >
        <div>
          <div className="d-flex flex-column gap-3">
            <div className="table-wrapper">
              <div className="px-4 pt-3">
                <div className="mb-2">
                  <h4>Teams</h4>
                </div>
                {size?.width < MEDIUM_SCREEN_WIDTH && filterButton()}
                <div className="mt-2 search-bg d-flex align-items-center justify-content-between">
                  <div className="w-100">
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search by Job ID or name..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="w-25">
                    {size?.width >= MEDIUM_SCREEN_WIDTH && filterButton()}
                  </div>
                </div>
              </div>
              <div className="p-3 custom-table">
                {size?.width >= MEDIUM_SCREEN_WIDTH ? (
                  <DataTable
                    columns={tableHeader}
                    data={[
                      ...jobs,
                      ...(jobs?.length
                        ? [
                          {
                            name: customeRow('TOTAL DAILY HOURS'),
                            monday: dayTotal('monday'),
                            tuesday: dayTotal('tuesday'),
                            wednesday: dayTotal('wednesday'),
                            thursday: dayTotal('thursday'),
                            friday: dayTotal('friday'),
                            saturday: dayTotal('saturday'),
                            sunday: dayTotal('sunday'),
                            weekTotal: totalWeekly(),
                            overtime: totalOvertime(),
                            finalRow: true,
                          },
                        ]
                        : []),
                    ]}
                  />
                ) : (
                  <TeamMobile
                    jobs={jobs}
                    toggleRowExpansion={toggleRowExpansion}
                    expandedRows={expandedRows}
                    nameCell={nameCell}
                    jobsCell={jobsCell}
                    dayCell={dayCell}
                    weekTotalCell={weekTotalCell}
                    getRow={getRow}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {entriesMeta && (
          <Pagination
            limit={limit}
            handleLimitChange={(val) => handleLimitChange(val)}
            totalPage={entriesMeta.total_pages}
            currentPage={currentPage}
            totalCount={entriesMeta.total_count}
            handlePageChange={(val) => setCurrentPage(Number(val))}
            handleNextPageClick={() => setCurrentPage(currentPage + 1)}
            handlePreviousPageClick={() => setCurrentPage(currentPage - 1)}
          />
        )}
      </div>
      {size?.width < MEDIUM_SCREEN_WIDTH && (
        <div className="mobile-grand-total p-3">
          <div className="d-none">
            {getRow('Total Moday Hours', dayTotal('monday'))}
            {getRow('Total Tuesday Hours', dayTotal('tuesday'))}
            {getRow('Total Wednesday Hours', dayTotal('wednesday'))}
            {getRow('Total Thursday Hours', dayTotal('thursday'))}
            {getRow('Total Friday Hours', dayTotal('friday'))}
            {getRow('Total Saturday Hours', dayTotal('saturday'))}
            {getRow('Total Sunday Hours', dayTotal('sunday'))}
            <hr className="my-2" />
          </div>
          {getRow(
            'Total Weekly Hours',
            minutesToHours(stat?.weekTotal?.weekTotal)
          )}
        </div>
      )}
    </>
  );
}

export default Teams;
