import "../../assets/styles/dashboardStyles.css";
import { useCallback, useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import DashboardHistory from "../../components/DashboardHistory";
import { toast } from "react-toastify";
import moment from "moment";
import _debounce from 'lodash/debounce';

import DatePickerValue from '../common/DatePicker';
import dayjs from 'dayjs';

import { getMonthHistory } from "../../network/timeTrack";
import { MEDIUM_SCREEN_WIDTH } from "../../constants";

export default function HistoryDashboard({ isTimeTrack }) {
    const size = useWindowSize();

    const [monthAndYear, setMonthAndYear] = useState({ month: moment().format('MMM'), year: moment().format('YYYY') });

    const [loader, setLoader] = useState(false);
    const [monthlyEntries, setMonthlyEntries] = useState([]);
    const [durationMap, setDurationMap] = useState([]);
    const [monthlyEntriesMeta, setMonthlyEntriesMeta] = useState({});
    const [totalSecondsPerMonth, setTotalSecondsPerMonth] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [search, setSearch] = useState('');
    const [monthlyPayoutAmount, setMonthlyPayoutAmount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalWeeklyPayoutArr, setTotalWeeklyPayoutArr] = useState([]);
    const [totalWeeklyDueArr, setTotalWeeklyDueArr] = useState([]);
    const [monthlyDueAmount, setMonthlyDueAmount] = useState(0);

    useEffect(() => {
        getHistory(page, limit, search, monthAndYear);
    }, [search, monthAndYear]);


    const handleDebounce = (searchVal) => {
        setSearch(searchVal);
    };

    const debounceFn = useCallback(_debounce(handleDebounce, 1000), []);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        debounceFn(e.target.value);
    };

    const getHistory = async (page = 1, limit = 10, search = "", monthAndYear = { month: moment().format('MMM'), year: moment().format('YYYY') }) => {
        setLoader(true);
        let res = await getMonthHistory(page, limit, { ...monthAndYear, search: search });
        if (res?.data?.message === "Success") {
            setMonthlyEntries(res.data?.timeEntries?.monthlyEntries);
            setDurationMap(res.data.timeEntries?.durationMap);
            setMonthlyEntriesMeta(res.data.timeEntries?.meta);
            setPage(res.data.timeEntries?.meta?.page);
            setLimit(res.data.timeEntries?.meta?.limit);
            setTotalSecondsPerMonth(res.data.timeEntries?.totalSecondsPerMonth);
            setStartDate(res.data.timeEntries?.startDate);
            setEndDate(res.data.timeEntries?.endDate);
            setMonthlyPayoutAmount(res.data.timeEntries?.total_payout_amount_of_month);
            setTotalWeeklyPayoutArr(res.data.timeEntries?.weeklyPayout);
            setTotalWeeklyDueArr(res.data.timeEntries?.weeklyDue);
            setMonthlyDueAmount(res.data.timeEntries.monthlyDueAmount);
        } else {
            toast.error('Error in fetching time entries.');
        }
        setLoader(false);
    };

    return (
        <>
            {loader && <div className="loader"></div>}

            <div className={`${loader ? "opacity-50" : ""} ${isTimeTrack ? "app-container-with-time-track-at-bottom" : "app-container"}`}>
                <div className="h-100 d-flex flex-column justify-content-between">
                    <div>
                        <div className="d-flex flex-column mb-2">

                            <div className="mb-2 history-heading d-flex align-items-end justify-content-between">
                                <h4>History</h4>
                                {size?.width < MEDIUM_SCREEN_WIDTH && <DatePickerValue
                                    initialValue={dayjs(new Date(`${monthAndYear.year}-${monthAndYear.month}-01`))}
                                    handleChange={(value) => {
                                        setMonthAndYear({ month: moment(value).format('MMM'), year: moment(value).format('YYYY') })
                                    }}
                                    minDate={null}
                                    disabled={null}
                                    isHistoryTab={true}
                                />
                                }
                            </div>
                            <div className="search-bg clocker-wrapper d-flex align-items-center justify-content-between">
                                <div className="w-100">
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder="Search by Job ID or name..."
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {size?.width > MEDIUM_SCREEN_WIDTH &&
                                    <DatePickerValue
                                        initialValue={dayjs(new Date(`${monthAndYear.year}-${monthAndYear.month}-01`))}
                                        handleChange={(value) => {
                                            setMonthAndYear({ month: moment(value).format('MMM'), year: moment(value).format('YYYY') });
                                            setPage(1);
                                            setSearch('');
                                        }}
                                        minDate={null}
                                        disabled={null}
                                        isHistoryTab={true}
                                    />
                                }
                            </div>
                        </div>
                        <DashboardHistory
                            monthlyEntries={monthlyEntries}
                            totalSecondsPerMonth={totalSecondsPerMonth}
                            durationMap={durationMap}
                            monthlyEntriesMeta={monthlyEntriesMeta}
                            getHistory={getHistory}
                            startDate={startDate}
                            endDate={endDate}
                            monthlyPayoutAmount={monthlyPayoutAmount}
                            search={search}
                            monthAndYear={monthAndYear}
                            page={page}
                            limit={limit}
                            totalWeeklyPayoutArr={totalWeeklyPayoutArr}
                            totalWeeklyDueArr={totalWeeklyDueArr}
                            monthlyDueAmount={monthlyDueAmount}
                        />
                    </div>
                    {/* PAGINTION */}
                    {
                        monthlyEntries?.length ? (
                            <div className="pagination-history d-flex justify-content-between">
                                <div className="d-flex gap-2 align-items-center">
                                    <span className="text-label-secondary d-none d-md-block">
                                        Show
                                    </span>
                                    <div>
                                        <select
                                            className="page-couter text-label-secondary"
                                            onChange={(e) =>
                                                getHistory(
                                                    monthlyEntriesMeta?.page,
                                                    e.target.value,
                                                    search,
                                                    monthAndYear
                                                )
                                            }
                                        >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                    <span className="text-label-secondary d-none d-md-block">
                                        entries of {monthlyEntriesMeta?.total}
                                    </span>
                                </div>
                                <div className="d-flex gap-1">
                                    <div
                                        className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
                                        onClick={(e) => {
                                            if (monthlyEntriesMeta?.page > 1) {
                                                getHistory(
                                                    monthlyEntriesMeta?.page - 1,
                                                    monthlyEntriesMeta?.limit,
                                                    search,
                                                    monthAndYear
                                                );
                                            }
                                        }}
                                    >
                                        {'<'}
                                    </div>
                                    {new Array(
                                        Math.ceil(
                                            monthlyEntriesMeta?.total / monthlyEntriesMeta?.limit
                                        )
                                    )
                                        .fill(0)
                                        .map((val, index) => (
                                            <div
                                                className={`pag-item-wrapper normal-text-primary color-label cursor-pointer ${monthlyEntriesMeta?.page === index + 1 ? 'sl-page' : ''
                                                    }`}
                                                onClick={(e) =>
                                                    getHistory(
                                                        index + 1,
                                                        monthlyEntriesMeta?.limit,
                                                        search,
                                                        monthAndYear
                                                    )
                                                }
                                            >
                                                {index + 1}
                                            </div>
                                        ))}
                                    <div
                                        className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
                                        onClick={(e) => {
                                            if (
                                                monthlyEntriesMeta?.page !==
                                                new Array(
                                                    Math.ceil(
                                                        monthlyEntriesMeta?.total / monthlyEntriesMeta?.limit
                                                    )
                                                ).fill(0).length
                                            )
                                                getHistory(
                                                    monthlyEntriesMeta?.page + 1,
                                                    monthlyEntriesMeta?.limit,
                                                    search,
                                                    monthAndYear
                                                );
                                        }}
                                    >
                                        {'>'}
                                    </div>
                                </div>
                            </div>
                        ) :
                            null
                    }
                </div>
            </div>
        </>
    );
}
