import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-scanner';
import qrFrame from '../../assets/icons/qrFrame.png';
import ResultModal from './components/ResultModal';
import './QrScanner.css';

function QrScanner(isTrailer, updateTrailerVehicle, updateOpenQr) {
  const [delay] = useState(100);
  const [result, setResult] = useState('no result');
  const [scanning, setScanning] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [cameraPermission, setCameraPermission] = useState(false);
  const [isTrailerTypeMisMatch, setisTrailerTypeMisMatch] = useState();

  const checkIsValidJSON = (data) => {
    try {
      JSON.parse(data.text);
      return true;
    } catch {
      return false;
    }
  };

  // const checkCameraPermission = async () => {
  //   try {
  //     await navigator.mediaDevices.getUserMedia({ video: true });
  //     setCameraPermission(true);
  //   } catch (error) {
  //     setCameraPermission(false);
  //   }
  // };

  // useEffect(() => {
  //   checkCameraPermission();
  // }, []);

  const handleScan = (data) => {
    if (!isTrailer?.isTrailer && data && checkIsValidJSON(data)) {
      setResult(data);
      setShowModal(true);
      setScanning(false);
    } else if (isTrailer && data && checkIsValidJSON(data)) {
      const jsonData = JSON.parse(data.text);
      setResult(data);
      console.log(jsonData);
      if (jsonData?.vehicle_type?.toLowerCase() == 'trailer') {
        setShowModal(true);
        setScanning(false);
        setisTrailerTypeMisMatch(false);
      } else {
        setisTrailerTypeMisMatch(true);
        setShowModal(true);
        setScanning(false);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleScanAgain = () => {
    setShowModal(false);
    setResult('no result');
    setScanning(true);
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const cameraStyle = {
    width: '100%',
    height: '100%',
  };

  const frameStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    maxHeight: '80%',
  };

  const constraints = {
    video: { facingMode: 'environment' },
  };

  return (
    <>
      {/* {!cameraPermission ? (
        <div className='overlay'>
          <p>Please grant access to your camera.</p>
        </div>
      ) : ( */}
      <>
        <div style={containerStyle}>
          {scanning && (
            <div
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <QrReader
                delay={delay}
                style={cameraStyle}
                onError={handleError}
                onScan={handleScan}
                constraints={constraints}
              />
              <img
                src={qrFrame}
                className='frame'
                style={frameStyle}
                alt='QR Frame'
              />
            </div>
          )}
          {showModal && result && (
            <ResultModal
              data={result}
              scanAgain={handleScanAgain}
              isTrailer={isTrailer}
              updateTrailerVehicle={updateTrailerVehicle}
              updateOpenQr={updateOpenQr}
              isTrailerTypeMisMatch={isTrailerTypeMisMatch}
            />
          )}
        </div>
        {!showModal && (
          <div className='d-flex flex-column justify-content-center align-items-center mt-5 gap-3'>
            <div style={{ fontSize: '20px' }}>Scan QR Code</div>
            <div>Place this scan box over the QR Code</div>
          </div>
        )}
      </>
      {/* )} */}
    </>
  );
}

export default QrScanner;
