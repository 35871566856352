import '../../assets/styles/dashboardStyles.css';
import { useEffect, useState } from 'react';
import { getJobsListing } from '../../network/jobs';
import { toast } from 'react-toastify';
import JobTable from '../../components/job/JobTable';
import './DashboardNotAllowd.css';
import DashboardTable from '../../components/dashboardNotAllowed/DashboardTable';
import { getTimeEntriesByWeekDays } from '../../network/timeTrack';
import { minutesToHours } from '../../services/utils';
import DashboardComp from '../../components/DashboardComp';

function DashboardNotAllowed() {
  const [jobs, setJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [entriesMeta, setEntriesMeta] = useState();
  const [filters, setFilter] = useState({
    start_date: '',
    end_date: '',
    search: '',
  });
  const [paymentRule, setPaymentRule] = useState();

  useEffect(() => {
    handleUseEffectCalls();
  }, [filters]);

  const handleUseEffectCalls = async () => {
    setLoader(true);
    await getJobs();
    setLoader(false);
  };

  const handleEntryUpdate = () => {};

  const getJobs = async (page = 1, size = 10) => {
    let res = await getTimeEntriesByWeekDays(page, size);
    if (res?.data?.timeEntries) {
      setJobs(res?.data?.timeEntries?.entries);
      setPaymentRule(res?.data?.timeEntries?.paymentRule);
      setEntriesMeta(res?.data?.timeEntries?.meta);
    } else {
      toast.error('Error in fetching jobs.');
    }
  };

  const sumOfDayTotal = () => {
    let total = 0;

    for (const jobEntry of jobs) {
      total += jobEntry?.dayTotal || 0;
    }

    const overtime =
      total > paymentRule?.weekly_hour_limit * 60
        ? Math.abs(total - paymentRule?.weekly_hour_limit * 60)
        : 0;
    return { weeklyTotal: total, overtime };
  };

  return (
    <>
      {loader && <div className="loader"></div>}

      <div className={`${loader ? 'opacity-50' : ''} app-container`}>
        <div className="top-section">
          <div className="left">This Week</div>
          <div className="right">
            {/* Hidden by client */}
            {/* <div className="stat">
              <div className="title-text">Weekly Payout:</div>
              <div className="title-value">
                $
                {Math.ceil(
                  (sumOfDayTotal().weeklyTotal / 60) * paymentRule?.hourly_rate
                )}
              </div>
            </div> */}
            {/* <div className="stat">
              <div className="title-text">Due Amount:</div>
              <div className="title-value">$0</div>
            </div>{' '} */}
            {/* <div className="stat">
              <div className="title-text">Overtime Due Amount:</div>
              <div className="title-value">
                $
                {Math.ceil(
                  (sumOfDayTotal().overtime / 60) * paymentRule?.overtime_rate
                )}
              </div>
            </div>{' '} */}
            <div className="stat">
              <div className="title-text">Overtime:</div>
              <div className="title-value">
                {minutesToHours(sumOfDayTotal().overtime)}
              </div>
            </div>{' '}
            <div className="stat">
              <div className="title-text">Weekly Total</div>
              <div className="title-value">
                {minutesToHours(sumOfDayTotal().weeklyTotal)}
              </div>
            </div>
          </div>
        </div>
        {entriesMeta && (
          <DashboardTable
            timeEntries={entriesMeta}
            getJobs={getJobs}
            jobs={jobs}
            handleEntryUpdate={handleEntryUpdate}
          />
        )}
      </div>
    </>
  );
}

export default DashboardNotAllowed;
