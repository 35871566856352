import TimeField from '../cutomTimeField/TimeInput';
import EXPAND from '../../assets/icons/expand-white-bg.svg';
import EXPANDED from '../../assets/icons/expanded-white-bg.svg';
import { SCREEN } from '../../constants';
import { toHoursAndMinutes } from '../../services/utils';
import moment from 'moment';
import DELETE from '../../assets/icons/delete.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function DashboardSmall({
  entries,
  expandedRows,
  toggleRowExpansion,
  date,
  handleDeleteIconClick,
  updateEntry,
  handleAddUserClick,
}) {
  return (
    <div>
      <div className=" d-flex flex-column gap-3">
        {entries?.length ? (
          entries?.map((entry) => {
            return (
              <div className="time-entry-wrapper">
                <div className="time-entry-header-wrapper align-items-center d-flex justify-content-between">
                  <span className="text-label-primary color-standard">{`${entry?._id?.jobId} (${entry?._id?.name})`}</span>
                  <span>
                    {expandedRows?.includes(
                      `${entry?._id?.date}-${entry?._id?.id}`
                    ) ? (
                      <img
                        src={EXPANDED}
                        alt="expand"
                        onClick={() => toggleRowExpansion(entry)}
                      />
                    ) : (
                      <img
                        src={EXPAND}
                        alt="expand"
                        onClick={() => toggleRowExpansion(entry)}
                      />
                    )}
                  </span>
                </div>
                {expandedRows?.includes(
                  `${entry?._id?.date}-${entry?._id?.id}`
                ) && (
                  <div className="time-entry-div">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-label-primary">Job Name</span>
                      <span className="primary-text-color text-label-heading text-decoration-none">
                        {'• '} <u>{` ${entry?._id?.name}`}</u>
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-label-primary">Total Time</span>
                      <span className="text-label-heading">
                        {toHoursAndMinutes(
                          entry?.data?.reduce((total, user) => {
                            let userTotal = user.entry.reduce(
                              (userTime, entry) => {
                                let durationInSeconds = moment(
                                  entry.end_time
                                ).diff(moment(entry.start_time), 'seconds');
                                return userTime + durationInSeconds;
                              },
                              0
                            );
                            return total + userTotal;
                          }, 0),
                          SCREEN.mobile
                        )}
                      </span>
                    </div>
                    {date === moment().format('YYYY-MM-DD') ? (
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-label-primary">Action</span>
                        <span
                          className="primary-text-color text-label-heading text-decoration-none"
                          onClick={() => handleAddUserClick(entry?._id?.id)}
                        >
                          <u>Add User</u>
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                    {entry?.data?.length
                      ? entry?.data?.map((dt) => (
                          <div className="user-detail-wrapper">
                            <div className="d-flex justify-content-between w-100">
                              <span>Union ID</span>
                              <span>{dt?.user?.union_id}</span>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                              <span>Worker</span>
                              <span>{dt?.user?.name}</span>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                              <span>Time</span>
                              <span>
                                {date === moment().format('YYYY-MM-DD') ? (
                                  <>
                                    {dt.is_track_by_lead ? (
                                      <TimeField
                                        className="time-input-mobile normal-text"
                                        value={toHoursAndMinutes(
                                          dt.entry.reduce((userTime, entry) => {
                                            let durationInSeconds = moment(
                                              entry.end_time
                                            ).diff(
                                              moment(entry.start_time),
                                              'seconds'
                                            );
                                            return userTime + durationInSeconds;
                                          }, 0),
                                          SCREEN.mobile
                                        )}
                                        onBlur={(e) =>
                                          updateEntry({
                                            users: [
                                              {
                                                _id: dt?.user?._id,
                                                time: e.target.value,
                                                tt_id: dt?.timeEntryId,
                                              },
                                            ],
                                            jobId: entry?._id?.id,
                                          })
                                        }
                                      />
                                    ) : (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            Not allowed
                                          </Tooltip>
                                        }
                                      >
                                        <TimeField
                                          className="time-input-mobile normal-text"
                                          value={toHoursAndMinutes(
                                            dt.entry.reduce(
                                              (userTime, entry) => {
                                                let durationInSeconds = moment(
                                                  entry.end_time
                                                ).diff(
                                                  moment(entry.start_time),
                                                  'seconds'
                                                );
                                                return (
                                                  userTime + durationInSeconds
                                                );
                                              },
                                              0
                                            ),
                                            SCREEN.mobile
                                          )}
                                          disabled
                                          onBlur={(e) =>
                                            updateEntry({
                                              users: [
                                                {
                                                  _id: dt?.user?._id,
                                                  time: e.target.value,
                                                  tt_id: dt?.timeEntryId,
                                                },
                                              ],
                                              jobId: entry?._id?.id,
                                            })
                                          }
                                        />
                                      </OverlayTrigger>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {toHoursAndMinutes(
                                      dt.entry.reduce((userTime, entry) => {
                                        let durationInSeconds = moment(
                                          entry.end_time
                                        ).diff(
                                          moment(entry.start_time),
                                          'seconds'
                                        );
                                        return userTime + durationInSeconds;
                                      }, 0),
                                      SCREEN.mobile
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                            {date === moment().format('YYYY-MM-DD') ? (
                              <div className="d-flex justify-content-between w-100">
                                <span>Action</span>
                                <span>
                                  {dt.is_track_by_lead ? (
                                    <img
                                      className="cursor-pointer"
                                      alt="delete-icon"
                                      src={DELETE}
                                      onClick={() =>
                                        handleDeleteIconClick(dt?.timeEntryId)
                                      }
                                    />
                                  ) : (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Not allowed
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        className="opacity-50"
                                        alt="delete-icon"
                                        src={DELETE}
                                      />
                                    </OverlayTrigger>
                                  )}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ))
                      : ''}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p className="text-center">No record found</p>
        )}
      </div>
    </div>
  );
}
