import { useEffect, useState } from 'react';
import { CALENDER_FORMAT, MEDIUM_SCREEN_WIDTH, SCREEN } from '../constants';
import useWindowSize from '../hooks/useWindowSize';
import DashboardLarge from './dashboard/Large';
import DashboardSmall from './dashboard/Small';
import Confirmation from './modals/Confirmation';

import DELETE_FILL from '../assets/icons/delete-fill.svg';

import { deleteTimeEntry, deleteSubTimeEntry } from '../network/timeTrack';

import { toast } from 'react-toastify';
import { toHoursAndMinutes } from '../services/utils';
import moment from 'moment';

export default function DashboardComp({
  isTimeTrack,
  timeEntries,
  handleEntryUpdate,
  setTimeEntries,
  weeklyHours,
  durationMap,
  entriesMeta,
  getWeekTimeEntries,
  jobs,
  teamLeaders
}) {
  const size = useWindowSize();
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [deleteEntryId, setDeleteEntryId] = useState({});
  const [loader, setLoader] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => { }, [entriesMeta]);

  const toggleRowExpansion = (row) => {
    if (expandedRows.includes(row._id)) {
      setExpandedRows(expandedRows.filter((id) => id !== row?._id));
    } else {
      setExpandedRows([...expandedRows, row?._id]);
    }
  };

  const handleDeleteEntryToggle = (id) => {
    setDeleteEntryId(id);
    toggleDeleteModal(true);
  };

  const delEntry = async () => {
    setLoader(true);
    let res;
    if (!deleteEntryId?.is_subentry) {
      res = await deleteTimeEntry(deleteEntryId?.entry_id);
      if (!res?.data) {
        toast.error('Something went wrong.');
      } else {
        getWeekTimeEntries();
        toast.success('Time entry deleted successfully');
      }
    } else {
      res = await deleteSubTimeEntry(
        deleteEntryId?.entry_id,
        deleteEntryId?.subentry_id
      );
      if (res?.data?.time_entry) {
        getWeekTimeEntries();
        if (res?.data?.time_entry?.time_intervals?.length === 1) {
          setExpandedRows((prevExpan) =>
            prevExpan.filter((id) => id !== res?.data?.time_entry?._id)
          );
        }
        toast.success('Time entry deleted successfully');
      } else {
        toast.error('Something went wrong.');
      }
    }

    setDeleteEntryId('');
    toggleDeleteModal(false);
    setLoader(false);
  };
  return (
    <>
      <div
        className={`d-flex flex-column justify-content-between ${isTimeTrack ? 'h-100-330' : 'h-100'
          } ${loader ? 'opacity-50' : ''}`}
      >
        {loader && <div className="loader"></div>}
        {timeEntries?.length ? (
          <>
            <div>
              <div className="mb-3 d-flex justify-content-between">
                <div className="text-heading-primary">This Week</div>
                <div className="d-flex align-items-end gap-1">
                  <div className="text-label-secondary color-label">
                    Weekly Total:
                  </div>
                  <div className="text-heading-secondary">
                    {toHoursAndMinutes(Math.floor(weeklyHours), SCREEN.desktop)}
                  </div>
                </div>
              </div>
              <div className="tt-height d-flex flex-column gap-3">
                {durationMap?.length ? (
                  durationMap?.map((duration) => (
                    <>
                      {timeEntries?.filter(
                        (entry) =>
                          moment(entry?.createdAt).format('YYYY-MM-DD') ===
                          duration?.date
                      )?.length ? (
                        <div className="table-wrapper">
                          <div className="table-header">
                            <div className="text-heading-secondary color-standard">
                              {moment(duration?.date).calendar(
                                null,
                                CALENDER_FORMAT
                              )}
                            </div>
                            <div>
                              <span className="text-label-secondary color-label color-standard">
                                Total:
                              </span>{' '}
                              <span className="text-heading-secondary color-standard">
                                {toHoursAndMinutes(
                                  Math.floor(duration?.totalHours),
                                  SCREEN.desktop
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="p-3 custom-table">
                            {size?.width >= MEDIUM_SCREEN_WIDTH ? (
                              <DashboardLarge
                                timeEntries={timeEntries?.filter(
                                  (entry) =>
                                    moment(entry?.createdAt).format(
                                      'YYYY-MM-DD'
                                    ) === duration?.date
                                )}
                                handleDeleteEntryToggle={
                                  handleDeleteEntryToggle
                                }
                                expandedRows={expandedRows}
                                toggleRowExpansion={toggleRowExpansion}
                                handleEntryUpdate={handleEntryUpdate}
                                jobs={jobs}
                                isEditAllowed={
                                  moment().format('YYYY-MM-DD') ===
                                  duration?.date
                                }
                                teamLeaders={teamLeaders}
                              />
                            ) : (
                              <DashboardSmall
                                timeEntries={timeEntries?.filter(
                                  (entry) =>
                                    moment(entry?.createdAt).format(
                                      'YYYY-MM-DD'
                                    ) === duration?.date
                                )}
                                handleDeleteEntryToggle={
                                  handleDeleteEntryToggle
                                }
                                expandedRows={expandedRows}
                                toggleRowExpansion={toggleRowExpansion}
                                handleEntryUpdate={handleEntryUpdate}
                                isEditAllowed={
                                  moment().format('YYYY-MM-DD') ===
                                  duration?.date
                                }
                                teamLeaders={teamLeaders}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ))
                ) : (
                  <p>No Record Exists</p>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <span className="text-label-secondary d-none d-md-block">
                  Show
                </span>
                <div>
                  <select
                    className="page-couter text-label-secondary"
                    onChange={(e) =>
                      getWeekTimeEntries(entriesMeta?.page, e.target.value)
                    }
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
                <span className="text-label-secondary d-none d-md-block">
                  entries of {entriesMeta?.total}
                </span>
              </div>
              <div className="d-flex gap-1">
                <div
                  className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
                  onClick={(e) => {
                    if (entriesMeta?.page > 1) {
                      getWeekTimeEntries(
                        entriesMeta?.page - 1,
                        entriesMeta?.limit
                      );
                    }
                  }}
                >
                  {'<'}
                </div>
                {new Array(Math.ceil(entriesMeta?.total / entriesMeta?.limit))
                  .fill(0)
                  .map((val, index) => (
                    <div
                      className={`pag-item-wrapper normal-text-primary color-label cursor-pointer ${entriesMeta?.page === index + 1 ? 'sl-page' : ''
                        }`}
                      onClick={(e) =>
                        getWeekTimeEntries(index + 1, entriesMeta?.limit)
                      }
                    >
                      {index + 1}
                    </div>
                  ))}
                <div
                  className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
                  onClick={(e) => {
                    if (
                      entriesMeta?.page !==
                      new Array(
                        Math.ceil(entriesMeta?.total / entriesMeta?.limit)
                      ).fill(0).length
                    )
                      getWeekTimeEntries(
                        entriesMeta?.page + 1,
                        entriesMeta?.limit
                      );
                  }}
                >
                  {'>'}
                </div>
              </div>
            </div>
          </>
        ) : (
          <p className="text-center text-heading-primary">No Record Exists</p>
        )}
        <Confirmation
          title={'Are you sure you want to delete?'}
          description={'You won’t be able to reverse this action.'}
          show={deleteModal}
          toggle={toggleDeleteModal}
          icon={DELETE_FILL}
          onOkay={delEntry}
        />
      </div>
    </>
  );
}
