import EXPAND from '../../assets/icons/expand-white-bg.svg';
import EXPANDED from '../../assets/icons/expanded-white-bg.svg';

export default function TeamMobile({
  jobs,
  expandedRows,
  toggleRowExpansion,
  nameCell,
  jobsCell,
  dayCell,
  weekTotalCell,
  getRow,
}) {
  return (
    <div>
      <div className="d-flex flex-column gap-3">
        {jobs?.map((job) => (
          <div className="time-entry-wrapper">
            <div className="time-entry-header-wrapper align-items-center d-flex justify-content-between">
              <span className="text-label-primary color-standard">
                {nameCell(job?.user?.image, job?.user?.name, job?.user?.role)}
              </span>
              <span>
                {expandedRows?.includes(job?._id) ? (
                  <img
                    src={EXPANDED}
                    alt="expand"
                    onClick={() => toggleRowExpansion(job)}
                  />
                ) : (
                  <img
                    src={EXPAND}
                    alt="expand"
                    onClick={() => toggleRowExpansion(job)}
                  />
                )}
              </span>
            </div>
            {expandedRows?.includes(job?._id) && (
              <>
                <div className="time-entry-div">
                  {getRow('Jobs', jobsCell(job?.jobs))}

                  {getRow('Monday', dayCell(job?.jobs, 'Monday'))}

                  {getRow('Tuesday', dayCell(job?.jobs, 'Tuesday'))}

                  {getRow('Wednesday', dayCell(job?.jobs, 'Wednesday'))}

                  {getRow('Thursday', dayCell(job?.jobs, 'Thursday'))}

                  {getRow('Friday', dayCell(job?.jobs, 'Friday'))}

                  {getRow('Saturday', dayCell(job?.jobs, 'Saturday'))}

                  {getRow('Sunday', dayCell(job?.jobs, 'Sunday'))}
                </div>
                <div className="px-3 py-2 total-mobile d-flex justify-content-between align-items-center">
                  <span className="text-label-primary">Worker Total Hours</span>
                  <span className="text-label-heading">
                    {weekTotalCell(
                      job?.jobs,
                      job?.paymentRule?.weekly_hour_limit
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
