import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import removeBtn2x from '../../../assets/icons/removeBtn(2x).png';
import retakeBtn2x from '../../../assets/icons/retakeBtn(2x).png';
import cameraIcon from '../../../assets/icons/camera.png';

function Checkbox({
  register,
  errors,
  watch,
  preview,
  add,
  remove,
  isTrailer,
  isSubmitted,
  submittedForm,
}) {
  return (
    <>
      {' '}
      <Row>
        <Col sm='12' md='6'>
          <div
            className={`d-flex ${
              isSubmitted ? 'flex-row' : 'flex-column'
            } mt-3 ${isSubmitted ? ' justify-content-between me-3' : ''}`}
          >
            <label>
              Has the engine oil level been checked?
              <span className='required-color'> *</span>
            </label>
            {!isSubmitted ? (
              <div className='mt-3'>
                <label className='me-3'>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={true}
                    {...register(
                      isTrailer
                        ? 'trailer_is_engine_oil_been_checked'
                        : 'is_engine_oil_been_checked'
                      // {
                      //   required: true,
                      // }
                    )}
                  />
                  Yes
                </label>
                <label>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={false}
                    {...register(
                      isTrailer
                        ? 'trailer_is_engine_oil_been_checked'
                        : 'is_engine_oil_been_checked'
                    )}
                  />
                  No
                </label>
              </div>
            ) : (
              <div className='text-black'>
                {(
                  isTrailer
                    ? submittedForm?.trailer?.is_engine_oil_been_checked
                    : submittedForm?.safetyForm?.is_engine_oil_been_checked
                )
                  ? 'Yes'
                  : 'No'}
              </div>
            )}
          </div>
        </Col>
        <Col sm='12' md='6' className='ps-md-2'>
          <div
            className={`d-flex ${
              isSubmitted ? 'flex-row' : 'flex-column'
            } mt-3 ${isSubmitted ? ' justify-content-between me-3 ' : ''}`}
          >
            <label>
              Have the brakes been checked?
              <span className='required-color'> *</span>
            </label>
            {!isSubmitted ? (
              <div className='mt-3'>
                <label className='me-3'>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={true}
                    {...register(
                      isTrailer
                        ? 'trailer_is_brakes_been_checked'
                        : 'is_brakes_been_checked'
                    )}
                  />
                  Yes
                </label>
                <label>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={false}
                    {...register(
                      isTrailer
                        ? 'trailer_is_brakes_been_checked'
                        : 'is_brakes_been_checked'
                    )}
                  />
                  No
                </label>
              </div>
            ) : (
              <div className='text-black'>
                {(
                  isTrailer
                    ? submittedForm?.trailer?.is_brakes_been_checked
                    : submittedForm?.safetyForm?.is_brakes_been_checked
                )
                  ? 'Yes'
                  : 'No'}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm='12' md='6'>
          <div
            className={`d-flex ${
              isSubmitted ? 'flex-row' : 'flex-column'
            } mt-3 ${isSubmitted ? ' justify-content-between me-3' : ''}`}
          >
            <label>
              Have the lights been checked?
              <span className='required-color'> *</span>
            </label>
            {!isSubmitted ? (
              <div className='mt-3'>
                <label className='me-3'>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={true}
                    {...register(
                      isTrailer
                        ? 'trailer_is_lights_been_checked'
                        : 'is_lights_been_checked'
                    )}
                  />
                  Yes
                </label>
                <label>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={false}
                    {...register(
                      isTrailer
                        ? 'trailer_is_lights_been_checked'
                        : 'is_lights_been_checked'
                    )}
                  />
                  No
                </label>
              </div>
            ) : (
              <div className='text-black'>
                {(
                  isTrailer
                    ? submittedForm?.trailer?.is_lights_been_checked
                    : submittedForm?.safetyForm?.is_lights_been_checked
                )
                  ? 'Yes'
                  : 'No'}
              </div>
            )}
          </div>
        </Col>
        <Col sm='12' md='6' className='ps-md-2'>
          <div
            className={`d-flex ${
              isSubmitted ? 'flex-row' : 'flex-column'
            } mt-3 ${isSubmitted ? ' justify-content-between me-3' : ''}`}
          >
            <label>
              Have the tires been checked?
              <span className='required-color'> *</span>
            </label>
            {!isSubmitted ? (
              <div className='mt-3'>
                <label className='me-3'>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={true}
                    {...register(
                      isTrailer
                        ? 'trailer_is_tires_been_checked'
                        : 'is_tires_been_checked'
                    )}
                  />
                  Yes
                </label>
                <label>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={false}
                    {...register(
                      isTrailer
                        ? 'trailer_is_tires_been_checked'
                        : 'is_tires_been_checked'
                    )}
                  />
                  No
                </label>
              </div>
            ) : (
              <div className='text-black'>
                {(
                  isTrailer
                    ? submittedForm?.trailer?.is_tires_been_checked
                    : submittedForm?.safetyForm?.is_tires_been_checked
                )
                  ? 'Yes'
                  : 'No'}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm='12' md='6'>
          <div
            className={`d-flex ${
              isSubmitted ? 'flex-row' : 'flex-column'
            } mt-3 ${isSubmitted ? ' justify-content-between me-3' : ''}`}
          >
            <label>
              Any Visual Defects?<span className='required-color'> *</span>
            </label>
            {!isSubmitted ? (
              <div className='mt-3'>
                <label className='me-3'>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={true}
                    {...register(
                      isTrailer
                        ? 'trailer_any_visual_defects'
                        : 'any_visual_defects'
                    )}
                  />
                  Yes
                </label>
                <label>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={false}
                    {...register(
                      isTrailer
                        ? 'trailer_any_visual_defects'
                        : 'any_visual_defects'
                    )}
                  />
                  No
                </label>
              </div>
            ) : (
              <div className='text-black'>
                {(
                  isTrailer
                    ? submittedForm?.trailer?.any_visual_defects
                    : submittedForm?.safetyForm?.any_visual_defects
                )
                  ? 'Yes'
                  : 'No'}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {(!isSubmitted &&
        isTrailer &&
        watch('trailer_any_visual_defects') === 'true') ||
      (!isTrailer && watch('any_visual_defects') === 'true') ? (
        <Row className='mt-3'>
          <Col sm='12'>
            <div>
              <label className='mb-2 d-inline-block'>Comments</label>
            </div>
            <textarea
              placeholder='Write here'
              className={`form-input w-100 ${
                errors['comments'] && 'invalid-input'
              }`}
              rows={'5'}
              {...register(isTrailer ? 'trailer_comments' : 'comments')}
            />
          </Col>
        </Row>
      ) : isTrailer ? (
        <>
          {isSubmitted && submittedForm?.trailer?.comment && (
            <div className='mt-4'>Comments</div>
          )}
          <div className='text-black mt-2'>
            {submittedForm?.trailer?.comment}
          </div>
        </>
      ) : (
        <>
          {isSubmitted && submittedForm?.safetyForm?.comment && (
            <div className='mt-4'>Comments</div>
          )}
          <div className='text-black mt-2'>
            {submittedForm?.safetyForm?.comment}
          </div>
        </>
      )}
      <Row className='mt-3 d-flex justify-content-center align-items-center'>
        {isSubmitted && !preview ? '' : <Col>Attachment</Col>}
        <Col>
          <input
            type='file'
            accept='image/*'
            onChange={add}
            style={{ display: 'none' }}
            id={isTrailer ? 'trailer-imput' : 'image-input'}
          />
          {!preview && !isSubmitted && (
            <label
              htmlFor={isTrailer ? 'trailer-imput' : 'image-input'}
              className='camera-button  gap-2'
            >
              <img src={cameraIcon} alt='' />
              Take a picture
            </label>
          )}
        </Col>
      </Row>
      {preview && (
        <Row className='mt-3  d-block d-md-none'>
          <div className='d-flex flex-column image-container p-3'>
            <img
              src={preview}
              alt='Preview'
              style={{ maxWidth: '100%', maxHeight: '150px' }}
            />
            {!isSubmitted && (
              <>
                <div className='d-flex flex-row gap-3 justify-content-center mt-3'>
                  <img
                    src={removeBtn2x}
                    width={'45%'}
                    alt='remove'
                    onClick={remove}
                  />
                  <label htmlFor={isTrailer ? 'trailer-imput' : 'image-input'}>
                    <img src={retakeBtn2x} alt='retake' width={'100%'} />
                  </label>
                </div>
              </>
            )}
          </div>
        </Row>
      )}
    </>
  );
}
export default Checkbox;
