import React, { useState } from "react";

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <input
          className="filter-input-field"
          placeholder="Find jobs..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <span className="text-heading pt-4 pb-3 d-block">Jobs</span>
        <ul className="jobs-listing job-listing-height">
          {React.Children.toArray(children).filter((child) => {
            return (
              !value ||
              child?.props?.value?.toLowerCase().includes(value.toLowerCase())
            );
          })}
        </ul>
      </div>
    );
  }
);

export default CustomMenu;
