import moment from "moment";
import { SCREEN } from "../../constants";
import { toHoursAndMinutes } from "../../services/utils";

import EXPAND from "../../assets/icons/expand.svg";
import EXPANDED from "../../assets/icons/expanded.svg";

import ProfilesCell from '../common/ProfilesCell';

export default function DashboardSmall({
    timeEntries,
    expandedRows,
    toggleRowExpansion,
    durationMap
}) {

    const calPayout = (row) => {
        let one_day_payout_amount = 0;
        const entries = row?.entries;
        for (const entry of entries) {
            one_day_payout_amount += entry.payout_amount;
        }
        return one_day_payout_amount.toFixed(2);
    }

    return (
        <div>
            <div className=" d-flex flex-column gap-3">
                {timeEntries?.map((entry) => (
                    <div className="history-time-entry-wrapper">

                        <div className="history-time-entry-header-wrapper align-items-center d-flex justify-content-between">
                            <div className="text-label-primary">
                                {moment(entry?._id).format("ddd, MMM D")}
                            </div>
                            <span>
                                <div className="d-flex">
                                    {entry?.entries?.length > 1 && (
                                        <img
                                            className="cursor-pointer"
                                            alt="expand-icon"
                                            src={expandedRows?.includes(entry?._id) ? EXPANDED : EXPAND}
                                            onClick={() => toggleRowExpansion(entry)}
                                        />
                                    )}
                                </div>
                            </span>
                        </div>

                        {/* main view of each entry  */}
                        <div className={`time-entry-div history-time-entry-div ${expandedRows?.includes(entry?._id) ? 'remove-border' : ''}`}>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-label-primary">Jobs</span>
                                <span className="normal-text">
                                    <u className="hist-rows text-decoration-none">
                                        {[...(new Set(entry?.entries?.map((entry) => entry?.job?.name)))]?.[0]}
                                        {
                                            [...(new Set(entry?.entries?.map((entry) => entry?.job?.name)))].length > 1 ?
                                                <span className="more-span px-2 ms-1">
                                                    {[...(new Set(entry?.entries?.map((entry) => entry?.job?.name)))].length - 1} more...
                                                </span> :
                                                null
                                        }
                                        {
                                            [...(new Set(entry?.entries?.map((entry) => entry?.job?.name)))].length > 1 ?
                                                <div className="additional-jobs">
                                                    Jobs
                                                    <div className="additional-jobs-sub">
                                                        {
                                                            [...(new Set(entry?.entries?.map((entry) => entry?.job?.name)))]?.slice(1).map((job, index) => (
                                                                <div key={index}>{job}</div>
                                                            ))
                                                        }
                                                    </div>
                                                </div> :
                                                null
                                        }
                                    </u>
                                </span>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-label-primary">Total Time</span>
                                <span className="normal-text">
                                    <u className="text-decoration-none">
                                        {durationMap.find((obj) => obj.date === entry._id) ? toHoursAndMinutes(durationMap.find((obj) => obj.date === entry._id).totalSecondsPerDay, SCREEN.desktop) : ''}
                                    </u>
                                </span>
                            </div>

                            {/* commented on clients demand  */}
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <span className="text-label-primary">Total Payout Amount</span>
                                <span className="normal-text">
                                    <u className="text-decoration-none">
                                        ${calPayout(entry)}
                                    </u>
                                </span>
                            </div> */}
                        </div>

                        {/* expanded view of each entry  */}
                        {expandedRows.includes(entry?._id) &&
                            (
                                <div className="sub-entries-wrapper">
                                    {entry?.entries?.map((obj) => {
                                        return (
                                            <div className="sub-entry-wrapper">

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-label-primary">PO Number</span>
                                                    <span className="normal text">
                                                        PID{obj?.job?.po_number}
                                                    </span>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-label-primary">Job Name</span>
                                                    <span className="normal text">
                                                        {obj?.job?.name}
                                                    </span>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-label-primary">Team Leaders</span>
                                                    <ProfilesCell teamLeaders={obj?.job?.team_leads} />
                                                </div>

                                                {/* commented on clients demand  */}
                                                {/* <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-label-primary">Payout Amount</span>
                                                    <span className="normal text">
                                                        ${obj?.payout_amount?.toFixed(2)}
                                                    </span>
                                                </div> */}

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-label-primary">Time</span>
                                                    <span className="normal text">
                                                        {toHoursAndMinutes(
                                                            obj?.time_intervals
                                                                ?.map((timeTrack) =>
                                                                    moment(timeTrack?.end_time).diff(
                                                                        moment(timeTrack?.start_time),
                                                                        "seconds"
                                                                    )
                                                                )
                                                                .reduce((accumulator, currentValue) => {
                                                                    return accumulator + currentValue;
                                                                }, 0),
                                                            SCREEN.mobile
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}