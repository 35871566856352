import moment from 'moment';
import EXPAND from '../../assets/icons/expand-white-bg.svg';
import EXPANDED from '../../assets/icons/expanded-white-bg.svg';
import { SCREEN } from '../../constants';
import { getDaysDiff, toHoursAndMinutes } from '../../services/utils';
import ProfilesCell from '../common/ProfilesCell';
export default function SafetyformTableMobile({
  data,
  expandedRows,
  toggleRowExpansion,
}) {
  const getRow = (title, data) => (
    <div className='d-flex justify-content-between align-items-center'>
      <span className='text-label-primary'>{title}</span>
      <span className='text-label-heading'>{data}</span>
    </div>
  );

  return (
    <div>
      <div className=' d-flex flex-column gap-3'>
        {data?.map((entry) => (
          <div className='time-entry-wrapper'>
            <div className='time-entry-header-wrapper align-items-center d-flex justify-content-between'>
              <span className='text-label-primary color-standard'>{`${entry?.job}`}</span>
              <span>
                {expandedRows?.includes(entry?._id) ? (
                  <img
                    src={EXPANDED}
                    alt='expand'
                    onClick={() => toggleRowExpansion(entry)}
                  />
                ) : (
                  <img
                    src={EXPAND}
                    alt='expand'
                    onClick={() => toggleRowExpansion(entry)}
                  />
                )}
              </span>
            </div>
            {expandedRows?.includes(entry?._id) && (
              <div className='time-entry-div'>
                {getRow('VIN', entry?.basicInfo?.vin_number)}

                {getRow(
                  'Date',
                  moment(entry?.safetyForm.createdAt).format('DD MMM, YYYY')
                )}

                {getRow('Make', entry?.basicInfo?.make || 'N/A')}

                {getRow('Model', entry?.basicInfo?.model || 'N/A')}

                {getRow(
                  'License Number',
                  entry?.basicInfo?.license_plate_number || 'N/A'
                )}

                {getRow('Year', entry?.basicInfo?.year || 'N/A')}

                {getRow(
                  'Odometer Reading',
                  entry?.safetyForm?.odometer_reading
                    ? entry?.safetyForm?.odometer_reading + ' KM'
                    : 'N/A'
                )}

                {getRow(
                  'Action',
                  <a
                    href={`/safety_form/${entry?._id}`}
                    style={{ color: '#3B98D1' }}
                  >
                    View Details
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
