import { MEDIUM_SCREEN_WIDTH, SCREEN, TIME_VAR } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import Pagination from '../pagination';
import Header from './Header';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';
import { useEffect, useState } from 'react';
import { getUserJobs, getJobUsers, addUsersToJobs } from '../../network/jobs';
import { toast } from 'react-toastify';
import { getUserNotInTeam } from '../../network/users';
import AddWorker from '../modals/AddWorker';
import { addEntryByLead } from '../../network/timeTrack';
import SuccessModal from '../modals/SuccessModal';
import SUCCESS_ICON from '../../assets/icons/success.svg';
import { useNavigate } from 'react-router-dom';
import { toHoursAndMinutes } from '../../services/utils';
import moment from 'moment';

export default function TLDashboard() {
  const size = useWindowSize();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [loader, setLoader] = useState();
  const [users, setUsers] = useState([]);
  const [workerModal, toggleWorkerModal] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [successModal, toggleSuccessModal] = useState(false);

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {}, []);

  const getJobs = async () => {
    setLoader(true);
    let res = await getUserJobs();
    if (res.data?.data?.jobs) {
      setJobs(res.data?.data?.jobs);
    } else {
      toast.error('You have not been assigned any job yet.');
    }
    setLoader(false);
  };

  const handleJobSelect = async (val) => {
    setUsers([]);
    setLoader(true);
    setSelectedJob(val);
    let res = await getJobUsers(val?._id);
    if (res?.data?.users) {
      setUsers(res?.data?.users);
    } else {
      toast.error('Error in fetching users!');
    }
    setLoader(false);
  };

  const handleAddUserClick = async () => {
    setLoader(true);
    let res = await getUserNotInTeam(selectedJob?._id);
    if (res?.data?.users) {
      setWorkers(res?.data?.users);
    } else {
      toast.error('Something went wrong!');
    }
    setLoader(false);
    toggleWorkerModal(true);
  };

  const handleAddSingleWorker = (id) => {
    if (selectedWorkers?.includes(id)) {
      setSelectedWorkers((prevWorkers) =>
        prevWorkers.filter((val) => val !== id)
      );
    } else {
      setSelectedWorkers((prevWorkers) => [...prevWorkers, id]);
    }
  };

  const handleModalClose = () => {
    toggleWorkerModal(false);
    setWorkers([]);
    setSelectedWorkers([]);
  };

  const handleSelectAll = () => {
    if (selectedWorkers?.length === workers?.length) {
      setSelectedWorkers([]);
    } else {
      setSelectedWorkers(workers?.map((val) => val._id));
    }
  };

  const onTimeEntryUpdate = (val, id, field) => {
    setUsers((oldUsers) =>
      oldUsers.map((user) => {
        if (user?._id === id) {
          let savedTime = toHoursAndMinutes(
            moment(user?.time_entry?.time_intervals[0]?.end_time).diff(
              user?.time_entry?.time_intervals[0]?.start_time,
              'seconds'
            ),
            SCREEN.mobile
          );
          let savedHours = savedTime?.split(':')[0];
          let savedMinutes = savedTime?.split(':')[1];
          let newField = '00:00';
          let formattedVal = val;
          if (Number(val) < 10) {
            formattedVal = val === '00' ? val : `0${val}`;
          }
          if (field === TIME_VAR.hour) {
            newField = user?.time
              ? `${formattedVal}:${user?.time?.split(':')[1]}`
              : `${formattedVal}:${savedMinutes}`;
          } else if (field === TIME_VAR.minute) {
            newField = user?.time
              ? `${user?.time?.split(':')[0]}:${formattedVal}`
              : `${savedHours}:${formattedVal}`;
          }
          return { ...user, time: newField };
        }
        return user;
      })
    );
  };

  const handleGoToDashboard = () => {
    window.location.reload(true);
  };

  const handleLimitChange = (val) => {
    setCurrentPage(1);
    setLimit(val);
  };

  const handleAddUserConfirm = async () => {
    let res = await addUsersToJobs(selectedJob?._id, selectedWorkers);
    if (res?.data?.message) {
      toggleWorkerModal(false);
      setWorkers([]);
      setSelectedWorkers([]);
      toast.success('User added successfully.');
      handleJobSelect(selectedJob);
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleLogHours = async () => {
    let usersWithUpdatedEntry = users
      ?.filter((user) => user?.time && user?.time !== '00:00')
      .map((user) => {
        return {
          _id: user?._id,
          payment_rule_id: user?.payment_rule_id,
          time: user?.time,
          tt_id: user?.time_entry?._id,
        };
      });
    setLoader(true);
    if (usersWithUpdatedEntry?.length) {
      let res = await addEntryByLead({
        users: usersWithUpdatedEntry,
        jobId: selectedJob?._id,
      });
      if (res?.data?.message) {
        toggleSuccessModal(true);
      } else {
        toast.error('Something went wrong!');
      }
    } else {
      toast.info('Please Update at least one entry to log hours');
    }
    setLoader(false);
  };

  return (
    <>
      {loader && <div className="loader"></div>}

      <Header
        jobs={jobs}
        selectedJob={selectedJob}
        setSelectedJob={handleJobSelect}
        loading={loader}
        users={users}
        handleLogHours={handleLogHours}
      />
      {selectedJob?.name ? (
        <div
          className={`dashboard-container-tl d-flex flex-column justify-content-between ${
            loader ? 'opacity-50 p-none' : ''
          }`}
        >
          {users?.length ? (
            <>
              {size?.width >= MEDIUM_SCREEN_WIDTH ? (
                <DesktopTable
                  selectedJob={selectedJob}
                  users={users?.slice(
                    (currentPage - 1) * limit,
                    (currentPage - 1) * limit + limit
                  )}
                  handleAddUserClick={handleAddUserClick}
                  onTimeEntryUpdate={onTimeEntryUpdate}
                />
              ) : (
                <MobileTable
                  users={users?.slice(
                    (currentPage - 1) * limit,
                    (currentPage - 1) * limit + limit
                  )}
                  handleAddUserClick={handleAddUserClick}
                  onTimeEntryUpdate={onTimeEntryUpdate}
                />
              )}
            </>
          ) : (
            <p className="text-center text-heading-primary">No Record Exists</p>
          )}

          <Pagination
            limit={limit}
            handleLimitChange={(val) => handleLimitChange(val)}
            totalPage={Math.ceil(users?.length / limit)}
            currentPage={currentPage}
            totalCount={users?.length}
            handlePageChange={(val) => setCurrentPage(Number(val))}
            handleNextPageClick={() => setCurrentPage(currentPage + 1)}
            handlePreviousPageClick={() => setCurrentPage(currentPage - 1)}
          />
        </div>
      ) : (
        <p className="text-center text-heading-primary mt-3">
          Select Job from dropdown
        </p>
      )}
      <AddWorker
        isOpen={workerModal}
        handleClose={handleModalClose}
        workers={workers}
        selectedWorkers={selectedWorkers}
        handleAddSingleWorker={handleAddSingleWorker}
        handleSelectAll={handleSelectAll}
        handleAddUserConfirm={handleAddUserConfirm}
      />
      <SuccessModal
        show={successModal}
        toggle={handleGoToDashboard}
        icon={SUCCESS_ICON}
        title={'Hours Logged'}
        description={'Hours has been logged successfully'}
        onCancel={handleGoToDashboard}
        onConfirm={() => navigate('/daily_logs')}
      />
    </>
  );
}
