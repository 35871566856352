export default function Pagination({
  currentPage,
  totalPage,
  handlePageChange,
  limit,
  handleLimitChange,
  handleNextPageClick,
  handlePreviousPageClick,
  totalCount,
}) {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-2 align-items-center">
        <span className="text-label-secondary d-none d-md-block">Show</span>
        <div>
          <select
            className="page-couter text-label-secondary"
            value={limit}
            onChange={(e) => handleLimitChange(e.target.value)}
            tabIndex="-1"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <span className="text-label-secondary d-none d-md-block">
          entries of {totalCount}
        </span>
      </div>
      <div className="d-flex gap-1">
        <div
          className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
          onClick={() => currentPage !== 1 && handleNextPageClick()}
        >
          {'<'}
        </div>
        {new Array(totalPage ? totalPage : 0).fill(0).map((val, index) => (
          <div
            className={`pag-item-wrapper normal-text-primary color-label cursor-pointer ${
              currentPage === index + 1 ? 'sl-page' : ''
            }`}
            onClick={(e) => handlePageChange(index + 1)}
          >
            {index + 1}
          </div>
        ))}
        <div
          className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
          onClick={() => currentPage < totalPage && handlePreviousPageClick()}
        >
          {'>'}
        </div>
      </div>
    </div>
  );
}
