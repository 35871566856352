import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import './SafetyFormTypes.css';
import detailIcon from '../../assets/icons/detail_eye.png';
import searchIcon from '../../assets/icons/search-icon.png';
import { useNavigate } from 'react-router-dom';

function SafetyFormTypes() {
  const [forms, setForms] = useState([
    {
      _id: 1,
      title: 'Job Hazards Analysis',
      isAvailable: false,
    },
    {
      _id: 2,
      title: 'Tool Box Talk',
      isAvailable: false,
    },
    {
      _id: 3,
      title: 'Workplace Inspection Report',
      isAvailable: false,
    },
    {
      _id: 4,
      title: "Operator's Daily Logbook",
      route: 'operator_logbook',
      isAvailable: true,
    },
    {
      _id: 5,
      title: 'HSE: Near Miss Report',
      isAvailable: false,
    },
    {
      _id: 6,
      title: 'HSE: Incident Report',
      isAvailable: false,
    },
  ]);
  const [search, setSearch] = useState();
  const [filteredData, setFilteredData] = useState(forms);
  const navigate = useNavigate();
  const tableHeader = [
    {
      name: 'Form Name',
      width: '72%',
      cell: (row) => (
        <div
          className={row.isAvailable ? '' : 'coming-soon'}
          style={{ fontSize: '16px' }}
        >
          {row.title}
          {!row.isAvailable && <span className='status'> (Coming Soon)</span>}
        </div>
      ),
    },
    {
      name: 'Action',
      right: true,
      style: { textAlign: 'right' },
      cell: (row) => (
        <div className='action-icon' onClick={() => handleFormAction(row)}>
          <img
            src={detailIcon}
            alt='detail-icon'
            className={`${
              row.isAvailable ? 'detail-icon' : 'detail-icon-disabled'
            }`}
          />
        </div>
      ),
    },
  ];
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearch(searchTerm);

    if (!searchTerm) {
      setFilteredData(forms);
      return;
    }

    const filter = forms.filter((form) =>
      form?.title?.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filter);
  };

  const handleFormAction = (row) => {
    if (row?.isAvailable) {
      navigate(`/safety_forms/${row.route}`);
    }
  };

  return (
    <div className='main-container'>
      <div className='px-4'>
        <div className='mb-2'>
          <h4>Forms</h4>
        </div>
      </div>
      <div className=' search-bar d-flex align-items-center justify-content-between'>
        <div className='w-100 position-relative'>
          <img src={searchIcon} className='search-icon' />
          <input
            type='text'
            className='search-input'
            placeholder='Search'
            value={search || ''}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div>
        <div className='custom-table d-flex justify-content-between'>
          <DataTable columns={tableHeader} data={filteredData} />
        </div>
      </div>
    </div>
  );
}

export default SafetyFormTypes;
