import { useState } from 'react';
import LogHours from '../../components/modals/LogHours';
import TLDashboard from '../../components/TLDashboard';

function Dashboard() {
  const [logHoursModal, toggleLogHoursModal] = useState(false);
  return (
    <>
      {logHoursModal ? (
        <TLDashboard />
      ) : (
        <div className="app-container d-flex justify-content-center">
          <LogHours onToggle={() => toggleLogHoursModal(true)} />
        </div>
      )}
    </>
  );
}

export default Dashboard;
