import React, { useEffect, useState } from 'react';
import './ResultModal.css';
import truck from '../../../assets/icons/truck.svg';
import { useNavigate } from 'react-router-dom';

function ResultModal({
  data,
  scanAgain,
  isTrailer,
  updateTrailerVehicle,
  updateOpenQr,
  isTrailerTypeMisMatch,
}) {
  const [result, setResult] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const jsonObject = JSON.parse(data?.text);
    setResult(jsonObject);
  }, []);

  const viewDetails = () => {
    if (isTrailer?.isTrailer) {
      isTrailer.updateTrailerVehicle(result);
      isTrailer.updateOpenQr(false);
    } else {
      navigate('/safety_form', {
        state: { vehicleData: result, isTrailer: isTrailer },
      });
    }
  };

  return (
    <>
      {isTrailer?.isTrailer && isTrailerTypeMisMatch ? (
        <div className='body'>
          <div className='popup'>
            <div style={{ fontSize: '20px', marginTop: '20px' }}>
              Trailer Not Found!
            </div>
            <div className=' d-flex justify-content-around mt-3 gap-1'>
              <button className='primary-button-outline' onClick={scanAgain}>
                Scan Again
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='body'>
          <div className='popup'>
            {result?.vehicle_photo?.imageUrl ? (
              <img
                src={result?.vehicle_photo?.imageUrl}
                height={'80px'}
                width={'80px'}
              />
            ) : (
              <img src={truck} height={'80px'} width={'80px'} />
            )}

            <div style={{ fontSize: '20px', marginTop: '20px' }}>
              {isTrailer?.isTrailer ? 'Trailer Found!' : 'Vehicle Found!'}
            </div>
            <div className='make-modal'>
              {result?.make} {result?.model}
            </div>
            <div className=' d-flex justify-content-around mt-3 gap-1'>
              <button className='primary-button-outline' onClick={scanAgain}>
                Scan Again
              </button>
              <button
                className='primary-button primary-button-text'
                onClick={viewDetails}
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ResultModal;
