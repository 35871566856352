import React from 'react';
import userImage from '../../assets/images/user-profile.png';

function ProfilesCell({ teamLeaders }) {
  return (
    <div className="d-flex align-items-center">
      {teamLeaders?.length ? (
        <>
          {teamLeaders.slice(0, 3).map((leader, index) => {
            return (
              <div key={leader?.name}>
                <img
                  src={leader.image || userImage}
                  alt=""
                  className="profile-cell-img"
                  style={{
                    marginLeft: index !== 0 && '-10px',
                  }}
                />
              </div>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ProfilesCell;
