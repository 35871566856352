import '../../assets/styles/headerStyles.css';
import './index.css';
import CORE_LOGO from '../../assets/icons/logo.svg';
import CROSS from '../../assets/icons/cross.svg';
import DOWN_ARROW from '../../assets/icons/down-arrow.svg';
import PROFILE from '../../assets/icons/profile.svg';
import LOGOUT from '../../assets/icons/logout.svg';
import LOGOUT_FILL from '../../assets/icons/logout-fill.svg';
import BREADCRUMB from '../../assets/icons/breadcrumb.svg';

import { Link, useNavigate } from 'react-router-dom';
import { forwardRef, useEffect, useState, useLayoutEffect } from 'react';

import { Dropdown, NavDropdown } from 'react-bootstrap';

import { useAuth } from '../../hooks/useAuth';

import {
  NAVS,
  LEAD_NAVS,
  ROLES,
  NAV_LEAD_ALLOWED_TRACK,
} from '../../constants';
import Confirmation from '../modals/Confirmation';
import { connect } from 'socket.io-client';
import { toast } from 'react-toastify';
import {
  getNotifications,
  getNotificationsCount,
  markAllRead,
} from '../../network/notification';
import { generateUniqueId } from '../../services/utils';
import moment from 'moment';
import notifImage from '../../assets/images/Notifications.svg';
import useOutsideClick from '../../hooks/useOutsideClick';

function Header() {
  const [socket, setSocket] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [user, setUser] = useState();
  const auth = useAuth();

  const fetchNotification = async () => {
    try {
      const [data, count] = await Promise.all([
        getNotifications(),
        getNotificationsCount(),
      ]);
      setNotifications(data?.data?.data || []);
      setNotificationCount(count?.data?.unseenCount);
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    const newSocket = connect(
      process.env.REACT_APP_API_BASE_URL.replace('/api/v1', '')
    );
    newSocket.emit('join_room', 'ALLCHATS');
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchNotification();
    setUser(auth?.user);
  }, [auth?.user]);

  useEffect(() => {
    if (socket) {
      socket.on('update_notification', (data) => {
        if (user?._id === data?.user_id) {
          toast.warn(
            `Your certificate "${data?.description}" has been expired. Please contact admin!`
          );
        }
        fetchNotification();
      });
    }
  }, [socket]);

  const navigate = useNavigate();

  const OutsideClick = useOutsideClick(() => {
    toggleNav(false);
    toggleProfileBar(false);
  });

  const [isNav, toggleNav] = useState(false);
  const [logoutModal, toggleLogoutModal] = useState(false);
  const [profileBar, toggleProfileBar] = useState(false);
  const [nav, setNav] = useState(
    auth?.user?.role === ROLES.team_lead
      ? auth?.user?.team?.is_track_permitted_by_lead
        ? NAV_LEAD_ALLOWED_TRACK
        : LEAD_NAVS
      : NAVS
  );

  useEffect(() => {}, [navigate]);

  const handleLogout = (e) => {
    e.stopPropagation();
    auth?.logout();
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div
      href=''
      className='d-flex gap-3 align-items-center cursor-pointer'
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  const handleClearAll = async () => {
    try {
      await markAllRead();
      fetchNotification();
    } catch (error) {
      console.log(error);
    }
  };

  const getNotificationsDropdown = () => {
    const list = [];
    notifications.length &&
      list.push(
        <NavDropdown.Item className='notif-head' key={generateUniqueId()}>
          <div className='py-2 d-flex align-items-center justify-content-between'>
            <div className='text-white'>
              Notifications {notificationCount ? `(${notificationCount})` : ''}
            </div>
            <div className='text-white' role='button' onClick={handleClearAll}>
              Clear All
            </div>
          </div>
        </NavDropdown.Item>
      );
    notifications.slice(0, 3).forEach((notification) => {
      list.push(
        <NavDropdown.Item
          key={generateUniqueId()}
          className={!notification?.is_seen_by_user && 'unread-notif'}
        >
          <div className='py-2'>
            <div className='d-flex align-items-center'>
              <div className='wrap-break'>
                Your certificate "{notification?.certificateName}" has been
                expired. Please contact admin!
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
              <small className='text-black-50'>
                {moment(notification?.notify_date).format('DD-MM-YYYY')}
              </small>
            </div>
          </div>
        </NavDropdown.Item>
      );
    });
    list.push(
      notifications.length ? (
        <NavDropdown.Item
          key={generateUniqueId()}
          onClick={() => navigate(`/notifications`)}
        >
          <div className='w-100 text-center py-2 view-all'>View All</div>
        </NavDropdown.Item>
      ) : (
        <NavDropdown.Item key={generateUniqueId()}>
          <div className='w-100 text-center py-2 pe-none'>No Notifications</div>
        </NavDropdown.Item>
      )
    );
    return list;
  };

  const notificationDiv = () => (
    <NavDropdown
      className='notif'
      title={
        <div className='position-relative'>
          <img src={notifImage} alt='messages' className='small-icon' />
          {notificationCount ? (
            <div className='position-absolute count-unseen'>
              {notificationCount}
            </div>
          ) : (
            <></>
          )}
        </div>
      }
    >
      {getNotificationsDropdown()}
    </NavDropdown>
  );

  const handleToggleNav = () => {
    toggleNav(!isNav);
    toggleProfileBar(false);
  };

  return (
    <>
      <div className='header'>
        <div className='d-flex gap-md-5 gap-3'>
          <div
            className='d-flex d-md-none header-breadcrumb'
            ref={OutsideClick}
          >
            <img src={BREADCRUMB} alt='breadcrumb' onClick={handleToggleNav} />
            {isNav && (
              <div className={`mbl-navs-wrapper`} ref={OutsideClick}>
                <div>
                  <img
                    src={CROSS}
                    alt='cross-icon'
                    onClick={() => toggleNav(false)}
                  />
                </div>
                {nav?.map((nav) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(nav?.to);
                      toggleNav(false);
                    }}
                    key={`nav_${nav.key}`}
                  >
                    <img src={nav?.icon_dark} alt={nav?.icon_alt} />
                    <span>{nav?.title}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <img src={CORE_LOGO} alt='core-logo' />
          </div>
          <div className='d-md-flex d-none gap-3'>
            {/* Leaving the commented code on purpose */}
            {/* {nav.map((nav) => (
              <>
                <div
                  className='d-flex align-items-center gap-2 cursor-pointer position-relative'
                  key={`nav_mbl_div_${nav.key}`}
                >
                  <Link
                    className='nav-link d-flex gap-2'
                    to={nav?.to}
                    key={`nav_mbl_${nav.key}`}
                  >
                    <img src={nav?.icon} alt={nav?.icon_alt} />
                    {nav?.title}
                  </Link>
                  {window.location.href.includes(nav?.to) && (
                    <div className='active-bar position-absolute'></div>
                  )}
                </div>
              </>
            ))} */}

            {nav
              .filter((navItem) => navItem.key !== 'QrScan')
              .map((navItem) => (
                <div
                  className='d-flex align-items-center gap-2 cursor-pointer position-relative'
                  key={`nav_mbl_div_${navItem.key}`}
                >
                  <Link
                    className='nav-link d-flex gap-2'
                    to={navItem.to}
                    key={`nav_mbl_${navItem.key}`}
                  >
                    <img src={navItem.icon} alt={navItem.icon_alt} />
                    {navItem.title}
                  </Link>
                  {window.location.href.includes(navItem.to) && (
                    <div className='active-bar position-absolute'></div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className='d-flex gap-3'>
          <div className='d-flex d-md-none'>{notificationDiv()}</div>
          <div
            className='d-flex d-md-none profile-img-wrapper'
            onClick={() => toggleProfileBar(!profileBar)}
            ref={OutsideClick}
          >
            <img
              src={user?.image || PROFILE}
              alt='profile-icon'
              className='user-profile-image'
            />
          </div>
        </div>
        {profileBar && (
          <div
            className='mbl-navs-wrapper profile-bar'
            tabIndex={'0'}
            onBlur={() => toggleProfileBar(false)}
            ref={OutsideClick}
          >
            {/* <Link to={'/profile'} className="link-text"> */}
            <div
              className='link-text'
              onClick={() => {
                navigate('/profile');
                toggleProfileBar(false);
              }}
            >
              <img
                src={user?.image || PROFILE}
                alt='profile-icon'
                className='user-profile-image'
              />
              <span>Profile</span>
            </div>
            {/* </Link> */}
            <div onClick={() => toggleLogoutModal(true)}>
              <img src={LOGOUT} alt='logout-icon' />
              <span>Logout</span>
            </div>
          </div>
        )}
        <div className='d-md-flex d-none align-items-center gap-3'>
          {notificationDiv()}
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>
              <div className='profile-img-wrapper'>
                <img
                  src={user?.image || PROFILE}
                  alt='profile-icon'
                  className='user-profile-image'
                />
              </div>
              <div className='gap-2 d-flex'>
                <span className='normal-text color-white'>{user?.name}</span>
                <img src={DOWN_ARROW} alt='down-arrow' />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to={'/profile'} className='link-text'>
                  <div className='d-flex gap-3 link-text'>
                    <img
                      src={user?.image || PROFILE}
                      alt='profile'
                      className='user-profile-image'
                    />
                    <span>Profile</span>
                  </div>
                </Link>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item>
                <div
                  className='d-flex gap-3'
                  onClick={() => toggleLogoutModal(true)}
                >
                  <img src={LOGOUT} alt='logout' />
                  <span>Logout</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Confirmation
          show={logoutModal}
          toggle={toggleLogoutModal}
          onOkay={handleLogout}
          icon={LOGOUT_FILL}
          title={'Logout'}
          description={'Are you sure?'}
        />
      </div>
    </>
  );
}

export default Header;
