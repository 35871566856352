import API from './axiosClient';

export const getUserNotInTeam = async (jobId) => {
  return API.get(`/user/not_in_team/${jobId}`);
};

export const getUserDetail = async () => {
  return API.get(`/user/profile`);
};

export const updateUser = async (body) => {
  return API.put(`/user/update`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const removeProfile = async () => {
  return API.delete(`/user/remove-profile`);
};
