import {
  Badge,
  Overlay,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import CALENDER from "../assets/icons/calender-icon.svg";
import moment from "moment";
import { useState } from "react";

export default function TimeTrack({
  handleClockOut,
  currentTracking,
  handlePause,
}) {
  const [loader, setLoader] = useState(false);
  const timeTooltip = (props) => (
    <Popover {...props} className="tooltip-wrapper">
      <div className="d-flex flex-column gap-2">
        <span>Clocked In</span>
        <div className="d-flex flex-column gap-1 tooltip-entries">
          {currentTracking?.entries?.time_intervals?.map((entry) => (
            <span>{moment(entry?.start_time).format("hh:mm A")}</span>
          ))}
        </div>
      </div>
    </Popover>
  );

  const handlePauseClick = async () => {
    setLoader(true);
    await handlePause();
    setLoader(false);
  };

  const handleClockedOutClick = async () => {
    setLoader(true);
    await handleClockOut();
    setLoader(false);
  };

  return (
    <div className="track-wrapper">
      <div className="d-md-flex d-none gap-5 w-md-100">
        <div className="d-flex gap-4">
          <div className="d-flex flex-column gap-2 info-text">
            <span>Job ID</span>
            <p className="text-decoration-none">
              {currentTracking?.job?.po_number}
            </p>
          </div>
          <div className="d-flex flex-column gap-2 info-text">
            <span>Job Name</span>
            <p className="primary-text-color text-decoration-none">
              {"• "} <u>{` ${currentTracking?.job?.name}`}</u>
            </p>
          </div>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-5 justify-content-between">
            <div className="d-flex flex-column gap-2 info-text">
              <span>Clocked In</span>
            </div>
            <div className="d-flex flex-column gap-2 info-text">
              <span>Paused at</span>
            </div>
          </div>
          {currentTracking?.entries?.time_intervals?.length &&
            currentTracking?.entries?.time_intervals?.map((entry, index) => {
              if (index <= 1) {
                return (
                  <div
                    key={entry?._id}
                    className="d-flex justify-content-between"
                  >
                    <div className="d-flex info-text position-relative">
                      <p className="mb-0">
                        {moment(entry?.start_time).format("HH:mm")}
                      </p>
                      {currentTracking?.entries?.time_intervals?.length > 2 &&
                        index === 1 && (
                          <OverlayTrigger
                            trigger={"click"}
                            placement="top"
                            overlay={timeTooltip}
                          >
                            <div className="absolute-badge cursor-pointer custom-badge">
                              +
                              {currentTracking?.entries?.time_intervals
                                ?.length - 2}
                            </div>
                          </OverlayTrigger>
                        )}
                    </div>
                    <div className="d-flex flex-column gap-2 info-text">
                      <p>
                        {entry?.end_time
                          ? moment(entry?.end_time).format("HH:mm")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </div>
      <div className="d-flex gap-3 w-md-100">
        <div className="d-md-flex d-none gap-2 align-items-center">
          <img src={CALENDER} alt="calender-icon" />
          <span className="normal-text-primary primary-text-color">
            {currentTracking?.entries?.time_intervals?.length &&
              moment(
                currentTracking?.entries?.time_intervals?.[0]?.start_time
              ).format("MMMM DD, yyyy")}
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 w-md-100">
          <button
            className="primary-button-outline w-md-100"
            onClick={handlePauseClick}
            disabled={loader}
          >
            {currentTracking?.entries?.time_intervals?.length &&
            currentTracking?.entries?.time_intervals?.filter(
              (entry) => !entry?.end_time
            ).length
              ? "Pause"
              : "Start"}
          </button>
          <button
            className="primary-button primary-button-text w-md-100"
            onClick={handleClockedOutClick}
            disabled={loader}
          >
            Clock Out
          </button>
        </div>
      </div>
    </div>
  );
}
