import { useCallback, useEffect, useState } from 'react';
import { CALENDER_FORMAT, MEDIUM_SCREEN_WIDTH, SCREEN } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import {
  addEntryByLead,
  deleteTimeEntry,
  getTeamLogs,
} from '../../network/timeTrack';
import { toHoursAndMinutes } from '../../services/utils';
import DashboardLarge from './DashboardLarge';
import DashboardSmall from './DashboardSmall';
import filterImg from '../../assets/icons/filter.svg';
import DateRangeFilter from '../modals/DateRangeFilter';
import { toast } from 'react-toastify';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import Pagination from '../pagination';
import Confirmation from '../modals/Confirmation';
import DELETE_ICON from '../../assets/icons/delete-fill.svg';
import AddWorker from '../modals/AddWorker';
import { getUserNotInTeam } from '../../network/users';
import { addUsersToJobs } from '../../network/jobs';

export default function DailyLogComp() {
  const size = useWindowSize();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [durationMap, setDurationMap] = useState([]);
  const [entries, setEntries] = useState([]);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [deletedEntryId, setDeletedEntryId] = useState('');
  const [filters, setFilter] = useState({
    start_date: '',
    end_date: '',
    search: '',
  });
  const [entriesMeta, setEnriesMeta] = useState({});
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState(false);
  const [workerModal, toggleWorkerModal] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);

  moment.updateLocale("en", {
    week: {
      dow: 1,
    },
  });

  useEffect(() => {
    getUserTeamLogs();
  }, []);

  useEffect(() => { }, [entriesMeta]);

  const filterButton = () => (
    <button
      className="primary-button-outline"
      onClick={() => setShowModal(true)}
    >
      <div className="d-flex align-items-center justify-content-center">
        <img className="me-3" src={filterImg} alt="filter" />
        <span>Filter</span>
      </div>
    </button>
  );

  const getUserTeamLogs = async (
    currentPage = 1,
    limit = 10,
    start_date = '',
    end_date = '',
    query = ''
  ) => {
    setLoader(true);
    let res = await getTeamLogs(
      currentPage,
      limit,
      start_date ? moment(start_date).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : moment().startOf('week').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      end_date ? moment(end_date).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : moment().endOf('week').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      query
    );
    if (res?.data) {
      setDurationMap(res?.data?.durationMap?.dailyLogs);
      setSelectedTotal(res?.data?.totalHours || 0);
      setEntries(res?.data?.entries);
      setEnriesMeta(res?.data?.meta);
    } else {
      toast.error(res?.response?.data?.error || 'Something went wrong');
    }
    setLoader(false);
  };

  const handleLimitChange = (val) => {
    setLimit(val);
    getUserTeamLogs(
      currentPage,
      val,
      filters.start_date,
      filters.end_date,
      search
    );
  };

  const toggleRowExpansion = (row) => {
    if (expandedRows.includes(`${row?._id?.date}-${row?._id?.id}`)) {
      setExpandedRows(
        expandedRows.filter((id) => id !== `${row?._id?.date}-${row?._id?.id}`)
      );
    } else {
      setExpandedRows([...expandedRows, `${row?._id?.date}-${row?._id?.id}`]);
    }
  };

  const handleOkay = (filters) => {
    if (!filters?.start_date || !filters?.end_date) {
      toast.error('Please select complete date range');
    } else {
      setShowModal(false);
      let start_date = moment(filters?.start_date).format('YYYY-MM-DD');
      let end_date = moment(filters?.end_date).format('YYYY-MM-DD');
      setFilter({
        start_date,
        end_date,
      });
      getUserTeamLogs(currentPage, limit, start_date, end_date);
    }
  };

  const clearFilters = () => {
    setFilter((prev) => ({ ...prev, start_date: '', end_date: '' }));
    setShowModal(false);
  };

  const handleDebounce = (
    start_date,
    end_date,
    currentPage,
    limit,
    searchVal
  ) => {
    getUserTeamLogs(currentPage, limit, start_date, end_date, searchVal);
  };

  const debounceFn = useCallback(_debounce(handleDebounce, 500), []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(
      filters.start_date,
      filters.end_date,
      currentPage,
      limit,
      e.target.value
    );
  };

  const handlePageChange = (val) => {
    setCurrentPage(Number(val));
    getUserTeamLogs(val, limit, filters.start_date, filters.end_date, search);
  };

  const handleNextPageClick = () => {
    if (Math.ceil(entriesMeta?.total / limit) < currentPage + 1) {
      setCurrentPage((prev) => prev + 1);
      getUserTeamLogs(
        currentPage + 1,
        limit,
        filters.start_date,
        filters.end_date,
        search
      );
    }
  };

  const handlePreviousPageClick = () => {
    if (currentPage - 1 > 0) {
      setCurrentPage((prev) => prev - 1);
      getUserTeamLogs(
        currentPage - 1,
        limit,
        filters.start_date,
        filters.end_date,
        search
      );
    }
  };

  const handleDeleteEntry = async () => {
    setLoader(true);
    let res = await deleteTimeEntry(deletedEntryId);
    if (res.data) {
      setDeletedEntryId('');
      toggleDeleteModal(false);
      toast.success('Entry deleted successfully.');
      await getUserTeamLogs(
        currentPage,
        limit,
        filters.start_date,
        filters.end_date,
        search
      );
    } else {
      toast.error('Error in deleting time entry');
    }
    toggleDeleteModal(false);
    setLoader(false);
  };

  const handleDeleteIconClick = (entryId) => {
    setDeletedEntryId(entryId);
    toggleDeleteModal(true);
  };

  const updateEntry = async (data) => {
    if (data?.users?.[0]?.time === '00:00') {
      toast.error('Blank time entry is not allowed');
      setEntries([...entries]);
      return;
    }

    let res = await addEntryByLead(data);

    if (res.data) {
      await getUserTeamLogs(
        currentPage,
        limit,
        filters.start_date,
        filters.end_date,
        search
      );
      toast.success('Entry update successfully');
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleAddUserConfirm = async () => {
    let res = await addUsersToJobs(selectedJob, selectedWorkers);
    if (res?.data?.message) {
      toggleWorkerModal(false);
      setWorkers([]);
      setSelectedWorkers([]);
      toast.success('User added successfully.');
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleAddUserClick = async (jobId) => {
    setLoader(true);
    setSelectedJob(jobId);
    let res = await getUserNotInTeam(jobId);
    if (res?.data?.users) {
      setWorkers(res?.data?.users);
    } else {
      toast.error('Something went wrong!');
    }
    setLoader(false);
    toggleWorkerModal(true);
  };

  const handleAddSingleWorker = (id) => {
    if (selectedWorkers?.includes(id)) {
      setSelectedWorkers((prevWorkers) =>
        prevWorkers.filter((val) => val !== id)
      );
    } else {
      setSelectedWorkers((prevWorkers) => [...prevWorkers, id]);
    }
  };

  const handleModalClose = () => {
    toggleWorkerModal(false);
    setWorkers([]);
    setSelectedWorkers([]);
  };

  const handleSelectAll = () => {
    if (selectedWorkers?.length === workers?.length) {
      setSelectedWorkers([]);
    } else {
      setSelectedWorkers(workers?.map((val) => val._id));
    }
  };

  return (
    <>
      {loader ? <div className="loader"></div> : ''}
      <div className={loader ? 'opacity-50 p-none' : ''}>
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <span className="text-heading-primary">Daily Log</span>
          {size?.width < MEDIUM_SCREEN_WIDTH && filterButton()}
        </div>
        <div className=" filter-header-wrapper mt-3 d-flex align-items-center justify-content-between">
          <div className="w-100">
            <input
              type="text"
              className="search-input"
              placeholder="Search by Job ID or name..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {size?.width >= MEDIUM_SCREEN_WIDTH && filterButton()}
        </div>
        {entries?.length ? (
          <>
            <div className="d-flex flex-column justify-content-between mt-3">
              <div className="mb-3 d-flex justify-content-between">
                <div className="text-heading-primary">
                  {filters?.start_date && filters?.end_date
                    ? `${filters?.start_date} to ${filters?.end_date}`
                    : 'This Week'}
                </div>
                <div className="d-flex align-items-end gap-1">
                  <div className="text-label-secondary color-label">Total:</div>
                  <div className="text-heading-secondary">
                    {toHoursAndMinutes(
                      Math.floor(selectedTotal),
                      SCREEN.desktop
                    )}
                  </div>
                </div>
              </div>
              <div className="daily-log-tt-height d-flex flex-column gap-3">
                {durationMap?.length ? (
                  durationMap
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    ?.map((duration) => (
                      <div className="table-wrapper">
                        <div className="table-header">
                          <div className="text-heading-secondary color-standard">
                            {moment(duration?.date).calendar(
                              null,
                              CALENDER_FORMAT
                            )}
                          </div>
                          <div>
                            <span className="text-label-secondary color-label color-standard">
                              Total:
                            </span>{' '}
                            <span className="text-heading-secondary color-standard">
                              {toHoursAndMinutes(
                                Math.floor(duration?.totalSecs),
                                SCREEN.desktop
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="p-3 custom-table">
                          {size?.width <= MEDIUM_SCREEN_WIDTH ? (
                            <DashboardSmall
                              entries={entries?.filter(
                                (entry) => entry?._id?.date === duration.date
                              )}
                              expandedRows={expandedRows}
                              toggleRowExpansion={toggleRowExpansion}
                              handleDeleteIconClick={handleDeleteIconClick}
                              date={duration?.date}
                              updateEntry={updateEntry}
                              handleAddUserClick={handleAddUserClick}
                            />
                          ) : (
                            <DashboardLarge
                              entries={entries?.filter(
                                (entry) => entry?._id?.date === duration.date
                              )}
                              handleDeleteIconClick={handleDeleteIconClick}
                              date={duration?.date}
                              expandedRows={expandedRows}
                              toggleRowExpansion={toggleRowExpansion}
                              updateEntry={updateEntry}
                              handleAddUserClick={handleAddUserClick}
                            />
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <p className="text-center text-heading-primary">
                    No Record Exists
                  </p>
                )}
              </div>
              <div className="mt-3">
                <Pagination
                  currentPage={currentPage}
                  limit={limit}
                  totalPage={Math.ceil(entriesMeta?.total / limit)}
                  handlePageChange={handlePageChange}
                  handleLimitChange={(val) => handleLimitChange(Number(val))}
                  handleNextPageClick={handleNextPageClick}
                  handlePreviousPageClick={handlePreviousPageClick}
                />
              </div>
            </div>
          </>
        ) : (
          <p className="text-center text-heading-primary mt-3">
            No Record Exists
          </p>
        )}

        <DateRangeFilter
          show={showModal}
          toggle={setShowModal}
          onOkay={handleOkay}
          onClear={clearFilters}
          value={filters}
        />
        <Confirmation
          show={deleteModal}
          onOkay={handleDeleteEntry}
          toggle={() => toggleDeleteModal(false)}
          icon={DELETE_ICON}
          title={'Delete Time Entry'}
          description={'Are you sure you want to delete this?'}
        />
        <AddWorker
          isOpen={workerModal}
          handleClose={handleModalClose}
          workers={workers}
          selectedWorkers={selectedWorkers}
          handleAddSingleWorker={handleAddSingleWorker}
          handleSelectAll={handleSelectAll}
          handleAddUserConfirm={handleAddUserConfirm}
        />
      </div>
    </>
  );
}