import "../../assets/styles/logHoursStyles.css";
import LOG_HOURS from "../../assets/icons/log-hours.svg";

export default function LogHours({ onToggle }) {
  return (
    <div className="log-hours-wrapper">
      <img className="mb-4" src={LOG_HOURS} alt="log-hours" />
      <span className="modal-heading mt-4">Log Hours</span>
      <span className="normal-text my-3">
        You can add hours for the workers from here.
      </span>
      <button
        className="primary-button primary-button-text d-flex gap-2 mt-3"
        onClick={onToggle}
      >
        <span>+</span>
        <span>Add Hours</span>
      </button>
    </div>
  );
}
