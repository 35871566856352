import React, { useRef } from 'react';
import TimeField from '../cutomTimeField/TimeInput';
import TwoDigitTimeField from '../cutomTimeField/TwoDigitTimeField';
import { SCREEN, TIME_VAR } from '../../constants';
import { toHoursAndMinutes } from '../../services/utils';
import moment from 'moment';

const MobileTable = ({ users, onTimeEntryUpdate, handleAddUserClick }) => {
  return (
    <div className="mbl-log-table">
      <div className="w-100">
        <button
          className="primary-button-outline w-100"
          onClick={handleAddUserClick}
        >
          Add User
        </button>
      </div>
      <div className="w-100 d-flex flex-column gap-3 custom-table-log-mbl">
        {users?.length
          ? users.map((user, index) => (
              <div className="user-detail-wrapper" key={user._id}>
                <div className="d-flex justify-content-between w-100">
                  <span>Union ID</span>
                  <span>{user?.union_id}</span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span>Worker</span>
                  <span>{user?.name}</span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span>Time</span>
                  <span className="d-flex gap-1">
                    {/* <TimeField
                      id={`timeInput_${user._id}`}
                      className="time-input-mobile normal-text"
                      value={user?.time ? user?.time : '00:00'}
                      onChange={(e) =>
                        onTimeEntryUpdate(e.target.value, user?._id)
                      }
                    /> */}
                    <TwoDigitTimeField
                      id={`timeInput_${user._id}_hr`}
                      // value={user?.time ? user?.time?.split(':')[0] : ''}
                      handleChange={(val) =>
                        onTimeEntryUpdate(val, user?._id, TIME_VAR.hour)
                      }
                      value={
                        user?.time_entry
                          ? toHoursAndMinutes(
                              moment(
                                user?.time_entry?.time_intervals[0]?.end_time
                              ).diff(
                                user?.time_entry?.time_intervals[0]?.start_time,
                                'seconds'
                              ),
                              SCREEN.mobile
                            )?.split(':')[0]
                          : ''
                      }
                      min={1}
                      max={23}
                    />
                    :
                    <TwoDigitTimeField
                      id={`timeInput_${user._id}_mn`}
                      value={
                        user?.time_entry
                          ? toHoursAndMinutes(
                              moment(
                                user?.time_entry?.time_intervals[0]?.end_time
                              ).diff(
                                user?.time_entry?.time_intervals[0]?.start_time,
                                'seconds'
                              ),
                              SCREEN.mobile
                            )?.split(':')[1]
                          : ''
                      }
                      handleChange={(val) =>
                        onTimeEntryUpdate(val, user?._id, TIME_VAR.minute)
                      }
                      min={1}
                      max={59}
                    />
                  </span>
                </div>
              </div>
            ))
          : ''}
      </div>
    </div>
  );
};

export default MobileTable;
