import './VehicleInfo.css';
import React, { useEffect, useState } from 'react';
import truck from '../../assets/icons/truck.svg';
import cloudy from '../../assets/images/cloud.jpeg';
import clear from '../../assets/images/clear1.jpg';
import snow from '../../assets/images/snow.jpeg';
import foggy from '../../assets/images/foggy.jpeg';
import { getOneVehicle } from '../../network/vehicle';
import userImage from '../../assets/images/user-image.png';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function VehicleInfo({
  data,
  updateLoader,
  updateLocationGrante,
  isTrailer,
  userData,
  weather,
  openQr,
  isSubmitted,
  type,
  reading,
}) {
  const [user, setUser] = useState();
  const [vehicle, setvehicle] = useState();
  const [profileImage, setProfileImage] = useState();
  const [weatherData, setWeatherData] = useState();

  const imageToshow = (status) => {
    switch (status) {
      case 'Clear':
        return clear;
      case 'Rain':
        return cloudy;
      case 'Thunderstorm':
        return cloudy;
      case 'Drizzle':
        return cloudy;
      case 'Clouds':
        return cloudy;
      case 'Snow':
        return snow;
      case 'Mist':
        return foggy;
      case 'Smoke':
        return foggy;
      case 'Haze':
        return foggy;
      case 'Fog':
        return foggy;
      default:
        return clear;
    }
  };

  const getDate = () => {
    const currentDate = new Date();

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayOfMonth = currentDate.getDate();

    const monthIndex = currentDate.getMonth();
    const monthName = monthNames[monthIndex];
    const year = currentDate.getFullYear();

    const formattedDate = `${dayOfMonth} ${monthName}, ${year}`;
    return formattedDate;
  };

  useEffect(() => {
    if (!isSubmitted) {
      updateLoader(true);
      getOneVehicle(data?.vehicle_id)
        .then((res) => {
          setvehicle(res);
        })
        .catch((error) => {
          toast.error('Error in fetching details.');
          console.log(error);
        });
      if (!isTrailer) {
        setUser(userData);
        setWeatherData(weather);
      }
      updateLoader(false);
    } else {
      setUser(userData);
      setWeatherData(weather);
    }
  }, [user, weatherData]);

  return (
    <>
      {!openQr && (
        <div className={`card-container ${!isTrailer ? 'px-4' : ''}`}>
          {!isTrailer && (
            <div className='d-flex flex-row justify-content-between'>
              <div className='d-flex flex-column gap-2'>
                <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                  <img
                    className='round-img'
                    src={user?.image || userImage}
                    alt='profile'
                  />
                  <div className='heading-font'>{user?.name}</div>
                </div>
                <div className='role-font'>{user?.role}</div>
              </div>
              <div>{!isSubmitted && getDate()}</div>
            </div>
          )}
          <div className='d-flex flex-row justify-content-between py-4'>
            <div>
              <img
                src={
                  (isSubmitted
                    ? data?.vehicle_photos[0]?.imageUrl
                    : data?.vehicle_photo?.imageUrl) || truck
                }
                style={{ borderRadius: '10px' }}
                alt='truck img'
                className='truck-image'
              />
            </div>
            {!isTrailer && (
              <>
                <div style={{ position: 'relative' }}>
                  <img
                    src={imageToshow(
                      isSubmitted
                        ? weatherData?.status
                        : weatherData?.weather[0]?.main
                    )}
                    style={{ borderRadius: '10px' }}
                    alt='weather img'
                    className='weather-image'
                  />
                  <div className='main-temp'>
                    {Math.round(
                      isSubmitted
                        ? weatherData?.current_temperature
                        : weatherData?.main?.temp
                    )}
                    &deg;
                  </div>
                  <div className='area'>
                    {isSubmitted ? weatherData?.city : weatherData?.name}
                  </div>
                  <div className='d-flex flex-column peak-temp'>
                    <div>
                      {isSubmitted
                        ? weatherData?.status
                        : weatherData?.weather[0]?.main}
                    </div>
                    <div className='d-flex flex-row gap-2'>
                      <div>
                        H:
                        {Math.round(
                          isSubmitted
                            ? weatherData?.max_temperature
                            : weatherData?.main?.temp_max
                        )}
                        &deg;
                      </div>
                      <div>
                        L:
                        {Math.round(
                          isSubmitted
                            ? weatherData?.min_temperature
                            : weatherData?.main?.temp_min
                        )}
                        &deg;
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-column text-color gap-3'>
              <div className='d-flex flex-row justify-content-between gap-5'>
                <div>VIN</div>
                <div className='text-black'>{data?.vin_number || 'N/A'}</div>
              </div>
              <div className='d-flex flex-row justify-content-between gap-5'>
                <div>Make</div>
                <div className='text-black'>{data?.make || 'N/A'}</div>
              </div>
              <div className='d-flex flex-row justify-content-between gap-5'>
                <div>Model</div>
                <div className='text-black' style={{ textAlign: 'right' }}>
                  {data?.model || 'N/A'}
                </div>
              </div>
              <div className='d-flex flex-row justify-content-between gap-5'>
                <div>Type</div>
                <div className='text-black'>
                  {isSubmitted
                    ? type
                    : vehicle?.data?.data?.vehicle_category?.category || 'N/A'}
                </div>
              </div>
              <div className='d-flex flex-row justify-content-between gap-5'>
                <div>License Plate</div>
                <div className='text-black'>
                  {data?.license_plate_number || 'N/A'}
                </div>
              </div>
              <div className='d-flex flex-row justify-content-between gap-5'>
                <div>Year</div>
                <div className='text-black'>{data?.year || 'N/A'}</div>
              </div>
              {!isTrailer && (
                <div className='d-flex flex-row justify-content-between gap-5'>
                  <div>Last Odometer Reading</div>
                  <div className='text-black'>
                    {isSubmitted
                      ? reading
                      : (vehicle?.data?.data?.last_odometer_reading
                          ? vehicle?.data?.data?.last_odometer_reading
                          : vehicle?.data?.data?.current_odometer_reading) ||
                        'N/A'}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
