import moment from 'moment';
import EXPAND from '../../assets/icons/expand-white-bg.svg';
import EXPANDED from '../../assets/icons/expanded-white-bg.svg';
import { SCREEN } from '../../constants';
import { getDaysDiff, toHoursAndMinutes } from '../../services/utils';
import ProfilesCell from '../common/ProfilesCell';
export default function JobTableMobile({
  data,
  expandedRows,
  toggleRowExpansion,
}) {
  const getRow = (title, data) => (
    <div className='d-flex justify-content-between align-items-center'>
      <span className='text-label-primary'>{title}</span>
      <span className='text-label-heading'>{data}</span>
    </div>
  );

  return (
    <div>
      <div className=' d-flex flex-column gap-3'>
        {data?.jobs?.map((entry) => (
          <div className='time-entry-wrapper'>
            <div className='time-entry-header-wrapper align-items-center d-flex justify-content-between'>
              <span className='text-label-primary color-standard'>{`${entry?.name}`}</span>
              <span>
                {expandedRows?.includes(entry?._id) ? (
                  <img
                    src={EXPANDED}
                    alt='expand'
                    onClick={() => toggleRowExpansion(entry)}
                  />
                ) : (
                  <img
                    src={EXPAND}
                    alt='expand'
                    onClick={() => toggleRowExpansion(entry)}
                  />
                )}
              </span>
            </div>
            {expandedRows?.includes(entry?._id) && (
              <div className='time-entry-div'>
                {getRow('Job ID', entry?.po_number)}

                {getRow(
                  'Starting Date',
                  moment(entry?.start_date).format('DD MMM, YYYY')
                )}

                {getRow(
                  'Completion Date',
                  moment(entry?.end_date).format('DD MMM, YYYY')
                )}

                {getRow(
                  'Job Duration',
                  getDaysDiff(entry?.start_date, entry?.end_date) + ' days'
                )}

                {getRow(
                  'Team Leader(s)',
                  <ProfilesCell teamLeaders={data.team_leaders} />
                )}

                {getRow(
                  'Total Time',
                  toHoursAndMinutes(entry?.totalTime, SCREEN.mobile) || 'N/A'
                )}

                {/* {getRow('Payout Amount', '$' + entry?.payout_amount)} */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
