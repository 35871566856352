import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AuthLayout } from './layout/authLayout';
import { DefaultLayout } from './layout/defaultLayout';
import Login from './pages/auth/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/globalStyles.css';
import TimeTrack from './pages/timeTrack';
import Jobs from './pages/job';
import History from './pages/history';
import { useAuth } from './hooks/useAuth';
import { ROLES } from './constants';
import Dashboard from './pages/dashboard';
import Teams from './pages/teams';
import DailyLogs from './pages/dailyLogs';
import Profile from './pages/profile/Profile';
import DashbaordNotAllowed from './pages/dashboardNotAllowed/DashbaordNotAllowed';
import Notifications from './components/notifications';
import { useEffect, useState } from 'react';

import TimeTrackBottom from './components/TimeTrack';
import moment from 'moment';

import { toast } from 'react-toastify';

import Confirmation from './components/modals/Confirmation';
import Disabled from './components/modals/Disabled';
import NOT_ALLOWED from './assets/icons/not-allowed.svg';

import {
  pauseTimeEntry,
  getUnpausedEntry,
  addSubEntry,
  clockOut,
  deleteTimeEntry,
  deleteSubTimeEntry,
} from './network/timeTrack';
import QrScanner from './pages/QrCodePage/QrScanner';
import QrForm from './pages/QrCodePage/QrForm';
import SafetyFormTypes from './pages/safetyForms/SafetyFormTypes';
import OperatorLogBook from './pages/operatorLogBook/OperatorLogBook';

function App() {
  const auth = useAuth();
  const [selectedJob, setSelectedJob] = useState({});
  const [isTimeTrack, setTrackFlag] = useState(false);
  const [currentTracking, setCurrentTracking] = useState({});
  const [alertEntry, setAlertEntry] = useState({});

  const [disableModal, toggleDisableModal] = useState(false);
  const [description, setDescription] = useState('');
  const [timeAlert, toggleTimeAlert] = useState(false);

  const location = useLocation();
  // Access location properties
  const { pathname, search, hash, state } = location;

  const getUserUnPausedEntry = async () => {
    let unpausedEntry = await getUnpausedEntry();

    if (unpausedEntry?.data?.timeTrack) {
      setSelectedJob(unpausedEntry?.data?.timeTrack?.job);
      setCurrentTracking({
        job: unpausedEntry?.data?.timeTrack?.job,
        entries: unpausedEntry?.data?.timeTrack,
      });
      setTrackFlag(true);
    } else {
      setSelectedJob({});
      setCurrentTracking({});
      setTrackFlag(false);
    }
  };

  const handlePause = async () => {
    let trackingClone = { ...currentTracking };
    let allEntriesStop = trackingClone.entries?.time_intervals?.filter(
      (entry) => !entry?.end_time
    );
    if (allEntriesStop?.length) {
      if (
        moment().diff(moment(allEntriesStop?.[0]?.start_time), 'seconds') < 60
      ) {
        setAlertEntry({ ...currentTracking, isPaused: true });
        toggleTimeAlert(true);
      } else {
        await pauseTimeService(trackingClone, false);
      }
    } else {
      let entry = {
        start_time: moment().set({ second: 0 }).format(),
      };
      let trackedTime = await addSubEntry(trackingClone?.entries?._id, entry);
      if (trackedTime?.data?.time_entry) {
        setCurrentTracking((prevTracking) => {
          return { ...prevTracking, entries: trackedTime?.data?.time_entry };
        });
      } else {
        toast.error('Something went wrong.');
      }
    }
  };

  const pauseTimeService = async (trackingClone) => {
    let entryPaused = await pauseTimeEntry({
      entry_id: trackingClone.entries?._id,
      subentry_id:
        trackingClone.entries?.time_intervals[
          trackingClone.entries?.time_intervals?.length - 1
        ]?._id,
      end_time: moment().set({ second: 0 }).format(),
    });
    if (entryPaused?.data?.time_entry) {
      setCurrentTracking((prevTracking) => {
        return { ...prevTracking, entries: entryPaused?.data?.time_entry };
      });
      toast.success('Entry updated.');
      return true;
    } else {
      toast.error('Something went wrong.');
      return false;
    }
  };

  const handleClockOut = async () => {
    let trackingClone = { ...currentTracking };
    let allEntriesStop = trackingClone?.entries?.time_intervals?.filter(
      (entry) => !entry?.end_time
    );
    let updateEntry = {};
    if (allEntriesStop?.length) {
      if (
        moment().diff(moment(allEntriesStop?.[0]?.start_time), 'seconds') < 60
      ) {
        setAlertEntry({
          ...currentTracking,
          isPaused: false,
          clockout_time: moment().set({ second: 0 }).format(),
        });
        toggleTimeAlert(true);
        return;
      }
      updateEntry = {
        entry_id: trackingClone.entries?._id,
        subentry_id:
          trackingClone.entries?.time_intervals[
            trackingClone.entries?.time_intervals?.length - 1
          ]?._id,
        end_time: moment().set({ second: 0 }).format(),
      };
    } else {
      updateEntry = {
        entry_id: trackingClone.entries?._id,
      };
    }

    let clockedOutRes = await clockOut(updateEntry);

    if (clockedOutRes?.data?.time_entry) {
      toast.success('Clocked out successfully.');
      setTrackFlag(false);
      setDescription('');
      setSelectedJob({});
      setCurrentTracking([]);
    } else {
      toast.error('Something went wrong.');
    }
  };

  const handleShortEntry = async () => {
    let trackingClone = { ...alertEntry };
    let allEntriesStop = trackingClone?.entries?.time_intervals?.filter(
      (entry) => !entry?.end_time
    );
    if (trackingClone?.entries?.time_intervals?.length > 1) {
      let res = await deleteSubTimeEntry(
        trackingClone?.entries?._id,
        allEntriesStop?.[0]?._id
      );

      if (res?.data?.time_entry) {
        if (alertEntry?.isPaused) {
          setCurrentTracking((prevTracking) => {
            return { ...prevTracking, entries: res?.data?.time_entry };
          });
          toggleTimeAlert(false);
          setAlertEntry({});
        } else {
          await clockOut({ entry_id: trackingClone?.entries?._id });
          setTrackFlag(false);
          setDescription('');
          setSelectedJob({});
          setCurrentTracking([]);
          toggleTimeAlert(false);
          setAlertEntry({});
        }
      } else {
        toast.error('Something went wrong.');
      }
    } else {
      let res = await deleteTimeEntry(trackingClone?.entries?._id);
      if (!res?.data) {
        toast.error('Something went wrong.');
      } else {
        setTrackFlag(false);
        setDescription('');
        setSelectedJob({});
        setCurrentTracking([]);
        toggleTimeAlert(false);
        setAlertEntry({});
      }
    }
  };

  useEffect(() => {
    if (auth?.user && location.pathname !== '/qrscan') {
      getUserUnPausedEntry();
    }
  }, [pathname]);

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='/' element={<Login />} />
      </Route>
      {auth?.user?.role !== ROLES.team_lead ? (
        <>
          <Route element={<DefaultLayout />}>
            {auth?.user?.team?.is_track_permitted_by_lead && (
              <>
                <Route
                  path='/dashboard'
                  element={
                    <>
                      <DashbaordNotAllowed />
                      {isTimeTrack && (
                        <>
                          <TimeTrackBottom
                            currentTracking={currentTracking}
                            handlePause={handlePause}
                            handleClockOut={handleClockOut}
                          />
                          <Disabled
                            show={disableModal}
                            toggle={toggleDisableModal}
                          />
                          <Confirmation
                            show={timeAlert}
                            toggle={toggleTimeAlert}
                            onOkay={handleShortEntry}
                            icon={NOT_ALLOWED}
                            title={'Alert!'}
                            description={
                              "Entries under 1 minute won't be saved. Proceed?"
                            }
                          />
                        </>
                      )}
                    </>
                  }
                />
              </>
            )}

            <Route path='/dashboard' element={<TimeTrack />} />
            <Route path='/qrscan' element={<QrScanner />} />
            <Route path='/safety_form' element={<QrForm />} />
            <Route path='/safety_form/:id' element={<QrForm />} />
            <Route path='/safety_forms' element={<SafetyFormTypes />} />
            <Route
              path='/safety_forms/operator_logbook'
              element={<OperatorLogBook />}
            />
            <Route
              path='/jobs'
              element={
                <>
                  <Jobs isTimeTrack={isTimeTrack} />
                  {isTimeTrack && (
                    <>
                      <TimeTrackBottom
                        currentTracking={currentTracking}
                        handlePause={handlePause}
                        handleClockOut={handleClockOut}
                      />
                      <Disabled
                        show={disableModal}
                        toggle={toggleDisableModal}
                      />
                      <Confirmation
                        show={timeAlert}
                        toggle={toggleTimeAlert}
                        onOkay={handleShortEntry}
                        icon={NOT_ALLOWED}
                        title={'Alert!'}
                        description={
                          "Entries under 1 minute won't be saved. Proceed?"
                        }
                      />
                    </>
                  )}
                </>
              }
            />
            <Route
              path='/history'
              element={
                <>
                  <History isTimeTrack={isTimeTrack} />
                  {isTimeTrack && (
                    <>
                      <TimeTrackBottom
                        currentTracking={currentTracking}
                        handlePause={handlePause}
                        handleClockOut={handleClockOut}
                      />
                      <Disabled
                        show={disableModal}
                        toggle={toggleDisableModal}
                      />
                      <Confirmation
                        show={timeAlert}
                        toggle={toggleTimeAlert}
                        onOkay={handleShortEntry}
                        icon={NOT_ALLOWED}
                        title={'Alert!'}
                        description={
                          "Entries under 1 minute won't be saved. Proceed?"
                        }
                      />
                    </>
                  )}
                </>
              }
            />
            <Route path='/profile' element={<Profile />} />
          </Route>
        </>
      ) : (
        <Route element={<DefaultLayout />}>
          <Route path='/qrscan' element={<QrScanner />} />
          <Route path='/safety_forms' element={<SafetyFormTypes />} />
          <Route
            path='/safety_forms/operator_logbook'
            element={<OperatorLogBook />}
          />
          <Route path='/safety_form' element={<QrForm />} />
          <Route path='/safety_form/:id' element={<QrForm />} />
          {auth?.user?.team?.is_track_permitted_by_lead ? (
            <>
              <Route
                path='/dashboard'
                element={
                  <>
                    <Dashboard />
                    {isTimeTrack && (
                      <>
                        <TimeTrackBottom
                          currentTracking={currentTracking}
                          handlePause={handlePause}
                          handleClockOut={handleClockOut}
                        />
                        <Disabled
                          show={disableModal}
                          toggle={toggleDisableModal}
                        />
                        <Confirmation
                          show={timeAlert}
                          toggle={toggleTimeAlert}
                          onOkay={handleShortEntry}
                          icon={NOT_ALLOWED}
                          title={'Alert!'}
                          description={
                            "Entries under 1 minute won't be saved. Proceed?"
                          }
                        />
                      </>
                    )}
                  </>
                }
              />

              <Route path='/profile' element={<Profile />} />
            </>
          ) : (
            <>
              <Route path='/time_track' element={<TimeTrack />} />
            </>
          )}
          <Route
            path='/daily_logs'
            element={
              <>
                <DailyLogs isTimeTrack={isTimeTrack} />
                {isTimeTrack && (
                  <>
                    <TimeTrackBottom
                      currentTracking={currentTracking}
                      handlePause={handlePause}
                      handleClockOut={handleClockOut}
                    />
                    <Disabled show={disableModal} toggle={toggleDisableModal} />
                    <Confirmation
                      show={timeAlert}
                      toggle={toggleTimeAlert}
                      onOkay={handleShortEntry}
                      icon={NOT_ALLOWED}
                      title={'Alert!'}
                      description={
                        "Entries under 1 minute won't be saved. Proceed?"
                      }
                    />
                  </>
                )}
              </>
            }
          />
          <Route
            path='/teams'
            element={
              <>
                <Teams isTimeTrack={isTimeTrack} />
                {isTimeTrack && (
                  <>
                    <TimeTrackBottom
                      currentTracking={currentTracking}
                      handlePause={handlePause}
                      handleClockOut={handleClockOut}
                    />
                    <Disabled show={disableModal} toggle={toggleDisableModal} />
                    <Confirmation
                      show={timeAlert}
                      toggle={toggleTimeAlert}
                      onOkay={handleShortEntry}
                      icon={NOT_ALLOWED}
                      title={'Alert!'}
                      description={
                        "Entries under 1 minute won't be saved. Proceed?"
                      }
                    />
                  </>
                )}
              </>
            }
          />
          <Route path='/profile' element={<Profile />} />
          <Route path='/notifications' element={<Notifications />} />
        </Route>
      )}
    </Routes>
  );
}

export default App;
