import API from './axiosClient';

export const getNotifications = (body) => {
  return API.post(`/notification/admin-all`, body);
};

export const getNotificationsCount = () => {
  return API.get(`/notification/admin-all-count`);
};

export const markAllRead = () => {
  return API.put(`/notification/admin-mark-read-admin-all`);
};
