import Dropdown from 'react-bootstrap/Dropdown';

import CustomToggle from './CustomToggle';
import CustomMenu from './CustomMenu';
import { useState } from 'react';

export default function CustomDropDown({
  children,
  jobs,
  handleSelectedJob,
  isTimeTrack,
}) {
  const [show, toggle] = useState(false);
  return (
    <Dropdown show={show} onToggle={!isTimeTrack ? toggle : () => {}}>
      <Dropdown.Toggle as={CustomToggle}>{children}</Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu} className="p-3">
        {jobs?.length ? (
          jobs?.map((val) => (
            <li
              key={`job_desktop_${val?._id}`}
              onClick={() => {
                handleSelectedJob(val);
                toggle(false);
              }}
              value={val?.name}
              className="cursor-pointer"
            >
              <span className="d-block">{val?.name}</span>
            </li>
          ))
        ) : (
          <p className="text-heading-secondary">No Job found</p>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
