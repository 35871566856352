import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

function DatePickerValue({
  initialValue,
  handleChange,
  minDate,
  disabled,
  customize,
  isHistoryTab
}) {
  const [open, setOpen] = React.useState(false);

  const iconStyle = {
    '& .MuiOutlinedInput-root': customize && {
      border: 'none !important',
      color: '#3b98d1 !important',
      fontSize: '18px !important',
      background: 'white !important',
      height: '3rem !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #DADEDF !important',
    },
    '& .MuiSvgIcon-root': {
      fill: '#3B98D1',
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...isHistoryTab && { views: ['year', 'month'], defaultValue: initialValue }}

        sx={iconStyle}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        className="w-100"
        value={initialValue}
        onChange={(newValue) => {
          handleChange(newValue.$d);
        }}
        minDate={minDate}
        disabled={disabled}
        slotProps={{
          textField: {
            onClick: () => setOpen(true),
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePickerValue;
