import { useCallback, useEffect, useState } from 'react';
import { MEDIUM_SCREEN_WIDTH, SCREEN } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { getDaysDiff, toHoursAndMinutes } from '../../services/utils';
import ProfilesCell from '../common/ProfilesCell';
import JobTableMobile from './JobTableMobile';
import DateRangeFilter from '../modals/DateRangeFilter';
import filterImg from '../../assets/icons/filter.svg';
import _debounce from 'lodash/debounce';
import Pagination from '../pagination';

export default function JobTable({
  entriesMeta,
  getJobs,
  data,
  filters,
  setFilter,
}) {
  const size = useWindowSize();
  const [expandedRows, setExpandedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState(filters?.search || '');
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(entriesMeta.current_page);

  useEffect(() => {}, [entriesMeta]);

  const toggleRowExpansion = (row) => {
    if (expandedRows.includes(row._id)) {
      setExpandedRows(expandedRows.filter((id) => id !== row?._id));
    } else {
      setExpandedRows([...expandedRows, row?._id]);
    }
  };

  const tableHeader = [
    {
      name: 'Job ID',
      selector: (job) => (
        <span className='normal-text-primary'>{job?.po_number}</span>
      ),
      width: '120px',
    },
    {
      name: 'Job Name',
      selector: (job) => (
        <span className='normal-text-primary'>{job?.name}</span>
      ),
      width: '200px',
      style: '',
    },
    {
      name: 'Starting Date',
      selector: (job) => (
        <span className='normal-text-primary'>
          {moment(job?.start_date).format('DD MMM, YYYY')}
        </span>
      ),
    },
    {
      name: 'Completion Date',
      selector: (job) => (
        <span className='normal-text-primary'>
          {moment(job?.end_date).format('DD MMM, YYYY') !== 'Invalid date'
            ? moment(job?.end_date).format('DD MMM, YYYY')
            : 'N/A'}
        </span>
      ),
    },
    {
      name: 'Job Duration',
      selector: (job) => (
        <span className='normal-text-primary'>
          {getDaysDiff(job?.start_date, job?.end_date)
            ? getDaysDiff(job?.start_date, job?.end_date) + ' days'
            : 'N/A'}
        </span>
      ),
    },
    {
      name: 'Team Leader',
      selector: () => <ProfilesCell teamLeaders={data.team_leaders} />,
    },
    {
      name: 'Total Time',
      selector: (job) => (
        <span>
          {toHoursAndMinutes(job?.totalTime, SCREEN.desktop) || 'N/A'}
        </span>
      ),
    },
    // {
    //   name: 'Payout Amount',
    //   selector: (job) => (
    //     <span className="normal-text-primary">${job?.payout_amount}</span>
    //   ),
    // },
  ];

  const handleOkay = (filters) => {
    setFilter(filters);
    setShowModal(false);
  };

  const clearFilters = () => {
    setFilter(() => ({ start_date: '', end_date: '' }));
    setShowModal(false);
  };

  const filterButton = () => (
    <button
      className='primary-button-outline'
      onClick={() => setShowModal(true)}
    >
      <div className='d-flex align-items-center justify-content-center'>
        <img className='me-3' src={filterImg} alt='filter' />
        <span>Filter</span>
      </div>
    </button>
  );

  const handleDebounce = (searchVal) => {
    setFilter((prev) => ({ ...prev, search: searchVal }));
  };

  const debounceFn = useCallback(_debounce(handleDebounce, 500), []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  const handleLimitChange = (val) => {
    setCurrentPage(1);
    setLimit(val);
  };

  return (
    <>
      <div className='h-100 d-flex flex-column justify-content-between'>
        <div>
          <div className='d-flex flex-column gap-3'>
            <div className='table-wrapper'>
              <div className='px-4 pt-3'>
                <div className='mb-2 d-flex align-items-center justify-content-between'>
                  <h4>Jobs</h4>
                  {size?.width < MEDIUM_SCREEN_WIDTH && filterButton()}
                </div>
                <div className='search-bg d-flex align-items-center justify-content-between'>
                  <div className='w-100'>
                    <input
                      type='text'
                      className='search-input'
                      placeholder='Search by Job ID or name...'
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  {size?.width >= MEDIUM_SCREEN_WIDTH && filterButton()}
                </div>
              </div>
              <div className='p-3 custom-table'>
                {data.jobs?.length ? (
                  <>
                    {size?.width >= MEDIUM_SCREEN_WIDTH ? (
                      <DataTable columns={tableHeader} data={data.jobs} />
                    ) : (
                      <JobTableMobile
                        toggleRowExpansion={toggleRowExpansion}
                        expandedRows={expandedRows}
                        data={data}
                      />
                    )}
                  </>
                ) : (
                  <p className='text-center text-heading-primary'>
                    No Record Exists
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <Pagination
            limit={limit}
            handleLimitChange={(val) => handleLimitChange(val)}
            totalPage={entriesMeta.total_pages}
            currentPage={currentPage}
            totalCount={entriesMeta.total_count}
            handlePageChange={(val) => setCurrentPage(Number(val))}
            handleNextPageClick={() => setCurrentPage(currentPage + 1)}
            handlePreviousPageClick={() => setCurrentPage(currentPage - 1)}
          />
        </div>
      </div>
      <DateRangeFilter
        show={showModal}
        toggle={setShowModal}
        onOkay={handleOkay}
        onClear={clearFilters}
        value={filters}
      />
    </>
  );
}
