import { Modal } from "react-bootstrap";

import NOT_ALLOWED from "../../assets/icons/not-allowed.svg";
import CORSS from "../../assets/icons/cross.svg";

export default function Disabled({ show, toggle }) {
  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="d-flex justify-content-end">
          <img
            src={CORSS}
            alt="cross-icon"
            className="cursor-pointer"
            onClick={() => toggle(false)}
          />
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-center">
            <img src={NOT_ALLOWED} alt="not-allowed" />
          </div>
          <div className="modal-inner-wrapper text-center my-4">
            <p className="modal-heading heading-color">Disabled</p>
            <span className="text-label">
              This feature is disabled unless you checkout from the previous job
              first.
            </span>
            <div className="d-flex justify-content-center mt-4">
              <button
                className="primary-button primary-button-text"
                onClick={() => toggle(false)}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
