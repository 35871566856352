import API from './axiosClient';

export const getUserJobs = async () => {
  return API.get(`/job/user_jobs`);
};

export const getJobsListing = async (body) => {
  return API.post(`/job/user_jobs_listing`, body);
};

export const getJobUsers = async (id) => {
  return API.get(`/job/${id}/users`);
};

export const addUsersToJobs = async (id, usersList) => {
  return API.post(`/job/${id}/add_users`, { usersList });
};

export const getWeekJobsListing = async (body) => {
  return API.post(`/job//user_weekly_jobs_listing`, body);
};
