import { useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function SelectJob({
  isOpen,
  handleClose,
  jobs,
  handleSelectedJob,
}) {
  const [filterVal, setFilterVal] = useState('');
  return (
    <Modal
      show={isOpen}
      className="job-listing-modal"
      onHide={() => handleClose(false)}
      centered
    >
      <Modal.Body>
        <div>
          <input
            className="filter-input-field w-100"
            placeholder="Find jobs..."
            onChange={(e) => setFilterVal(e.target.value)}
            value={filterVal}
          />
          <span className="text-heading pt-4 pb-3 d-block">Jobs</span>
          <ul className="jobs-listing p-3 ps-4">
            {jobs?.length ? (
              jobs
                ?.filter(
                  (val) =>
                    !filterVal ||
                    val?.name?.toLowerCase().includes(filterVal.toLowerCase())
                )
                .map((val) => (
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      handleSelectedJob(val);
                      handleClose(false);
                    }}
                    key={`job_mbl_${val?._id}`}
                  >
                    <span className="d-block">{val?.name}</span>
                  </li>
                ))
            ) : (
              <p className="text-heading-secondary">No Jobs found</p>
            )}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}
