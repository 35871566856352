import '../../assets/styles/dashboardStyles.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getUserDetail } from '../../network/users';
import { getSafetyFormDetail } from '../../network/safety_form';
import { toast } from 'react-toastify';
import VehicleInfo from '../../components/vehicleInfo/VehicleInfo.jsx';
import Form from '../../components/qrForm/Form.jsx';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useLocation } from 'react-router-dom';

function QrForm() {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [locationGranted, setLocationGranted] = useState(true);
  const [user, setUser] = useState();
  const [weatherData, setWeatherData] = useState();
  const [openQr, setOpenQr] = useState(false);

  const location = useLocation();
  const [vehicle, setVehicle] = useState();
  const [submittedForm, setSubmittedForm] = useState();
  const [lastReadingId, setlastReadingId] = useState();
  const updateLoader = (state) => {
    setLoader(state);
  };

  const updateLocationGrante = (state) => {
    setLocationGranted(state);
  };

  const updateOpenQr = (state) => {
    setOpenQr(state);
  };

  useEffect(() => {
    setLoader(true);
    getUserDetail()
      .then((data) => {
        setUser(data?.data?.user);
        setLoader(false);
      })
      .catch((error) => {
        toast.error('Error in fetching details.');
        console.log(error);
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    if (!id) {
      setLocationGranted(false);
      setLoader(true);
      setVehicle(location?.state?.vehicleData);
      setlastReadingId(location?.state?.vehicleData?.vehicle_id);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocationGranted(true);

            const weatherRequest = fetch(
              `https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&units=metric&appid=${process.env.REACT_APP_WEATHER_API}`
            ).then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            });

            const reverseGeocodingRequest = fetch(
              `https://api.openweathermap.org/geo/1.0/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=${process.env.REACT_APP_WEATHER_API}`
            ).then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            });

            Promise.all([weatherRequest, reverseGeocodingRequest])
              .then((data) => {
                const [weatherData, reverseGeocodingData] = data;
                weatherData.name = reverseGeocodingData[0].name.split(' ')[0];
                setWeatherData(weatherData);
              })
              .catch((error) => {
                console.error('There was a problem with fetching data:', error);
              });
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
      setLoader(false);
    } else {
      setLoader(true);
      getSafetyFormDetail(id)
        .then((data) => {
          setVehicle(data?.data?.data?.basicInfo);
          setWeatherData(data?.data?.data?.safetyForm?.weather);
          setSubmittedForm(data?.data?.data);
          setLoader(false);
        })
        .catch((error) => {
          toast.error('Error occured ', error);
          setLoader(false);
        });
    }
  }, []);

  return (
    <>
      {loader && <div className='loader-custom'></div>}
      {!locationGranted ? (
        <div className='overlay'>
          <p>Please grant access to your location.</p>
        </div>
      ) : (
        <div
          className={`${loader ? 'opacity-50' : ''}`}
          style={{ backgroundColor: '#f5f5f5', minHeight: '91vh' }}
        >
          {!openQr && (
            <div
              style={{ padding: '2rem 2rem 0 2rem' }}
              className='heading-font d-block d-md-none'
            >
              Operator's Form
            </div>
          )}
          {user && weatherData ? (
            <Row className='p-4 d-block d-md-none'>
              <Col lg='4'>
                <VehicleInfo
                  data={vehicle}
                  updateLoader={updateLoader}
                  updateLocationGrante={updateLocationGrante}
                  userData={user}
                  weather={weatherData}
                  openQr={openQr}
                  isSubmitted={id ? true : false}
                  type={submittedForm?.vehicle_category}
                  reading={submittedForm?.safetyForm?.odometer_reading}
                />
              </Col>
              <Col lg='5'>
                <Form
                  updateLoader={updateLoader}
                  updateLocationGrante={updateLocationGrante}
                  userData={user}
                  weather={weatherData}
                  updateOpenQr={updateOpenQr}
                  openQr={openQr}
                  vehicleId={vehicle?.vehicle_id}
                  isSubmitted={id ? true : false}
                  submittedForm={submittedForm}
                  oldReadingId={lastReadingId}
                />
              </Col>
            </Row>
          ) : (
            <div className='loader'></div>
          )}
        </div>
      )}
    </>
  );
}

export default QrForm;
