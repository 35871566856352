import { Row, Col, Card, Button } from "react-bootstrap";
import GoogleIcon from "../../assets/icons/google.svg";
import FBIcon from "../../assets/icons/fb.svg";
import "../../assets/styles/loginStyles.css";
import { useGoogleLogin } from "@react-oauth/google";
import { useLogin } from "react-facebook";
import { googleLogin, facebookLogin } from "../../network/auth";
import { useAuth, AuthProvider } from "../../hooks/useAuth";

import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function Login() {
  const { login, isLoading, error } = useLogin();
  const [loading, setLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const msg1 = searchParams.get("msg1");
  const msg2 = searchParams.get("msg2");


  useEffect(() => {
    console.log("error messages :", msg1, msg2);
    if (msg1) {
      toast.error(msg1);
    }
    if (msg2) {
      toast.error(msg2);
    }
  }, [])


  const auth = useAuth();

  const verifyLogin = async ({ code }) => {
    if (code) {
      setLoader(true);
      const res = await googleLogin({ access_token: `${code}` });

      if (res?.data?.data?.user) {
        auth.login(res);
      } else {
        toast.error(res?.response?.data?.errorMessage);
      }
      setLoader(false);
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => verifyLogin(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
  });

  const loginWithFacebook = async () => {
    try {
      const response = await login({
        scope: "email",
      });

      if (response?.status === "connected") {
        setLoader(true);
        let res = await facebookLogin({
          user_id: response?.authResponse?.userID,
          access_token: response?.authResponse?.accessToken,
        });

        if (res?.data?.data?.user) {
          auth.login(res);
        } else {
          toast.error(res?.response?.data?.errorMessage);
        }
        setLoader(false);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <>
      <Row className={`h-100 ${loading ? "opacity-50" : ""}`}>
        <Col sm={6} className="bg-login-img"></Col>
        <Col sm={6} className="bg-login-img-sm bg-cl">
          {loading && <div className="loader"></div>}
          <div className="h-100 d-flex justify-content-center align-items-center">
            <Card className="w-75 login-card text-center">
              <div className="login-card-header-text">Welcome to CORE</div>
              <div className="login-card-body-text">
                <span>
                  Get ready to manage your tasks effortlessly, collaborate with
                  your team, and stay on top of project timelines.
                </span>
              </div>
              <div className="d-grid gap-3 w-100">
                <Button
                  size="lg"
                  className="btn-google login-btn"
                  onClick={loginWithGoogle}
                >
                  <img src={GoogleIcon} alt="google-icon" className="me-1" />
                  Continue with Google
                </Button>
                <Button
                  size="lg"
                  className="btn-fb login-btn"
                  onClick={loginWithFacebook}
                >
                  <img src={FBIcon} alt="google-icon" className="me-1" />
                  Continue with Facebook
                </Button>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
}
