import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import CHECKBOX_CHECKED from '../../assets/icons/checkbox_checked.svg';
import CHECKBOX_UNCHECKED from '../../assets/icons/checkbox_uncheck.svg';
import CROSS from '../../assets/icons/badge-cross.svg';

export default function AddWorker({
  isOpen,
  handleClose,
  workers,
  selectedWorkers,
  handleAddSingleWorker,
  handleSelectAll,
  handleAddUserConfirm,
}) {
  const [filterVal, setFilterVal] = useState('');
  return (
    <Modal
      show={isOpen}
      className="worker-listing-modal"
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <div>
          <input
            className="filter-input-field w-100"
            placeholder="Search worker..."
            onChange={(e) => setFilterVal(e.target.value)}
            value={filterVal}
          />
          <span className="text-heading pt-4 pb-3 d-block">Workers</span>
          {workers?.length ? (
            <>
              <div
                className="d-flex gap-2 cursor-pointer"
                onClick={handleSelectAll}
              >
                <img
                  src={
                    workers?.length === selectedWorkers?.length
                      ? CHECKBOX_CHECKED
                      : CHECKBOX_UNCHECKED
                  }
                  alt="check-icon"
                />
                <span>Select All</span>
              </div>
              <hr />
              <div className="d-flex flex-column add-user-listing-wrapper gap-3">
                {workers?.length &&
                  workers
                    ?.filter(
                      (val) =>
                        !filterVal ||
                        val?.name
                          ?.toLowerCase()
                          .includes(filterVal.toLowerCase())
                    )
                    ?.map((val) => (
                      <div
                        key={val?._id}
                        className="d-flex gap-2 cursor-pointer"
                        onClick={() => handleAddSingleWorker(val?._id)}
                      >
                        <img
                          src={
                            selectedWorkers?.includes(val?._id)
                              ? CHECKBOX_CHECKED
                              : CHECKBOX_UNCHECKED
                          }
                          alt="check-icon"
                        />
                        <span>{val?.name}</span>
                      </div>
                    ))}
              </div>
            </>
          ) : (
            <p className="text-center text-heading-primary">No Record Exists</p>
          )}
        </div>
      </Modal.Body>
      {selectedWorkers?.length ? (
        <div className="d-flex flex-column gap-3 tag-wrapper">
          <div className="d-flex flex-wrap gap-2">
            {workers?.length &&
              workers
                ?.filter((val) => selectedWorkers?.includes(val?._id))
                .map((val) => (
                  <div
                    className="custom-name-badge"
                    key={`wrkr_bdg_${val?._id}`}
                  >
                    <span className="text-label-secondary badge-color">
                      {val?.name}
                    </span>
                    <img
                      className="cursor-pointer"
                      src={CROSS}
                      alt="cross"
                      onClick={() => handleAddSingleWorker(val?._id)}
                    />
                  </div>
                ))}
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button className="primary-button-outline" onClick={handleClose}>
              Cancel
            </button>
            <button
              className="primary-button color-standard"
              onClick={handleAddUserConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
}
