import moment from "moment";

export default function TimeTrackMobile({ currentTracking }) {
  return (
    <div className="tt-mobile-wrapper">
      <div>
        <span className="text-heading text-label-header">Job Record</span>
      </div>
      <div className="tt-table-wrapper mt-3">
        <div className="tt-table-header-wrapper text-label-primary">
          {currentTracking?.job?.po_number}
        </div>
        <div className="tt-data-wrapper d-flex flex-column gap-4">
          <div className="d-flex justify-content-between">
            <span className="text-label-primary">Job Name</span>
            <span className="primary-text-color text-label-heading text-decoration-none">
              {"• "} <u>{` ${currentTracking?.job?.name}`}</u>
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="text-label-primary">Clocked In</span>
            <div className="d-flex flex-column gap-1">
              {currentTracking?.entries?.time_intervals?.length &&
                currentTracking?.entries?.time_intervals?.map((entry) => (
                  <span
                    className="time-input-mobile normal-text"
                    key={`start_mbl_${entry?._id}`}
                  >
                    {moment(entry?.start_time).format("HH:mm")}
                  </span>
                ))}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <span className="text-label-primary">Paused At</span>
            <div className="d-flex flex-column gap-1">
              {currentTracking?.entries?.time_intervals?.length &&
                currentTracking?.entries?.time_intervals?.map((entry) => (
                  <>
                    {entry?.end_time ? (
                      <span className="time-input-mobile normal-text">
                        {moment(entry?.end_time).format("HH:mm")}
                      </span>
                    ) : (
                      <span className="normal-text">N/A</span>
                    )}
                  </>
                ))}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <span className="text-label-primary">Date</span>
            <span className="normal-text">
              {currentTracking?.entries?.time_intervals?.length &&
                moment(
                  currentTracking?.entries?.time_intervals?.[0]?.start_time
                ).format("MMMM DD, yyyy")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
