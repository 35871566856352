import { useEffect, useState } from 'react';
import { MEDIUM_SCREEN_WIDTH, SCREEN } from '../constants';
import useWindowSize from '../hooks/useWindowSize';
import HistoryLarge from './history/Large';
import HistorySmall from './history/Small';

import { toHoursAndMinutes } from '../services/utils';
import moment from 'moment';

export default function DashboardHistory({
  monthlyEntries,
  totalSecondsPerMonth,
  durationMap,
  monthlyEntriesMeta,
  getHistory,
  startDate,
  endDate,
  monthlyPayoutAmount,
  search,
  monthAndYear,
  page,
  limit,
  totalWeeklyPayoutArr,
  totalWeeklyDueArr,
  monthlyDueAmount,
}) {
  const size = useWindowSize();
  const [loader, setLoader] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => { }, [monthlyEntriesMeta]);

  const toggleRowExpansion = (row) => {
    if (expandedRows.includes(row._id)) {
      setExpandedRows(expandedRows.filter((id) => id !== row?._id));
    } else {
      setExpandedRows([...expandedRows, row?._id]);
    }
  };

  const start_and_end_week = (value, start_date, end_date) => {
    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    });
    const currentWeekStart = moment(value[0].date).clone().startOf('week');
    const currentWeekEnd = moment(value[0].date).clone().endOf('week');

    // if start day or end day is less than or greater
    // then month start day or end day then print mont start or end date
    const startFormat = currentWeekStart?.isBefore(start_date, 'day')
      ? moment(start_date).clone().format('ddd MMM D')
      : moment(currentWeekStart).format('ddd MMM D');

    const endFormat = currentWeekEnd?.isAfter(end_date, 'day')
      ? moment(end_date.split('T')[0]).clone().format('ddd MMM D')
      : moment(currentWeekEnd).format('ddd MMM D');

    return `(${startFormat} - ${endFormat})`;
  };

  return (
    <>
      <div className={`${loader ? 'opacity-50' : ''}`}>
        {loader && <div className="loader"></div>}
        {monthlyEntries?.length ? (
          <>
            <div>
              <div className="pre-table-haeading mt-3 mb-3 d-md-flex justify-content-between align-items-baseline">
                <h4 className="mb-2">
                  {`${moment(startDate).clone().format('MMM')} ${moment(
                    startDate
                  )
                    .clone()
                    .format('YYYY')} `}
                  <span className="text-heading-secondary">{`(${moment(
                    startDate
                  )
                    .clone()
                    .format('ddd MMM D')} - ${moment(endDate.split('T')[0])
                      .clone()
                      .format('ddd MMM D')})`}</span>
                </h4>
                <div className="d-md-flex justify-content-between gap-3">

                  {/* monthly payout and due ammount, commented out on clients demand */}
                  {/* <div className="d-flex justify-content-between gap-3 mb-2">
                    
                    <div className="d-flex align-items-end gap-1">
                      <div className="text-label-secondary color-label gap-1">
                        Monthly Payout:
                      </div>
                      <div className="text-heading-secondary">
                        ${monthlyPayoutAmount?.toFixed(2)}
                      </div>
                    </div>

                    <div className="d-flex align-items-end gap-1">
                      <div className="text-label-secondary color-label gap-1">
                        Monthly Due Amount:
                      </div>
                      <div className="text-heading-secondary">
                        ${monthlyDueAmount?.toFixed(2)}
                      </div>
                    </div>
                  </div> */}

                  <div className="d-flex align-items-end gap-1 mb-2">
                    <div className="text-label-secondary color-label">
                      Monthly Total:
                    </div>
                    <div className="text-heading-secondary">
                      {toHoursAndMinutes(
                        Math.floor(totalSecondsPerMonth),
                        SCREEN.desktop
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column gap-3">
                {Object.entries(durationMap)?.map(([key, value], index) => (
                  <>
                    {monthlyEntries.filter(
                      (entry) =>
                        moment(entry?._id).format('YYYY-MM-DD') >=
                        moment(value[value.length - 1].date).format(
                          'YYYY-MM-DD'
                        ) &&
                        moment(entry?._id).format('YYYY-MM-DD') <=
                        moment(value[0].date).format('YYYY-MM-DD')
                    )?.length ? (
                      <div className="table-wrapper" key={index}>
                        <div className="table-header">
                          <div className="text-heading-secondary color-standard">
                            {`${key} ${start_and_end_week(
                              value,
                              startDate,
                              endDate
                            )}`}
                          </div>
                          <div className="d-md-flex justify-content-between gap-3">

                            {/* weekly payout and due ammount, commented out on clients demand */}
                            {/* <div className="d-flex justify-content-between gap-3">
                              <div>
                                <span className="text-label-secondary color-label color-standard">
                                  Weekly Payout:
                                </span>{' '}
                                <span className="text-heading-secondary color-standard">
                                  ${totalWeeklyPayoutArr?.[index].toFixed(2)}
                                </span>
                              </div>

                              <div>
                                <span className="text-label-secondary color-label color-standard">
                                  Weekly Due Amount:
                                </span>{' '}
                                <span className="text-heading-secondary color-standard">
                                  ${totalWeeklyDueArr?.[index].toFixed(2)}
                                </span>
                              </div>
                            </div> */}

                            <div>
                              <span className="text-label-secondary color-label color-standard">
                                Weekly Total:
                              </span>{' '}
                              <span className="text-heading-secondary color-standard">
                                {toHoursAndMinutes(
                                  Math.floor(
                                    value.reduce(
                                      (total, data) =>
                                        total + data?.totalSecondsPerDay,
                                      0
                                    )
                                  ),
                                  SCREEN.desktop
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 custom-table">
                          {size?.width >= MEDIUM_SCREEN_WIDTH ? (
                            <HistoryLarge
                              timeEntries={monthlyEntries?.filter(
                                (entry) =>
                                  moment(entry?._id).format('YYYY-MM-DD') >=
                                  moment(value[value.length - 1].date).format(
                                    'YYYY-MM-DD'
                                  ) &&
                                  moment(entry?._id).format('YYYY-MM-DD') <=
                                  moment(value[0].date).format('YYYY-MM-DD')
                              )}
                              expandedRows={expandedRows}
                              toggleRowExpansion={toggleRowExpansion}
                              durationMap={value}
                            />
                          ) : (
                            <HistorySmall
                              timeEntries={monthlyEntries?.filter(
                                (entry) =>
                                  moment(entry?._id).format('YYYY-MM-DD') >=
                                  moment(value[value.length - 1].date).format(
                                    'YYYY-MM-DD'
                                  ) &&
                                  moment(entry?._id).format('YYYY-MM-DD') <=
                                  moment(value[0].date).format('YYYY-MM-DD')
                              )}
                              expandedRows={expandedRows}
                              toggleRowExpansion={toggleRowExpansion}
                              durationMap={value}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          <p className="text-center text-heading-primary">No Record Exists</p>
        )}
      </div>
    </>
  );
}
