import API from './axiosClient';

export const createSafetyForm = async (body) => {
  return API.post(`/safety_form/create`, body);
};

export const getUserSafetyForms = async (body) => {
  return API.post(`/safety_form/user_safety_forms`, body);
};

export const getSafetyFormDetail = async (id) => {
  return API.get(`/safety_form/detail/${id}`);
};
