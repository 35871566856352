import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { ROLES } from '../constants';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const [accessToken, setAccessToken] = useLocalStorage('access_token', null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    localStorage.setItem('access_token', data?.headers?.access_token);
    let { user } = data?.data?.data;
    setUser(user);
    if (
      user?.role === ROLES.team_lead &&
      !user?.team?.is_track_permitted_by_lead
    ) {
      navigate('/time_track');
    } else {
      navigate('/dashboard');
    }
  };

  const updateUser = (updatedUserData) => {
    setUser(updatedUserData);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      updateUser,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
