import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  (config) => {
    // Check if an access token is available in your repository
    const accessToken = localStorage.getItem('access_token');

    // If an access token is available, add it to the request headers
    if (accessToken) {
      config.headers.access_token = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log({ error });
    let res = error;
    if (res?.response?.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('access_token');
      window.location.href = `${process.env.REACT_APP_HOST}`;
    }

    //if role or time tracking permission changed then redirect user to login page
    if (res?.response?.status === 409) {
      console.log("Permissions changed :", res?.response?.data);
      localStorage.removeItem('user');
      localStorage.removeItem('access_token');

      const errorMsg1 = res?.response?.data?.resObject?.message1;
      const errorMsg2 = res?.response?.data?.resObject?.message2;
      const redirectUrl = `${process.env.REACT_APP_HOST}?msg1=${errorMsg1}&msg2=${errorMsg2}`;
      window.location.href = redirectUrl;
    }

    console.error(
      `Looks like there was a problem. Status Code: ` + res?.response?.status
    );
    return error;
  }
);

export default API;