import { Col, Modal, Row } from 'react-bootstrap';
import CORSS from '../../assets/icons/cross.svg';
import DatePickerValue from '../common/DatePicker';
import dayjs from 'dayjs';
import { useState } from 'react';

export default function DateRangeFilter({
  show,
  toggle,
  onOkay,
  value,
  onClear,
}) {
  const [filters, setFilter] = useState({ start_date: value?.start_date ? value.start_date : '', end_date: value?.end_date ? value.end_date : '' });

  return (
    <Modal size="lg" show={show} centered>
      <div className="p-3 border-bottom d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Filters</h5>
        <img
          src={CORSS}
          alt="cross-icon"
          className="cursor-pointer"
          onClick={() => toggle(false)}
        />
      </div>
      <Modal.Body>
        <div>
          <h5>Date</h5>
          <Row className="mt-5">
            <Col md="6" className="px-2">
              <h6>From</h6>
              <DatePickerValue
                initialValue={filters.start_date ? dayjs(filters.start_date) : ''}
                value={filters.start_date ? dayjs(filters.start_date) : ''}
                handleChange={(value) =>
                  setFilter({ ...filters, start_date: value })
                }
              />
            </Col>
            <Col md="6" className="px-2">
              <h6>To</h6>
              <DatePickerValue
                initialValue={filters.end_date ? dayjs(filters.end_date) : ''}
                value={filters.end_date ? dayjs(filters.end_date) : ''}
                handleChange={(value) =>
                  setFilter({ ...filters, end_date: value })
                }
              />
            </Col>
          </Row>
          <div className="mt-5 px-3 d-flex align-items-center justify-content-between">
            <div role="button" className="primary-text-color" onClick={() => {
              setFilter({ start_date: '', end_date: '' });
              onClear();
            }}>
              Clear Filters
            </div>
            <button
              className="model-button primary-button text-label-heading color-white"
              onClick={() => onOkay(filters)}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
