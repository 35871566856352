import CustomDropDown from '../dropdown';
import DOWN_ARROW from '../../assets/icons/select-down-arrow.svg';

export default function Header({
  jobs,
  setSelectedJob,
  selectedJob,
  users,
  handleLogHours,
}) {
  return (
    <>
      <div className="secondary-container">
        <div className="clocker-wrapper gap-4">
          <div className="d-flex align-items-start flex-column gap">
            <span className="text-heading-secondary heading-color">
              Add Hours
            </span>
            <span className=" normal-text">
              You can add hours for the workers from here.
            </span>
          </div>
          <div className="d-flex flex-grow-1 justify-content-between mt-3 mt-md-0">
            <CustomDropDown
              jobs={jobs}
              handleSelectedJob={(val) => setSelectedJob(val)}
            >
              <div className={`custom-select cursor-pointer mt-md-0`}>
                <span>
                  {selectedJob?.name ? selectedJob?.name : 'Select Job'}
                </span>

                <img src={DOWN_ARROW} alt="select-icon" />
              </div>
            </CustomDropDown>
            <button
              className="primary-button primary-button-text"
              disabled={!selectedJob?.name || !users?.length}
              onClick={handleLogHours}
            >
              Log Hours
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
