import { useEffect, useState } from 'react';
import { CALENDER_FORMAT, MEDIUM_SCREEN_WIDTH, SCREEN } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import DashboardLarge from '../../components/dashboard/LargeNotAllowed';
import DashboardSmall from '../../components/dashboard/SmallNotAllowed';
import Confirmation from '../../components/modals/Confirmation';

import DELETE_FILL from '../../assets/icons/delete-fill.svg';

import { deleteTimeEntry, deleteSubTimeEntry } from '../../network/timeTrack';

import { toast } from 'react-toastify';
import { toHoursAndMinutes } from '../../services/utils';
import moment from 'moment';
import { minutesToHours } from '../../services/utils';

export default function DashboardComp({
  timeEntries,
  handleEntryUpdate,
  jobs,
  getJobs,
}) {
  const size = useWindowSize();
  const [loader, setLoader] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => { }, [timeEntries]);

  const toggleRowExpansion = (row) => {
    if (expandedRows.includes(row.po_number)) {
      setExpandedRows(expandedRows.filter((id) => id !== row?.po_number));
    } else {
      setExpandedRows([...expandedRows, row?.po_number]);
    }
  };

  return (
    <>
      <div
        className={`d-flex flex-column justify-content-between  ${loader ? 'opacity-50' : ''
          }`}
      >
        {loader && <div className="loader"></div>}
        {jobs?.length ? (
          <>
            <div>
              <div className="tt-height-custom d-flex flex-column gap-3">
                {jobs?.length &&
                  jobs?.map((duration) => (
                    <>
                      <div className="table-wrapper-custom">
                        <div className="table-header">
                          <div className="text-heading-secondary color-standard">
                            {moment(duration?.dayOfWeek).format('dddd D, YYYY')}
                          </div>
                          <div>
                            <span className="text-label-secondary color-label color-standard">
                              Total:
                            </span>{' '}
                            <span className="text-heading-secondary color-standard">
                              {minutesToHours(
                                Math.floor(duration?.dayTotal)
                                // SCREEN.desktop
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="p-3 custom-table">
                          {size?.width >= MEDIUM_SCREEN_WIDTH ? (
                            <DashboardLarge
                              timeEntries={duration?.Jobs}
                              jobs={duration?.Jobs}
                              expandedRows={expandedRows}
                              toggleRowExpansion={toggleRowExpansion}
                              handleEntryUpdate={handleEntryUpdate}
                            />
                          ) : (
                            <DashboardSmall
                              timeEntries={duration?.Jobs}
                              expandedRows={expandedRows}
                              toggleRowExpansion={toggleRowExpansion}
                              handleEntryUpdate={handleEntryUpdate}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>

            <div className="d-flex justify-content-between my-3">
              <div className="d-flex gap-2 align-items-center">
                <span className="text-label-secondary d-none d-md-block">
                  Show
                </span>
                <div>
                  <select
                    className="page-couter text-label-secondary"
                    onChange={(e) => getJobs(timeEntries?.page, e.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
                <span className="text-label-secondary d-none d-md-block">
                  entries of {timeEntries?.total}
                </span>
              </div>
              <div className="d-flex gap-1">
                <div
                  className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
                  onClick={(e) => {
                    if (timeEntries?.page > 1) {
                      getJobs(timeEntries?.page - 1, timeEntries?.limit);
                    }
                  }}
                >
                  {'<'}
                </div>
                {new Array(Math.ceil(timeEntries?.total / timeEntries?.limit))
                  .fill(0)
                  .map((val, index) => (
                    <div
                      className={`pag-item-wrapper normal-text-primary color-label cursor-pointer ${timeEntries?.page === index + 1 ? 'sl-page' : ''
                        }`}
                      onClick={(e) => getJobs(index + 1, timeEntries?.limit)}
                    >
                      {index + 1}
                    </div>
                  ))}
                <div
                  className="pag-item-wrapper normal-text-primary color-label cursor-pointer"
                  onClick={(e) => {
                    if (
                      timeEntries?.page !==
                      new Array(
                        Math.ceil(timeEntries?.total / timeEntries?.limit)
                      ).fill(0).length
                    )
                      getJobs(timeEntries?.page + 1, timeEntries?.limit);
                  }}
                >
                  {'>'}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="table-wrapper-custom p-5 text-center">
            No Records Found
          </div>
        )}
      </div>
    </>
  );
}
