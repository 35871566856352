import '../../assets/styles/dashboardStyles.css';
import { useEffect, useState } from 'react';
import { getJobsListing } from '../../network/jobs';
import { toast } from 'react-toastify';
import JobTable from '../../components/job/JobTable';
import moment from 'moment';

function Job({ isTimeTrack }) {
  const [jobs, setJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [entriesMeta, setEntriesMeta] = useState();
  const [filters, setFilter] = useState({
    start_date: '',
    end_date: '',
    search: '',
  });

  useEffect(() => {
    handleUseEffectCalls();
  }, [filters]);

  const handleUseEffectCalls = async () => {
    setLoader(true);
    await getJobs();
    setLoader(false);
  };

  const getJobs = async (pageNo = 1, size = 10) => {

    let res = await getJobsListing({
      size,
      pageNo,
      search: filters.search,
      start_date: filters.start_date ? moment(filters.start_date).format('YYYY-MM-DD') : '',
      end_date: filters.end_date ? moment(filters.end_date).format('YYYY-MM-DD') : ''
    });
    if (res.data?.data) {
      setJobs(res.data?.data);
      setEntriesMeta(res.data?.meta);
    } else {
      toast.error('Error occured while fetching data.');
    }
  };

  return (
    <>
      {loader && <div className="loader"></div>}
      <div
        className={`${loader ? 'opacity-50' : ''} ${isTimeTrack
          ? 'app-container-with-time-track-at-bottom'
          : 'app-container'
          }`}
      >
        {entriesMeta && (
          <JobTable
            entriesMeta={entriesMeta}
            getJobs={getJobs}
            data={jobs}
            filters={filters}
            setFilter={setFilter}
          />
        )}
      </div>
    </>
  );
}

export default Job;
